import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ActivityIndicator from '../../shared/ActivityIndicator';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import { _moneyFormat } from '../../../helpers/utilityFunctions';
import _replace from 'lodash/replace';
import { Grid } from '@material-ui/core';

import DashboardStyles from '../DashboardSellers/Sellers.styles';
import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import parseQuerystring from '../../../helpers/parseQuerystring';

import { columns, mapData } from './TableMetadata';
import TableColor from '../../shared/Table/TableColor';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import SalesmanDataFilters from './SalesmanDataFilters';
import RankingTop from './RankingTop';
import CardMessage from './CardMessage';
import { objectsAreEqual } from '../../../helpers/compareObjects';

class RankingList extends Component {
  state = {
    isFreelance: this.props.canViewSummary,
    isAdmin: this.props.currentUser.roles.some(role =>
      role.name.includes('admin')
    )
  };

  componentDidMount() {
    this.fetchItems();
  }

  _fetchTopRanking = params => {
    const query = params || parseQuerystring(this.props) || {};
    new Promise((resolve, reject) => {
      (async () => {
        try {
          await this.props.fetchTopRanking({
            ...query
          });
          resolve();
        }catch (error) {
          reject(error);
        }
      })();
      setTimeout(() => reject('Servicio no disponible.'), 120000);
    });
  };

  fetchItems = params => {
    const query = params || parseQuerystring(this.props) || {};
    this.props.fetchPointsOfSaleNames({
      ...query
    });
    this._fetchPointsOfSale({ ...query });
    this._fetchSuppliers({ ...query });
  };

  _fetchPointsOfSale = params => {
    const supplier = params.supplier || '';
    const selectedZones = params.selectedZones || [];
    if (supplier.length > 0 || selectedZones.length > 0) {
      this.props.fetchPointsOfSaleList({
        supplier: params.supplier,
        selectedZones: params.selectedZones
      });
    }
  };

  _fetchSuppliers = params => {
    this.props.fetchSuppliers({ selectedZones: params.selectedZones });
  };

  _handleFilterChange = filters => {
    this._fetchTopRanking(filters);
    this._fetchPointsOfSale(filters);
    this._fetchSuppliers(filters);
    if (!objectsAreEqual(this.props.filterSelection, filters))
      this.props.fetchFilterSelection(filters);
  };

  getColor(rank) {
    let color = '';
    if (rank == 1) {
      color = '#DB4844';
    } else {
      color = '#FEAD00';
    }
    return color;
  }

  getApprovedSuppliers = (listPos, user) => {
    const sup_approved = [];
    listPos.map(pv => {
      const m = pv.association;
      m.map(e => {
        if (e.status == 'approved' && e.userId == user) {
          const data = {
            id: pv.supplier.id,
            name: pv.supplier.name
          };
          sup_approved.push(data);
        }
      });
    });
    return sup_approved;
  };

  _isCoordinator = userRoles => {
    return userRoles &&
      this.props.hugeSurfaceCoordinatorRoles &&
      userRoles.some(role =>
        this.props.hugeSurfaceCoordinatorRoles.includes(role.name)
      )
      ? true
      : false;
  };

  reload = () => {
    window.location.reload(true);
  };

  render() {
    const {
      classes,
      sellers = [],
      suppliers,
      fetchSupplierFilter,
      currentUser,
      saleChannels,
      pointsOfSaleList,
      requestDashboardTopRankingSend,
      canListDashboardTopRanking,
      canListTopSellers,
      newAlert,
      zones
    } = this.props;

    return (
      <Grid container spacing={24}>
        {(canListTopSellers || canListDashboardTopRanking) && (
          <Grid item xs={12}>
            <div className={classes.headerRanking}>
              <div className={classes.arrow}>
                <IconButton aria-label="goBack">
                  <Link to="/">
                    <ArrowBackIosIcon />
                  </Link>
                </IconButton>
              </div>
              <div className={classnames(classes.wrapper, classes.wrapperRank)}>
                <div className={classes.filter}>
                  <SalesmanDataFilters
                    suppliers={suppliers}
                    saleChannels={saleChannels}
                    pointsOfSale={currentUser.pointOfSales}
                    pointsOfSaleList={pointsOfSaleList}
                    ranking={true}
                    currentUser={currentUser}
                    isFreelance={this.state.isFreelance}
                    onChangeFilter={this._handleFilterChange}
                    supplierFilter={fetchSupplierFilter}
                    filterSelection={this.props.filterSelection}
                    isSurfaceCoordinator={this._isCoordinator(
                      this.props.currentUser.roles
                    )}
                    isAdmin={this.state.isAdmin}
                    newAlert={newAlert}
                    zones={zones.filter(
                      zone => parseInt(zone.area_type) === 2 && zone.id <= 4
                    )}
                  />
                </div>
              </div>
            </div>
          </Grid>
        )}

        {(canListTopSellers || canListDashboardTopRanking) && (
          <Grid item xs={12}>
            <div className={classes.topRanking}>
              <Grid container justify="center" spacing={0}>
                {sellers.slice(0, 3).map(seller => {
                  const color = this.getColor(seller.rank);
                  return (
                    <Grid item sm={4} xs={12}>
                      <div className={classnames(classes.simpleWrapper)}>
                        <RankingTop
                          key={seller.rank}
                          rank={{
                            color: color,
                            value: seller.rank
                          }}
                          nameSeller={seller.nameSeller}
                          avatar={seller.imgUser}
                          totalCount={{
                            color: color,
                            value: _moneyFormat(seller.sales)
                          }}
                          isLoading={requestDashboardTopRankingSend}
                        />
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </Grid>
        )}

        {(canListTopSellers || canListDashboardTopRanking) && (
          <Grid item xs={12}>
            <div className={classnames(classes.simpleWrapper)}>
              {sellers.length >= 1 && (
                <div className={classes.tableSellers}>
                  <TableColor
                    name={'Sellers'}
                    columns={columns}
                    data={mapData(sellers)}
                    loading={requestDashboardTopRankingSend}
                  />
                </div>
              )}
              {sellers.length < 1 && (
                <CardMessage
                  isLoading={requestDashboardTopRankingSend}
                  message={'En estos momentos no existe TOP de vendedores.'}
                />
              )}
            </div>
          </Grid>
        )}
      </Grid>
    );
  }
}

RankingList.propTypes = {
  requestSend: PropTypes.bool,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withStyles(combineStyles(DashboardStyles, LayoutStyles), {
  withTheme: true
})(RankingList);
