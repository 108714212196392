import _pickBy from 'lodash/pickBy';

const commissionFilters = filters => {
  const { name, categoryStatus, depth, page, per_page, parentName } = filters;

  const sanitizedFilters = {
    name: name,
    parentName: parentName,
    page,
    perPage: per_page
  };

  if (categoryStatus === 'activos') {
    sanitizedFilters.active = "true";
  }

  if (categoryStatus === 'inactivos') {
    sanitizedFilters.active = "false";
  }

  const newSanitizedFilters = _pickBy(
    sanitizedFilters,
    filter => !!filter || filter === false
  );

  if (depth === 'padre') {
    newSanitizedFilters.depth = 0;
  }

  if (depth === 'hija') {
    newSanitizedFilters.depth = 1;
  }

  return newSanitizedFilters;
};

export default commissionFilters;
