import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import CloseIcon from '@material-ui/icons/Close';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { CheckCircle } from '@material-ui/icons';
import uuid from 'uuid/v4';
import { Formik, Form, FieldArray, getIn, Field } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import _find from 'lodash/find';
import _get from 'lodash/get';
import toSnakeCase from 'lodash/snakeCase';
import CustomTableCell from './CustomTableCell';
import InfoField from '../../shared/InfoField';
import OutlinedTextField from '../../shared/Fields/OutlinedTextField';
import PrimaryButton from '../../shared/Buttons/PrimaryButton';
import SegmentHeader from '../../shared/SegmentHeader';
import SimpleSelect from '../../shared/Selects/SimpleSelect';
import SimpleTableRow from '../../shared/Tables/SimpleTableRow';
import formatMoney from '../../../helpers/formatMoney';
import mapListToOptions from '../../../helpers/mapListToOptions';
import classnames from 'classnames';
import { installments } from '../../../constants/enums';
import {
  validationSchema,
  articleValidation
} from './PortalSelectArticlesValidation';
import StepperButtons from '../../shared/StepperButtons';
import AutoSuggestInput from '../../shared/AutoSuggestInput';
import AutocompleteInput from '../../shared/Inputs/Autocomplete';
import ConfirmDialog from '../../shared/Modals/ConfirmDialog';
import Masks, { formatBack } from '../../shared/Inputs/Masks';
import { filterListFuzzyly, _moneyFormat } from '../../../helpers/utilityFunctions';
import moment from 'moment-timezone';
import QueryCampaignModal from '../../shared/Modals/QueryCampaignModal';
import CheckboxInput from '../../shared/Inputs/Checkbox';
import CustomDialog from '../../shared/Modals/CustomDialog';
import { getProcessRoute } from '../../../helpers/stepperFunctions';
import { diffInMonths } from '../../../helpers/formatDate';
import LoadingIndicator from '../../shared/LoadingIndicator';
import { options } from '../PortalRegisterFinancingPage/PortalRegisterMetadata';
import RadioField from '../../shared/Inputs/RadioField';

const badge = require('../../../assets/icons/badge.svg');

class PortalSelectArticlesPage extends Component {
  state = {
    giftCardArticles: [],
    gracePeriodModalIsOpen: false,
    gracePeriodModalData: {},
    validateGracePeriodUse: false,
    quantityErrors: false,
    showInvoiceSkipModal: false,
    hasCardifArticle: null,
    hasCardifSeller: null,
    sellerDataAuthorization: true,
    blockedModalNotification: true,
    disabledNext: false,
    monthsLeftForIdExpirationDate: null,
    validateInstallmentConfirmationUse: false,
    installments: 1,
    installmentsConfirmed: false,
    megaquotaModal: false,
    monthlyInstalment: '',
    layerTwoRequestDone: false,
    applyLayerTwo: false,
    quota: null,
    megaquotaLoading: false,
    attemp: 0,
    megaQuotaId: -1,
    cardifSeller: null
  };

  getNewArticle = () => {
    return {
      key: uuid(),
      id: '',
      amount: '',
      quantity: ''
    };
  };

  currentStep = this.props.routes.indexOf(this.props.location.pathname);
  nextStep = this.currentStep + 1;
  componentDidMount() {
    const { cardifArticle, gnpGasApplianceSaleEvaluation, userConfig, debtor, cosigner, voluntaryInsuranceLayerTwoArticleId } = this.props;
    const extra_quota_params = {};
    this.props.setCurrentStep(this.currentStep);

    const idExpirationDate = _get(debtor, 'idExpirationDate') || _get(cosigner, 'idExpirationDate') || null;

    if (idExpirationDate) {
      const monthsLeftForIdExpirationDate = diffInMonths(moment(idExpirationDate, "DD-MM-YYYY").toDate(), new Date());
      this.setState({ monthsLeftForIdExpirationDate });
    }

    if (this.props.isSecureSale && this.props.secureCreditInstalmentsPercentageList.length === 0) {
      this.props.fetchSecureCreditInstalmentsPercentages({ page: 1, perPage: 50 }, false);
    }

    if (gnpGasApplianceSaleEvaluation) {
      if (
        gnpGasApplianceSaleEvaluation['gnp_sale']['make_gnp_sale'] &&
        gnpGasApplianceSaleEvaluation['gnp_sale']['supplier_is_gnp'] &&
        gnpGasApplianceSaleEvaluation['gnp_sale']['supplier_has_gnp_articles']
      ) {
        extra_quota_params['category_id_in'] = [0];
      } else {
        if (
          gnpGasApplianceSaleEvaluation['gas_appliance_sale'][
          'supplier_has_gas_appliance_articles'
          ] &&
          gnpGasApplianceSaleEvaluation['gas_appliance_sale'][
          'any_breached_policy'
          ] &&
          !gnpGasApplianceSaleEvaluation['gas_appliance_sale'][
          'any_no_gas_appliance_policy'
          ] &&
          gnpGasApplianceSaleEvaluation['gas_appliance_sale'][
          'any_gas_appliance_policy'
          ]
        ) {
          extra_quota_params['category_id_in'] = [cardifArticle.category_id];
        }
      }
    }
    this._getFilterParams('');
    this._getCategoryValidationData();

    this.props.extraQuotas.length === 0 &&
      this.props.fetchExtraQuotas(
        this.props.debtorContract,
        extra_quota_params
      );
    const giftCardArticles = this._getGiftCardArticles();
    this.setState({ giftCardArticles });

    if (userConfig.articles) {
      this.setState({ installments: userConfig.articleInstallments });
      this.checkCardifArticle();
      this.checkLayerTwo();
    };
  }

  checkLayerTwo() {
    const { voluntaryInsuranceLayerTwoArticleId, userConfig } = this.props;

    if (voluntaryInsuranceLayerTwoArticleId) {
      if (userConfig.articles.find(article => article.id === voluntaryInsuranceLayerTwoArticleId)) this.setState({ applyLayerTwo: true });
    } else {
      setTimeout(() => this.checkLayerTwo(), 500);
    }
  }

  checkCardifArticle() {
    const { cardifArticle, userConfig } = this.props;
    const cardifId = _get(cardifArticle, 'id');

    if (cardifId) {
      if (userConfig.articles.find(article => article.id === cardifId)) this.setState({ hasCardifArticle: true });
    } else {
      setTimeout(() => this.checkCardifArticle(), 500);
    }
  }

  articleHasType(article, type) {
    if (!article.articleTypes) return false;
    const articleCodes = article.articleTypes.map(t => t.code);
    return articleCodes.includes(type);
  }
  _getGiftCardArticles = () => {
    const giftCardArticles = [];
    this.props.articles.map(article => {
      if (this.articleHasType(article, 'gift_card')) {
        giftCardArticles.push(article.id);
      }
    });
    return giftCardArticles;
  };

  _handleCampaignSubmit = () => {
    const values = this.state.gracePeriodModalData;
    const total = this.getTotal(values.articles, values.articleInstallments);
    if (total > 0) {
      const { supplierId, debtorContract, sale } = this.props;
      const articles = this.formatArticles(
        values.articles,
        values.articleInstallments,
        supplierId
      );
      const articlesdata = articles.map(article => {
        return {
          articleId: article.article_id,
          supplierId: article.supplier_id
        };
      });
      const saleData = {
        pointOfSaleId: sale.point_of_sale_id,
        saleChannelId: sale.sale_channel_id,
        total: total,
        installments: articles[0].num_monthly_instalments
      };
      this.props.validateCampaign({
        contract: debtorContract,
        campaignIds: this.props.gracePeriodCampaignIds,
        articlesdata,
        saleData
      });
    } else {
      this.props.newAlert(
        'error',
        'ERROR:',
        'El total de la venta debe ser mayor a 0.'
      );
    }
  };

  _handleCampaignClose = () => {
    this.setState({ gracePeriodModalIsOpen: false });
    this.props.resetQueryCampaign();
  };

  _handleApplyCampaign = () => {
    this.setState({ gracePeriodModalIsOpen: false });
  };

  formatArticles = (articles, installments, supplierId) => {
    const { cardifArticle, voluntaryInsuranceLayerTwoArticle, cardifSupplier } = this.props;

    const formatedArticles = [];
    if (!articles) {
      return formatedArticles;
    }

    const insuranceValue = this.getInsuranceValue(articles);
    articles.map(article => {
      const isVoluntaryInsurance = [_get(cardifArticle, 'id'), voluntaryInsuranceLayerTwoArticle.id].includes(Number(article.id));

      return formatedArticles.push({
        article_id: Number(article.id),
        supplier_id: isVoluntaryInsurance ? _get(cardifSupplier, 'id') : Number(supplierId),
        amount: Number(article.quantity) || 1,
        value: isVoluntaryInsurance
          ? (article.id === _get(cardifArticle, 'id') ? insuranceValue : this.getInsuranceValueLayerTwo()) * Number(installments)
          : Number(formatBack(article.amount, 'currency')),
        num_monthly_instalments: Number.parseInt(installments)
      });
    });
    return formatedArticles;
  };

  onSubmit = values => {
    const installments = this.state.installments || values.articleInstallments;
    const total = this.getTotal(values.articles, installments);
    const { campaignValidationResponse, isSecureSale } = this.props;
    if (total > 0) {
      const gracePeriodValidation =
        !!campaignValidationResponse.data &&
        !!campaignValidationResponse.data.isValid;
      this.setState({ gracePeriodModalData: values });

      if (
        !gracePeriodValidation &&
        this.props.canValidateCampaing &&
        this.props.isGracePeriodActive &&
        !isSecureSale
      ) {
        this.setState({ validateGracePeriodUse: true });
      } else if (this.props.isInstallmentConfirmationActive && Number(installments) < 12 && !this.state.installmentsConfirmed) {
        const monthlyInstalment = formatMoney(
          this.getMonthlyPayment(
            values.articles,
            values.initialPayment,
            installments
          ) +
          this.getSecureValue(
            values.articles,
            values.initialPayment,
            installments
          ) / installments
        );
        this.setState({ validateInstallmentConfirmationUse: true, installments: installments, monthlyInstalment });
      } else {
        this.validateSale(values);
      }
    } else {
      this.props.newAlert(
        'error',
        'ERROR:',
        'El total de la venta debe ser mayor a 0.'
      );
    }
  };

  validateSale = values => {
    const {
      debtor,
      cosigner,
      uniquePromissory,
      debtorContract,
      first_invoice,
      second_invoice,
      sale,
      supplierId,
      applyScoring,
      campaignValidationResponse,
      isSecureSale,
      invoiceValidationData,
      invoiceValidationSkipped,
      validateForeignIdentificationExpirationDateActive,
      toggleBaseErrorModal,
      categoriesUsedToScoringMaximumValue,
      scoringQuota,
      voluntaryInsurance,
      voluntaryInsuranceLayerTwoArticle,
      voluntaryInsuranceLayerTwoName,
      voluntaryInsuranceLayerTwoArticleId,
      useMegaQuota,
      cardifArticle,
      megaquotaAllowedCategoryIds
    } = this.props;

    const articles = [...values.articles];

    const installments = this.state.installments || values.articleInstallments;

    if (isSecureSale && applyScoring && categoriesUsedToScoringMaximumValue.length !== 0) {
      const selectedArticles = this.props.articles.filter(
        article => values.articles.map(article => article.id).includes(Number(article.id))
      );

      let validScoringArticles = false;
      selectedArticles.forEach(art => {
        if (categoriesUsedToScoringMaximumValue.map(category => category.id).includes(Number(art.category_id))) {
          validScoringArticles = true;
        }
      });

      if (!validScoringArticles) {
        this.props.newAlert(
          'error',
          'ERROR:',
          'No se puede aplicar el cupo de scoring a los artículos seleccionados'
        );
        return;
      }
    }

    if (useMegaQuota) {
      const { megaQuotaMinValueToSellInSelectedCategories } = this.props;

      const megaquotaCategoryIds = megaquotaAllowedCategoryIds.map(cate => cate.id);

      if (megaquotaCategoryIds.length > 0) {
        const filteredArticles = articles
          .filter(art => ![voluntaryInsuranceLayerTwoArticleId, cardifArticle.id].includes(art.id))
          .map(art => ({ ...art, categoryId: this.props.articles.find(ar => ar.id === art.id.toString()).category_id, amount: Number(art.amount.replace(/\./g, "")) }));

        const totalValue = filteredArticles.reduce((acc, cur) => acc + cur.amount * Number(cur.quantity), 0);
        const megaquotaArticleValue = filteredArticles.filter(art => megaquotaCategoryIds.includes(art.categoryId)).reduce((acc, cur) => acc + cur.amount * Number(cur.quantity), 0);

        if (megaquotaArticleValue > 0) {
          const ratio = megaquotaArticleValue / totalValue;
          const downPayment = values.initialPayment || 0;
          const realMegaquotaArticlesValue = megaquotaArticleValue - downPayment * ratio;

          if (realMegaquotaArticlesValue < megaQuotaMinValueToSellInSelectedCategories) {
            this.props.newAlert(
              'error',
              'ERROR:',
              'El valor mínimo a financiar de los artículos autorizados para uso de megacupo es ' + formatMoney(megaQuotaMinValueToSellInSelectedCategories)
            );
            return;
          }
        }
      }
    }

    if (this.state.applyLayerTwo && !voluntaryInsurance.hasVoluntaryInsurance && !this.state.hasCardifArticle) {
      this.props.newAlert(
        'error',
        'ERROR:',
        'Se necesita seguro voluntario para adquirir el ' + voluntaryInsuranceLayerTwoName.toLowerCase()
      );
      return;
    }

    if (isSecureSale) {
      articles.push({
        ...this.props.secureSaleArticle,
        amount: this.getSecureValue(articles, values.initialPayment || 0, installments)
      });
    };

    if (this.state.applyLayerTwo && !articles.find(a => a.id === voluntaryInsuranceLayerTwoArticleId)) {
      articles.push({
        ...voluntaryInsuranceLayerTwoArticle,
        amount: this.getInsuranceValueLayerTwo() * installments
      });
    }

    const parsedArticles = this.formatArticles(
      articles,
      installments,
      supplierId
    );

    if (validateForeignIdentificationExpirationDateActive && this.state.monthsLeftForIdExpirationDate) {
      const areInstallmentsAllowed = parsedArticles.some(
        ar => ar.num_monthly_instalments > this.state.monthsLeftForIdExpirationDate
      );

      if (areInstallmentsAllowed) {
        toggleBaseErrorModal(true, `El número de cuotas seleccionadas no está disponible para esta venta (máximo: ${this.state.monthsLeftForIdExpirationDate} cuotas).`);
        return;
      }
    }

    // Validate invoice validation is not required.
    if (
      invoiceValidationData.enable_skip_invoice_validation &&
      invoiceValidationSkipped
    ) {
      const financedValue = this.getFinanced(
        articles,
        values.initialPayment,
        installments,
        false
      );

      if (
        financedValue > invoiceValidationData.sale_cap_for_invoice_validation
      ) {
        this.setState({ showInvoiceSkipModal: true });
        return;
      }
    }

    const { megaQuotaId } = this.calculateMegaquota();

    if (this.props.supplier && this.props.supplier.canValidatePetSale && values.hasPets === null) {
      this.props.newAlert(
        'error',
        'ERROR:',
        'Error: No ha respondido la pregunta sobre mascotas'
      );
      return;
    }

    this.props.validateSale(
      {
        sale: {
          ...sale,
          down_payment: values.initialPayment || 0,
          observations: values.observations,
          cardif_salesman_id: values.cardifSellerId,
          campaign_id:
            campaignValidationResponse.data &&
              campaignValidationResponse.data.isValid
              ? campaignValidationResponse.data.selectedCampaignId
              : null
        },
        first_invoice,
        second_invoice,
        invoice_validation_skipped: this.props.invoiceValidationSkipped,
        articles: parsedArticles,
        selectedArticles: articles,
        promissory: {
          code: uniquePromissory ? Number.parseInt(uniquePromissory) : null,
          type_of_promissory: null,
          contract_id: debtorContract,
          unmaterialized_process_id: this.props.unmaterializedPromissoryProcessId || ''
        },
        debtor,
        cosigner,
        use_scoring: applyScoring && !useMegaQuota,
        scoringQuota,
        useMegaQuota,
        hasPets: values.hasPets,
        megacupoResponseId: megaQuotaId,
        grace_period_deffered:
          campaignValidationResponse.data &&
            campaignValidationResponse.data.isValid
            ? this.props.gracePeriodDeffered
            : null
      },
      {
        selectedArticles: articles,
        insurances: values.insurances,
        articleInstallments: installments,
        downPayment: values.initialPayment,
        observations: values.observations
      },
      this.props.routes[this.nextStep],
      this.props.process
    );
  };

  getTotal = (articles, max_install, withSecure = true, initialPayment = 0) => {
    let total = 0;
    articles.forEach((_, idx) => {
      total += this.getTotalPlusTax(articles, idx, max_install);
    });

    total += this.getInsuranceValueLayerTwo() * max_install;
    return this.props.isSecureSale && withSecure ? total + this.getSecureValue(articles, initialPayment, max_install) : total;
  };

  getPartialFinanced = (articles, max_install, initialPayment = 0) => {
    let total = 0;
    const initialCuota = Number.parseInt(initialPayment, 10) || 0;
    articles.forEach((_, idx) => {
      total = total + this.getTotalPlusTax(articles, idx, max_install);
    });

    return total - initialCuota;
  };

  getSecureValue = (articles, initialPayment, articleInstallments) => {
    const secureValue = Math.round(this.getFinanced(articles, initialPayment, articleInstallments, false) * this.getSecureSalePercentage(articleInstallments) / 100);
    return secureValue >= 0 && this.props.isSecureSale ? secureValue : 0;
  };

  getLessCardif = (articles, max_install) => {
    const insuranceValue = this.containsInsurance(articles) ? this.getInsuranceValue(articles) : 0;
    const insuranceValueLayerTwo = this.getInsuranceValueLayerTwo();
    return -(insuranceValue + insuranceValueLayerTwo) * max_install;
  };

  getFinanced = (articles, initialPayment, max_install, withSecure = true) => {
    let financed = 0;
    const initialCuota = Number.parseInt(initialPayment, 10) || 0;
    financed = this.getTotal(articles, max_install, withSecure) - initialCuota;
    return financed + this.getLessCardif(articles, max_install);
  };

  _getGiftCardValueRange = (articles, max_install, initialPayment) => {
    const giftCardConfig = this.props.giftCardConfig;

    if (giftCardConfig) {
      const { max_percentage, min_value } = this.props.giftCardConfig;
      const articlesWithoutGiftCards = articles.filter(
        article => !this.state.giftCardArticles.includes(article.id)
      );
      const maxValue =
        (this.getTotal(articlesWithoutGiftCards, max_install) +
          this.getLessCardif(articlesWithoutGiftCards, max_install) -
          initialPayment) *
        max_percentage;

      return {
        maxValue,
        minValue: min_value
      };
    }
    return null;
  };

  getMonthlyPayment = (articles, initialPayment, max_install) => {
    const { interest_rate,
      lower_interest_rate,
      excluded_lines_from_general_interest_rate
    } = this.props.globalConfig;

    const finance = Number.parseInt(
      this.getFinanced(articles, initialPayment, max_install, false),
      10
    );
    const financeCardif = finance;
    const isNormalInterest = articles.some((a) => !excluded_lines_from_general_interest_rate.includes(a.id.toString()));

    const interest = (isNormalInterest ? interest_rate : lower_interest_rate) / 100;

    const m_payment =
      (financeCardif * interest * (1 + interest) ** max_install) /
      ((1 + interest) ** max_install - 1) - (this.getLessCardif(articles, max_install) / max_install);

    return m_payment;
  };

  getMonthlyInsurance = (articles, initialPayment, max_install) => {
    const { obligatory_insurance_rate } = this.props.globalConfig;
    let m_insurance = 0;
    const finance =
      this.getFinanced(articles, initialPayment, max_install) -
      this.getLessCardif(articles, max_install);
    const obligatory_rate = obligatory_insurance_rate / 100;
    m_insurance = finance * obligatory_rate;
    return m_insurance;
  };

  getSubtotal = (articles, idx, max_install) => {
    const item = articles[idx];
    const quantity = Number.parseInt(item.quantity, 10);
    const amount = Number.parseInt(formatBack(item.amount, 'currency'), 10);

    if (item.id === _get(this.props.cardifArticle, 'id')) {
      const insurance_value = this.getInsuranceValue(articles);
      return insurance_value * max_install;
    }
    return amount * quantity - this.getTax(articles, idx);
  };

  getTotalPlusTax = (articles, idx, max_install) => {
    return (
      this.getSubtotal(articles, idx, max_install) + this.getTax(articles, idx)
    );
  };

  getTax = (articles, idx) => {
    const item = articles[idx];
    const quantity = Number.parseInt(item.quantity, 10);
    const amount = Number.parseInt(formatBack(item.amount, 'currency'), 10);
    const entity = this.getArticleEntity(item.id) || {};
    const iva = entity.iva || 0;
    const subTotal =
      Number.parseInt((quantity * amount) / (1 + iva / 100)) || 0;
    return Number.parseInt(quantity * amount - subTotal) || 0;
  };

  getArticleEntity = id => {
    return _find(this.props.articles, { id: id.toString() });
  };

  containsInsurance = articles => {
    return _find(articles, { id: _get(this.props.cardifArticle, 'id') });
  };

  containsInsuranceLayerTwo = articles => {
    return _find(articles, { id: _get(this.props.voluntaryInsuranceLayerTwoArticleId, 'id') });
  };

  containsGiftCard = selectedArticles => {
    const giftCardArticles = this.props.articles.filter(e =>
      this.articleHasType(e, 'gift_card')
    );
    return selectedArticles
      .map(article => _find(giftCardArticles, { id: article.id }))
      .filter(Boolean);
  };

  containsArticle = (articles, idArt) => {
    return _find(articles, { id: idArt });
  };

  containsValidArticles = articles => {
    return _find(articles, a => a.id > 0);
  };

  containsMotorbykeArticle = articles => {
    return _find(articles, a => {
      const article = this.getArticleEntity(a.id);
      if (!article) return null;
      return this.articleHasType(article, 'motorcycle');
    });
  };

  getInsuranceValue = articles => {
    const {
      voluntary_insurance_value,
      motorbike_voluntary_insurance_value,
      motorbike_voluntary_insurance_active,
      voluntary_insurance_value_grace_period,
      motorbike_voluntary_insurance_grace_period_active,
      motorbike_voluntary_insurance_grace_period_value
    } = this.props.globalConfig;
    const { campaignValidationResponse } = this.props;
    if (
      campaignValidationResponse.data &&
      campaignValidationResponse.data.isValid
    ) {
      return motorbike_voluntary_insurance_grace_period_active === 1
        ? this.containsMotorbykeArticle(articles)
          ? motorbike_voluntary_insurance_grace_period_value
          : voluntary_insurance_value_grace_period
        : voluntary_insurance_value_grace_period;
    }
    return motorbike_voluntary_insurance_active === 1
      ? this.containsMotorbykeArticle(articles)
        ? motorbike_voluntary_insurance_value
        : voluntary_insurance_value
      : voluntary_insurance_value;
  };

  getInsuranceValueLayerTwo = () => {
    return this.state.applyLayerTwo ? this.props.voluntaryInsuranceLayerTwoValue : 0;
  };

  getArticleExtraQuota = (id, allowScoringWithExtraQuota) => {
    const article = this.getArticleEntity(id);
    if (!article) return null;

    return article.extra_quotas && allowScoringWithExtraQuota
      ? `Extra cupo: ${_moneyFormat(article.extra_quotas.value)}`
      : null;
  };

  getArticlesOptions = (selected, numMonthlyInstalments) => {
    const { minInstallmentsToSellVoluntaryInsurance } = this.props;
    let avArt = this.props.articles;
    const cardifArticleMinNumMonthlyInstalments = minInstallmentsToSellVoluntaryInsurance;
    const {
      debtorBirthdate,
      voluntaryInsuranceMaxAge,
      gnpGasApplianceSaleEvaluation,
      invoiceValidationSkipped,
      scoringExclusionCategories
    } = this.props;

    if (this.containsValidArticles(selected)) {
      const debtorBirth = moment().diff(
        moment(debtorBirthdate, 'YYYY-MM-DD'),
        'years'
      );
      if (
        !invoiceValidationSkipped &&
        voluntaryInsuranceMaxAge &&
        debtorBirth <= voluntaryInsuranceMaxAge &&
        numMonthlyInstalments >= cardifArticleMinNumMonthlyInstalments &&
        !(
          gnpGasApplianceSaleEvaluation['gnp_sale']['make_gnp_sale'] &&
          gnpGasApplianceSaleEvaluation['gnp_sale']['supplier_is_gnp'] &&
          gnpGasApplianceSaleEvaluation['gnp_sale']['supplier_has_gnp_articles']
        )
      )
        avArt = [...avArt, this.props.cardifArticle];
    } else {
      avArt = avArt.filter(e => !this.articleHasType(e, 'gift_card'));
    }
    if (this.props.applyScoring) avArt = avArt.filter(a => !scoringExclusionCategories.includes(a.category_id) || (a.id == this.props.cardifArticle.id));
    avArt = avArt.filter(e => e && !this.containsArticle(selected, e.id) && e.id !== this.props.secureSaleArticle.id);
    const availableArticles = mapListToOptions(avArt, {});
    return availableArticles;
  };

  getArticleInstallmentOptions = (articles, max_install) => {
    const { gnpGasApplianceSaleEvaluation, articleInstallments } = this.props;
    let min = 1;
    let max = 1;
    if (articleInstallments) {
      if (
        gnpGasApplianceSaleEvaluation &&
        (gnpGasApplianceSaleEvaluation['gnp_sale']['make_gnp_sale'] &&
          gnpGasApplianceSaleEvaluation['gnp_sale']['supplier_is_gnp'] &&
          gnpGasApplianceSaleEvaluation['gnp_sale'][
          'supplier_has_gnp_articles'
          ])
      ) {
        max = 12;
      } else {
        max = articleInstallments.max_installments;
      }
    }
    const cuotas = installments(min, max);
    const newValue = max > max_install ? null : max;
    if (newValue && newValue != this.state.installments) {
      this.setState({ installments: newValue });
    }

    return mapListToOptions(cuotas, {
      raw: true
    }).reverse();
  };

  shouldPreventArticleDeletion = (selected, item) => {
    const containsGiftCard = this.containsGiftCard(selected);
    const insuranceItem = item.id === _get(this.props.cardifArticle, 'id');
    const giftCardItem = _find(containsGiftCard, { id: item.id });
    if (insuranceItem || giftCardItem) return false;

    const containsInsurance = this.containsInsurance(selected);
    if (containsInsurance && containsGiftCard.length > 0) {
      return selected.length <= 3;
    } else if (containsInsurance || containsGiftCard.length > 0) {
      return selected.length <= 2;
    }
    return selected.length <= 1;
  };

  verifyArticleQuantity = (item, errors) => {
    if (item.id.length !== 0 && parseInt(item.id) !== 0) {
      const article = this.getArticleEntity(item.id);

      if (article && article.max_items !== null) {
        if (parseInt(item.quantity) > article.max_items) {
          if (errors) {
            errors['articles'] = 'Error';
          }
          return 'La cantidad máxima permitida ha sido excedida';
        }
      }
    }

    return '';
  };

  verifyCategoryQuantity = (items, item, errors) => {
    let category = null;

    if (item.id) {
      const article = this.getArticleEntity(item.id);

      if (article && article.max_items === null && article.category.max_items !== null) {
        category = {
          id: article.category.id,
          name: article.category.name,
          max: article.category.max_items,
          count: 0
        };
      }
    }

    if (category) {
      for (const itemLoop of items) {
        if (itemLoop.id) {
          const article = this.getArticleEntity(itemLoop.id);

          if (article.category.id === category.id) {
            category.count += parseInt(itemLoop.quantity);
          }
        }
      }

      if (category.count > category.max) {
        if (errors) {
          errors['articles'] = 'Error';
        }
        return 'La cantidad máxima permitida de la categoría ha sido excedida';
      }
    }

    return '';
  };

  getSecureSalePercentage = installments => {
    const secureCreditInstalments = this.props.secureCreditInstalmentsPercentageList.find(
      e => e.maxNumOfInstalments >= installments && e.minNumOfInstalments <= installments
    );

    return secureCreditInstalments ? secureCreditInstalments.percentage : 0;
  };

  handleCloseDataAuthorizationModalNotification = () =>
    this.setState({ sellerDataAuthorization: true });

  handleCloseModalModalNotification = () =>
    this.setState({ blockedModalNotification: false });

  getSuggestions = async (array, value) => {
    const items = array.map(item => ({
      label:
        _get(item, 'attributes.first_name', '') +
        ' ' +
        _get(item, 'attributes.last_name', ''),
      value: item.id
    }));

    const suggestions = filterListFuzzyly(value, items, 'label');
    return Promise.resolve(suggestions);
  };

  _getFilterParams = values => {
    if (this.props.requestArticleListSend) return;
    const filterParams = {
      name: values,
      supplierId: this.props.supplierId,
      active: true,
    };
    this.props.fetchArticlesNode(filterParams);
  };

  _getCategoryValidationData = () => {
    const { debtorContract, supplierId } = this.props;
    const params = {
      contractId: debtorContract,
      supplierId,
    };
    this.props.getScoringCategories(params);
  };

  _removeArticleAndReloadFinancialPlanbyCategories = (articles, position) => {
    let newArticles = [...articles];
    newArticles.splice(position, 1);
    this._getMaxFinancialPlanbyCategories({}, newArticles);
  };

  _getMaxFinancialPlanbyCategories = (selected, articles) => {
    const articlesId = [...articles.map(art => art.id), Number(selected.id)];
    const articlesData = this.props.articles.filter(art => articlesId.includes(Number(art.id)));
    const categoriesId = articlesData.map(art => Number(art.category_id));
    const { debtorContract, sale } = this.props;
    const params = {
      contractId: debtorContract,
      saleChannelId: sale.sale_channel_id,
      categoriesId: categoriesId
    };
    this.props.getMaxFinancialPlanbyCategories(params);
  };

  isVoluntaryInsuranceLayerTwoActive = () => {
    const saleType = toSnakeCase(window.location.pathname.split('/')[1]);
    return this.props.voluntaryInsuranceLayerTwoActiveBySale[saleType];
  };

  checkMegaquota = (attemp = 0) => {
    const { getMegaQuota, debtor, debtorContract, cosigner, scoringResponseId } = this.props;
    this.setState({ megaquotaLoading: true });

    const hasCosigner = Boolean(cosigner && cosigner.identification);

    getMegaQuota({
      scoringResponseId,
      isSidebar: false,
      debtor: {
        contractId: debtorContract,
        personIdType: debtor.debtor_doctype_id,
        personId: debtor.identification,
        birthdate: debtor.date_of_birth,
        expeditionDate: debtor.expeditionDate,
      },
      cosigner: hasCosigner ? {
        contractId: cosigner.contract_id,
        personIdType: cosigner.cosigner_doctype_id,
        personId: cosigner.identification,
        birthdate: cosigner.date_of_birth,
        expeditionDate: cosigner.expeditionDate,
      } : null
    }).then(data => {
      const debtorMegaQuota = data[0];
      const cosignerMegaQuota = data[1];

      this.setState({ megaquotaModal: true });

      if (debtorMegaQuota.quota !== null && (!hasCosigner && cosignerMegaQuota === null || hasCosigner && cosignerMegaQuota.quota !== null) || attemp === 12) {
        this.setState({ attemp: 0, megaquotaLoading: false });
      } else {
        if (attemp + 1 !== 12) this.setState({ attemp: attemp + 1 });
        setTimeout(() => this.checkMegaquota(attemp + 1), 10000);
      };
    });
  };

  applyMegaquota = megaQuotaValue => {
    this.setState({ megaquotaModal: false });

    const { isScoringUsed, scoringQuotaByMegaquota, setScoringQuota, setApplyScoring, setMegaQuota } = this.props;

    if (isScoringUsed) {
      setScoringQuota(scoringQuotaByMegaquota);
      setApplyScoring(true);
    } else {
      setMegaQuota(megaQuotaValue);
    }
  };

  calculateMegaquota = () => {
    const quotaValues = this.props.megaQuotaInfo.filter(client => client !== null);
    const minQuotaClient = quotaValues.reduce((min, client) => (client.quota < min.quota ? client : min), quotaValues[0]);
    const megaQuotaValue = minQuotaClient && minQuotaClient.quota !== null ? (minQuotaClient.quota - this.props.usedQuota - this.props.usedQuotaByClient) : null;
    const megaQuotaId = minQuotaClient && minQuotaClient.quota !== null ? minQuotaClient.id : undefined;

    return { megaQuotaId, megaQuotaValue };
  };

  renderMegaquotaCategories = () => {
    const { megaquotaAllowedCategoryIds } = this.props;

    if (!megaquotaAllowedCategoryIds || megaquotaAllowedCategoryIds && megaquotaAllowedCategoryIds.length === 0) return <></>;

    return (
      <p>
        Este cupo se encuentra disponible para utilizar únicamente en las siguientes categorías:{' '}
        <ul>{megaquotaAllowedCategoryIds.map((cat) => <li key={cat.id}>{cat.name}</li>)}</ul>
      </p>
    );
  };

  renderMegaquotaWithScoring = () => {
    const { isScoringUsed, scoringQuotaByMegaquota } = this.props;
    const { megaQuotaValue } = this.calculateMegaquota();

    if (isScoringUsed) {
      return <p>Su cupo con Scoring es <b>{formatMoney(scoringQuotaByMegaquota)}</b>. Se omite el uso de Megacupo.</p>;
    }

    return (
      <>
        <p>El cupo total utilizable con Megacupo es de <b>{formatMoney(megaQuotaValue)}</b>.</p>
        {this.renderMegaquotaCategories()}
      </>
    );
  };

  render() {
    const {
      classes,
      extraQuotas,
      cardifArticle,
      applyScoring,
      campaignValidationResponse,
      userConfig,
      isSecureSale,
      invoiceValidationSkipped,
      articles,
      sellers,
      orgSellerDataAuthorization,
      isBlackListActive,
      isSendVoluntaryInsuranceSalesmanActive,
      cardifSalesmanId,
      scoringExclusionCategories,
      allowScoringWithExtraQuota,
      voluntaryInsurance,
      voluntaryInsuranceLayerTwoValue,
      voluntaryInsuranceLayerTwoName,
      minInstallmentsToSellVoluntaryInsuranceLayerTwo,
      supplier,
      useMegaQuota,
      resetMegaQuota,
      availableQuota,
      requestMegaQuotaSend,
      canCheckMegaQuotaInfo,
    } = this.props;

    const { layerTwoRequestDone, applyLayerTwo, installments, megaquotaLoading, megaquotaModal, attemp } = this.state;

    let avArt = articles;
    const hasCardifArticle = Boolean(cardifSalesmanId) && this.state.hasCardifArticle === null || this.state.hasCardifArticle;
    const hasCardifSeller = Boolean(cardifSalesmanId) && this.state.hasCardifSeller === null || this.state.hasCardifSeller;
    const cardifSalesman = sellers.find(s => s.id === cardifSalesmanId);
    const cardifSalesmanName = cardifSalesman ? cardifSalesman.attributes.first_name + ' ' + cardifSalesman.attributes.last_name : '';

    const isAbleToBuyLayerTwo = voluntaryInsurance.messages && voluntaryInsurance.messages.length === 0;
    const forbiddenItemsInList = [this.props.secureSaleArticle.id, this.props.voluntaryInsuranceLayerTwoArticle.id];

    let tempArticles = userConfig.articles && this.props.secureSaleArticle.id ? userConfig.articles.filter(item => !forbiddenItemsInList.includes(item.id)) : [];

    if (this.props.applyScoring && tempArticles.length) {
      avArt = avArt.filter(a => !scoringExclusionCategories.includes(a.category_id));
      tempArticles = tempArticles.filter(ta => ta && avArt.includes(ta));
    }
    if (!tempArticles.length) tempArticles = [this.getNewArticle()];

    const { megaQuotaValue } = this.calculateMegaquota();
    const isMegaQuotaAbleToApply = megaQuotaValue && megaQuotaValue > availableQuota;

    const initialValues = {
      articles: tempArticles,
      insurances: userConfig.insurances || [],
      gracePeriod: false,
      initialPayment: userConfig.downPayment || 0,
      articleInstallments: userConfig.articleInstallments || 1,
      observations: userConfig.observations || '',
      cardifSellerId: cardifSalesmanId || '',
      cardifSellerName: cardifSalesmanName || '',
      hasPets: this.props.hasPets || null
    };

    return (
      <div>
        {this.props.articles.length == 0 ? (
          <LoadingIndicator />
        ) : (
          <Paper>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema(hasCardifSeller && isSendVoluntaryInsuranceSalesmanActive)}
              onSubmit={this.onSubmit}
              render={({ values, handleChange, touched, errors, handleSubmit, setFieldValue }) => (
                <Form className={classes.form}>
                  <FieldArray
                    name="articles"
                    render={arrayHelpers => (
                      <Fragment>
                        <Grid container>
                          <Grid
                            container
                            classes={{ container: classes.articlesHeader }}
                          >
                            <Grid
                              classes={{ item: classes.articleHeaderItem }}
                              item
                              xs={12}
                              sm={4}
                            >
                              Artículo
                            </Grid>
                            <Grid
                              classes={{ item: classes.articleHeaderItem }}
                              item
                              xs={12}
                              sm={2}
                            >
                              Cuotas
                            </Grid>
                            <Grid
                              classes={{ item: classes.articleHeaderItem }}
                              item
                              xs={12}
                              sm={2}
                            >
                              Valor unitario
                            </Grid>
                            <Grid
                              classes={{ item: classes.articleHeaderItem }}
                              item
                              xs={12}
                              sm={1}
                            >
                              Cantidad
                            </Grid>
                            <Grid
                              classes={{ item: classes.articleHeaderItem }}
                              item
                              xs={12}
                              sm={1}
                            >
                              I.V.A.
                            </Grid>
                            <Grid
                              classes={{ item: classes.articleHeaderItem }}
                              item
                              xs={12}
                              sm={1}
                            >
                              Subtotal
                            </Grid>
                            <Grid
                              classes={{ item: classes.articleHeaderItem }}
                              item
                              xs={12}
                              sm={1}
                            >
                              {''}
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            classes={{ container: classes.responsiveHeader }}
                          >
                            Selección de artículos
                          </Grid>
                          {isSecureSale && this.props.secureSaleArticle.id && <Grid
                            container
                            classes={{ container: classes.articleItemContainerShade }}
                          >
                            <Grid
                              classes={{ item: classes.articleItem }}
                              item
                              xs={12}
                              sm={4}
                            >
                              <div className={classes.articleFieldMargin}>
                                <Field
                                  name="secure"
                                  id="secure"
                                  label="Selecciona un artículo:"
                                  margin="normal"
                                  variant="outlined"
                                  disabled={true}
                                  value={this.props.secureSaleArticle.id}
                                  initial_value_article={this.props.secureSaleArticle.id}
                                  suggestions={this.getArticlesOptions(
                                    values.articles,
                                    installments || values.articleInstallments
                                  )}
                                  suggestions_alt={this.props.articles}
                                  attr={'label'}
                                  component={AutoSuggestInput}
                                />
                              </div>
                            </Grid>
                            <Grid
                              classes={{ item: classes.articleItem }}
                              item
                              xs={4}
                              sm={2}
                            >
                              <div className={classes.articleFieldMargin}>
                                {this.props.secureSaleArticle.id !== _get(cardifArticle, 'id') ? (
                                  <SimpleSelect
                                    name="articleInstallments"
                                    label="# Cuotas"
                                    disabled={true}
                                    value={installments || values.articleInstallments}
                                    options={this.getArticleInstallmentOptions(values.articles, installments || values.articleInstallments) || []}
                                    inputProps={{
                                      MenuProps: {
                                        disableAutoFocusItem: true
                                      }
                                    }}
                                    errorStyle={{ color: 'red' }}
                                    helperText={this.props.secureCreditInstalmentsPercentageList.length !== 0 &&
                                      this.getSecureSalePercentage(installments || values.articleInstallments) === 0 ?
                                      'No existe una configuración de fianza para el N° de cuotas seleccionado' : ''
                                    }
                                  />
                                ) : (
                                  <span>
                                    {`${installments || values.articleInstallments} cuota${values.actual > 1 ? 's' : ''
                                      }`}
                                  </span>
                                )}
                              </div>
                            </Grid>
                            <Grid
                              classes={{ item: classes.articleItem }}
                              item
                              xs={4}
                              sm={2}
                            >
                              <div className={classes.articleFieldMargin}>
                                <OutlinedTextField
                                  name="1"
                                  label="Valor unitario"
                                  value={this.getSecureValue(values.articles, 0, installments || values.articleInstallments) || 0}
                                  disabled={true}
                                  InputProps={{
                                    inputComponent: Masks.currency
                                  }}
                                  helperText={''}
                                />
                              </div>
                            </Grid>
                            <Grid
                              classes={{ item: classes.articleItem }}
                              item
                              xs={4}
                              sm={1}
                            >
                              <div className={classes.articleFieldMargin}>
                                {this.props.secureSaleArticle.id !== _get(cardifArticle, 'id') ? (
                                  <OutlinedTextField
                                    name="1"
                                    label="Cant."
                                    disabled={true}
                                    value={1}
                                    InputProps={{
                                      inputComponent: Masks.amount
                                    }}
                                    error={
                                      !!this.verifyArticleQuantity(this.props.secureSaleArticle) ||
                                      !!this.verifyCategoryQuantity(
                                        values.articles,
                                        this.props.secureSaleArticle
                                      )
                                    }
                                  />
                                ) : (
                                  <span>{1}</span>
                                )}
                              </div>
                            </Grid>
                            <Grid
                              classes={{ item: classes.articleItem }}
                              item
                              xs={4}
                              sm={1}
                            >
                              <div className={classes.articleFieldMargin}>
                                <span className={classes.responsiveLable}>
                                  I.V.A.:{' '}
                                </span>
                                {formatMoney(this.getTax(
                                  [{
                                    ...this.props.secureSaleArticle,
                                    amount: this.getSecureValue(values.articles, values.initialPayment || 0, installments || values.articleInstallments)
                                  }],
                                  0
                                ))}
                              </div>
                            </Grid>
                            <Grid
                              classes={{ item: classes.articleItem }}
                              item
                              xs={4}
                              sm={1}
                            >
                              <div className={classes.articleFieldMargin}>
                                <span className={classes.responsiveLable}>
                                  Subtotal:{' '}
                                </span>
                                {formatMoney(this.getSecureValue(values.articles, 0, installments || values.articleInstallments) - this.getTax(
                                  [{
                                    ...this.props.secureSaleArticle,
                                    amount: this.getSecureValue(values.articles, values.initialPayment || 0, installments || values.articleInstallments)
                                  }],
                                  0
                                ))}
                              </div>
                            </Grid>
                            <Grid
                              classes={{ item: classes.iconArticleItem }}
                              item
                              xs={4}
                              sm={1}
                            >
                              <div className={classes.articleFieldMargin}>
                                <IconButton
                                  disabled={true}
                                  classes={{ root: classes.iconButton }}
                                >
                                  <CloseIcon classes={{ root: classes.icon }} />
                                  <span className={classes.responsiveLableRed}>
                                    QUITAR
                                  </span>
                                </IconButton>
                              </div>
                            </Grid>
                          </Grid>}
                          {values.articles.filter(item => !forbiddenItemsInList.includes(Number(item.id))).map((item, idx) => (
                            <Grid
                              container
                              classes={{
                                container:
                                  idx === 0 && !isSecureSale
                                    ? classes.articleItemContainerShade
                                    : null
                              }}
                              key={item.key}
                            >
                              <Grid
                                classes={{ item: classes.articleItem }}
                                item
                                xs={12}
                                sm={4}
                              >
                                <div className={classes.articleFieldMargin}>
                                  <Field
                                    name={`articles.${idx}.id`}
                                    id={`articles.${idx}.id`}
                                    label="Selecciona un artículo:"
                                    margin="normal"
                                    variant="outlined"
                                    value={item.id}
                                    initial_value_article={item.id.toString()}
                                    helperText={articleValidation(
                                      item,
                                      this.state.giftCardArticles,
                                      this._getGiftCardValueRange(
                                        values.articles,
                                        installments || values.articleInstallments,
                                        values.initialPayment
                                      )
                                    )}
                                    onChange={e => {
                                      handleChange(e, `articles.${idx}.id`);
                                      if (e.target.value === _get(cardifArticle, 'id')) {
                                        this.setState({ hasCardifArticle: true });
                                      }
                                    }}
                                    handleSuggestionSelected={(evt, { suggestion }) => {
                                      this._getMaxFinancialPlanbyCategories(suggestion, values.articles);
                                      this.props.resetQueryCampaign();
                                    }}
                                    attr={'label'}
                                    suggestions={this.getArticlesOptions(
                                      values.articles,
                                      installments || values.articleInstallments
                                    )}
                                    suggestions_alt={[...this.props.articles, this.props.cardifArticle]}
                                    component={AutoSuggestInput}
                                  />
                                </div>
                              </Grid>
                              <Grid
                                classes={{ item: classes.articleItem }}
                                item
                                xs={4}
                                sm={2}
                              >
                                <div className={classes.articleFieldMargin}>
                                  {item.id !== _get(cardifArticle, 'id') ? (
                                    <SimpleSelect
                                      name={`articleInstallments`}
                                      label="# Cuotas"
                                      value={installments || values.articleInstallments}
                                      onChange={e => {
                                        this.props.resetQueryCampaign();
                                        this.setState({ installmentsConfirmed: false, installments: e.target.value });
                                        handleChange(e);
                                      }}
                                      options={this.getArticleInstallmentOptions(
                                        values.articles,
                                        installments || values.articleInstallments
                                      )}
                                      inputProps={{
                                        MenuProps: {
                                          disableAutoFocusItem: true
                                        }
                                      }}
                                      helperText={
                                        getIn(errors, `articles.installments`) ||
                                        ' '
                                      }
                                    />
                                  ) : (
                                    <span>
                                      {`${installments || values.articleInstallments} cuota${values.actual > 1 ? 's' : ''}`}
                                    </span>
                                  )}
                                </div>
                              </Grid>
                              <Grid
                                classes={{ item: classes.articleItem }}
                                item
                                xs={4}
                                sm={2}
                              >
                                <div className={classes.articleFieldMargin}>
                                  {item.id !== _get(cardifArticle, 'id') ? (
                                    <OutlinedTextField
                                      name={`articles.${idx}.amount`}
                                      label="Valor unitario"
                                      value={item.amount}
                                      onChange={e => {
                                        this.props.resetQueryCampaign();
                                        handleChange(e);
                                      }}
                                      InputProps={{
                                        inputComponent: Masks.currency
                                      }}
                                      helperText={
                                        getIn(errors, `articles.${idx}.amount`) ||
                                        this.getArticleExtraQuota(
                                          item.id,
                                          allowScoringWithExtraQuota
                                        ) ||
                                        ' '
                                      }
                                    />
                                  ) : (
                                    <span>
                                      {this.getInsuranceValue(values.articles) * installments || values.articleInstallments}
                                    </span>
                                  )}
                                </div>
                              </Grid>
                              <Grid
                                classes={{ item: classes.articleItem }}
                                item
                                xs={4}
                                sm={1}
                              >
                                <div className={classes.articleFieldMargin}>
                                  {item.id !== _get(cardifArticle, 'id') ? (
                                    <OutlinedTextField
                                      name={`articles.${idx}.quantity`}
                                      label="Cant."
                                      value={item.quantity}
                                      InputProps={{
                                        inputComponent: Masks.amount
                                      }}
                                      onChange={e => {
                                        this.props.resetQueryCampaign();
                                        handleChange(e);
                                      }}
                                      helperText={
                                        getIn(
                                          errors,
                                          `articles.${idx}.quantity`
                                        ) ||
                                        this.verifyCategoryQuantity(
                                          values.articles,
                                          item,
                                          errors
                                        ) ||
                                        this.verifyArticleQuantity(
                                          item,
                                          errors
                                        ) ||
                                        ' '
                                      }
                                      error={
                                        !!this.verifyArticleQuantity(item) ||
                                        !!this.verifyCategoryQuantity(
                                          values.articles,
                                          item
                                        )
                                      }
                                    />
                                  ) : (
                                    <span>{1}</span>
                                  )}
                                </div>
                              </Grid>
                              <Grid
                                classes={{ item: classes.articleItem }}
                                item
                                xs={4}
                                sm={1}
                              >
                                <div className={classes.articleFieldMargin}>
                                  <span className={classes.responsiveLable}>
                                    I.V.A.:{' '}
                                  </span>
                                  {formatMoney(this.getTax(values.articles, idx))}
                                </div>
                              </Grid>
                              <Grid
                                classes={{ item: classes.articleItem }}
                                item
                                xs={4}
                                sm={1}
                              >
                                <div className={classes.articleFieldMargin}>
                                  <span className={classes.responsiveLable}>
                                    Subtotal:{' '}
                                  </span>
                                  {formatMoney(
                                    this.getSubtotal(
                                      values.articles,
                                      idx,
                                      installments || values.articleInstallments
                                    )
                                  )}
                                </div>
                              </Grid>
                              <Grid
                                classes={{ item: classes.iconArticleItem }}
                                item
                                xs={4}
                                sm={1}
                              >
                                <div className={classes.articleFieldMargin}>
                                  <IconButton
                                    disabled={this.shouldPreventArticleDeletion(
                                      values.articles,
                                      item
                                    )}
                                    onClick={() => {
                                      this.props.resetQueryCampaign();
                                      arrayHelpers.remove(idx);
                                      if (item.id === _get(cardifArticle, 'id')) {
                                        this.setState({ hasCardifArticle: false });

                                        if (!applyLayerTwo) {
                                          setFieldValue('cardifSellerId', '');
                                          setFieldValue('cardifSellerName', '');
                                          this.setState({ cardifSeller: null, hasCardifSeller: false });
                                        }
                                      }
                                      this._removeArticleAndReloadFinancialPlanbyCategories(arrayHelpers.form.values.articles, idx);
                                    }}
                                    classes={{ root: classes.iconButton }}
                                  >
                                    <CloseIcon classes={{ root: classes.icon }} />
                                    <span className={classes.responsiveLableRed}>
                                      QUITAR
                                    </span>
                                  </IconButton>
                                </div>
                              </Grid>
                            </Grid>
                          ))}

                        </Grid>
                        <div className={classes.addArticleBar}>
                          <PrimaryButton
                            onClick={() => {
                              this.props.resetQueryCampaign();
                              arrayHelpers.push(this.getNewArticle());
                            }}
                            className={classes.addArticleButton}
                          >Agregar artículo
                          </PrimaryButton>
                          {!isSecureSale && canCheckMegaQuotaInfo && supplier.canSellWithMegaquota && (
                            useMegaQuota ? (
                              <Chip
                                icon={<CheckCircle style={{ color: 'white' }} />}
                                size="small"
                                label={'Megacupo aplicado'}
                                classes={{ root: classes.rootChip }}
                              />
                            ) : (
                              <PrimaryButton
                                variant="outlined"
                                loading={attemp === 0 && requestMegaQuotaSend}
                                onClick={() => useMegaQuota ? resetMegaQuota() : this.checkMegaquota()}
                                className={classes.megaQuotaButton}
                              ><img className={classes.imageIcon} src={badge} />Solicitar megacupo
                              </PrimaryButton>
                            )
                          )}

                        </div>
                        {this.props.canCheckVoluntaryInsuranceLayerTwoBuyability && this.props.isVoluntaryInsuranceLayerTwoActive && this.isVoluntaryInsuranceLayerTwoActive() &&
                          supplier.canSellVoluntaryInsuranceLayerTwo && !invoiceValidationSkipped && installments >= minInstallmentsToSellVoluntaryInsuranceLayerTwo && (
                            <Grid item md={4} sm={6} xs={12}>
                              <Field
                                name="voluntaryInsuranceLayerTwo"
                                render={({ field, form }) => (
                                  <CheckboxInput
                                    label={voluntaryInsuranceLayerTwoName}
                                    field={field}
                                    form={form}
                                    value={applyLayerTwo}
                                    onChange={() => {
                                      if (applyLayerTwo) {
                                        this.setState({ applyLayerTwo: false });
                                        this.props.resetLayerTwoInfo();

                                        if (!hasCardifArticle) {
                                          setFieldValue('cardifSellerId', '');
                                          setFieldValue('cardifSellerName', '');
                                          this.setState({ cardifSeller: null, hasCardifSeller: false });
                                        }
                                      } else {
                                        this.props.getVoluntaryInsuranceLayerTwoInfo({
                                          contractId: this.props.debtorContract,
                                          birthDate: this.props.debtorBirthdate,
                                          isBuyingLayerOne: hasCardifArticle
                                        }).then(() => this.setState({ layerTwoRequestDone: true }));
                                      }
                                    }}
                                    classes={{ root: classnames(classes.noMarginTop, classes.checkboxContainer) }}
                                  />
                                )}
                              />
                            </Grid>
                          )}
                      </Fragment>
                    )}
                  />
                  {supplier.canValidatePetSale &&
                    <Fragment>
                      <SegmentHeader className={classes.segmentHeader}>
                        ¿El cliente tiene perro o gato como mascota?
                      </SegmentHeader>
                      <Grid container spacing={24} alignItems="flex-start">
                        <Grid item xs={12}>
                          <RadioField
                            options={options}
                            onChange={handleChange}
                            value={values.hasPets}
                            name="hasPets"
                            classes={{
                              radioButton: classes.radioButtonContainer
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Fragment>
                  }
                  {extraQuotas.length > 0 && (!applyScoring || allowScoringWithExtraQuota) && (
                    <Fragment>
                      <SegmentHeader
                        className={classnames(
                          classes.segmentHeader,
                          classes.extraQuotaHeader
                        )}
                      >
                        EXTRACUPOS DISPONIBLES
                      </SegmentHeader>
                      <div className={classes.sideScroll}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <CustomTableCell align="center">
                                Nombre
                              </CustomTableCell>
                              <CustomTableCell align="center">
                                Categoría
                              </CustomTableCell>
                              <CustomTableCell align="center">
                                Fecha Inicial
                              </CustomTableCell>
                              <CustomTableCell align="center">
                                Fecha Final
                              </CustomTableCell>
                              <CustomTableCell align="center">
                                Valor
                              </CustomTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {extraQuotas.map((item, idx) => (
                              <SimpleTableRow key={idx}>
                                <CustomTableCell align="center">
                                  {item.name}
                                </CustomTableCell>
                                <CustomTableCell align="center">
                                  {item.category_name || 'TODAS'}
                                </CustomTableCell>
                                <CustomTableCell align="center">
                                  {moment(item.start_at, 'YYYY-MM-DD').format(
                                    'DD/MM/YYYY'
                                  )}
                                </CustomTableCell>
                                <CustomTableCell align="center">
                                  {moment(item.end_at, 'YYYY-MM-DD').format(
                                    'DD/MM/YYYY'
                                  )}
                                </CustomTableCell>
                                <CustomTableCell align="center">
                                  {formatMoney(item.value)}
                                </CustomTableCell>
                              </SimpleTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </div>
                    </Fragment>
                  )}
                  <SegmentHeader className={classes.segmentHeader}>
                    DATOS DE LA VENTA
                  </SegmentHeader>
                  <Grid container alignItems="center" classes={{ container: classes.gridItem }}>
                    {(hasCardifArticle || applyLayerTwo) && isSendVoluntaryInsuranceSalesmanActive && (
                      <Grid item md={4} sm={6} xs={12}>
                        <FormControlLabel
                          className={classes.checkbox}
                          control={
                            <Checkbox
                              name="hasCardifArticle"
                              checked={hasCardifSeller}
                              onChange={() => {
                                setFieldValue('cardifSellerId', '');
                                setFieldValue('cardifSellerName', '');
                                this.setState({ cardifSeller: null });
                                this.setState({ hasCardifSeller: !hasCardifSeller });
                              }}
                            />
                          }
                          label="Ingresar vendedor cardif"
                        />
                      </Grid>
                    )}
                    {hasCardifSeller && <Grid item md={3} sm={6} xs={12}>
                      <Field
                        name="cardifSellerId"
                        onChange={handleChange}
                        render={({ field, form }) => {
                          return (
                            <AutocompleteInput
                              id="cardifSellerId"
                              name="cardifSellerId"
                              label="Vendedor Cardif:"
                              margin="normal"
                              variant="outlined"
                              error={
                                (touched.cardifSellerId && !!errors.cardifSellerId) ||
                                !!errors.cardifSellerId
                              }
                              helperText={
                                (touched.cardifSellerId && errors.cardifSellerId) ||
                                errors.cardifSellerId
                              }
                              value={this.state.cardifSeller || values.cardifSellerName || ''}
                              onChange={change => {
                                setFieldValue('cardifSellerId', '');
                                setFieldValue('cardifSellerName', '');
                                this.setState({ cardifSeller: change });
                              }}
                              suggestions={sellers}
                              getSuggestions={value =>
                                this.getSuggestions(sellers, value)
                              }
                              onSuggestionSelected={item => {
                                setFieldValue('cardifSellerName', item.label);
                                setFieldValue('cardifSellerId', item.value);
                                const sellerSelected = sellers.filter(
                                  seller => seller.id == item.value
                                );
                                const sellerPersonalDataHandling = sellerSelected[0].attributes.personal_data_handling;
                                this.setState({
                                  disabledNext: sellerPersonalDataHandling === null,
                                  sellerDataAuthorization: sellerPersonalDataHandling,
                                  isSellerBlocked: sellerSelected[0].attributes.blocked,
                                  blockedModalNotification: sellerSelected[0].attributes.blocked
                                });
                              }}
                              field={field}
                              form={form}
                              classes={{
                                root: classes.autocompleteRoot
                              }}
                            />
                          );
                        }}
                      />
                    </Grid>}
                  </Grid>
                  <Grid container alignItems="center">
                    {this.props.canValidateCampaing &&
                      this.props.isGracePeriodActive && (
                        <Grid item md={4} sm={6} xs={12}>
                          <Field
                            name={'gracePeriod'}
                            render={({ field, form }) => (
                              <CheckboxInput
                                label={'Periodo de Gracia'}
                                field={field}
                                form={form}
                                value={
                                  campaignValidationResponse.data &&
                                  campaignValidationResponse.data.isValid
                                }
                                onChange={() => {
                                  if (isSecureSale) {
                                    this.props.newAlert(
                                      'error',
                                      'ERROR:',
                                      'No se puede aplicar periodo de gracia para ventas con fianza.'
                                    );
                                    return;
                                  }

                                  if (
                                    campaignValidationResponse.data &&
                                    campaignValidationResponse.data.isValid
                                  ) {
                                    this.props.resetQueryCampaign();
                                  } else {
                                    this.setState({
                                      gracePeriodModalIsOpen: true,
                                      gracePeriodModalData: values
                                    });
                                  }
                                }}
                                classes={{
                                  root: classnames(
                                    classes.noMarginTop,
                                    classes.checkboxContainer
                                  )
                                }}
                              />
                            )}
                          />
                        </Grid>
                      )}
                    <Grid item md={4} sm={6} xs={12}>
                      <InfoField label="Valor a financiar:">
                        {formatMoney(
                          this.getFinanced(
                            values.articles,
                            values.initialPayment,
                            installments || values.articleInstallments,
                            false
                          ) -
                          this.getLessCardif(
                            values.articles,
                            installments || values.articleInstallments
                          ) +
                          this.getSecureValue(
                            values.articles,
                            values.initialPayment,
                            installments || values.articleInstallments
                          )
                        )}
                      </InfoField>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <InfoField label="Valor cuota mensual:">
                        {formatMoney(
                          this.getMonthlyPayment(
                            values.articles,
                            values.initialPayment,
                            installments || values.articleInstallments
                          ) +
                          this.getSecureValue(
                            values.articles,
                            values.initialPayment,
                            installments || values.articleInstallments
                          ) / installments || values.articleInstallments
                        )}
                      </InfoField>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <OutlinedTextField
                        name="initialPayment"
                        type={'number'}
                        label="Cuota inicial:"
                        error={errors.initialPayment ? true : false}
                        helperText={errors.initialPayment || '* Requerido'}
                        value={values.initialPayment}
                        onChange={e => {
                          this.props.resetQueryCampaign();
                          handleChange(e);
                        }}
                        className={classes.initialPayment}
                      />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <InfoField label="Valor seguro:">
                        {formatMoney(
                          this.getMonthlyInsurance(
                            values.articles,
                            values.initialPayment,
                            installments || values.articleInstallments
                          )
                        )}
                      </InfoField>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      {isSecureSale && !invoiceValidationSkipped ? (
                        <InfoField label="Valor de la fianza:">
                          {formatMoney(
                            this.getSecureValue(
                              values.articles,
                              values.initialPayment,
                              installments || values.articleInstallments
                            )
                          )}
                        </InfoField>
                      ) : (
                        <InfoField
                          label="TOTAL DE LA VENTA:"
                          customValue={classes.salesTotal}
                        >
                          {formatMoney(
                            this.getTotal(
                              values.articles,
                              installments || values.articleInstallments
                            )
                          )}
                        </InfoField>
                      )}
                    </Grid>
                  </Grid>
                  {isSecureSale && <Grid container justify="flex-end">
                    <Grid item md={4} sm={6} xs={12}>
                      <InfoField
                        label="TOTAL DE LA VENTA:"
                        customValue={classes.salesTotal}
                      >
                        {formatMoney(
                          this.getTotal(
                            values.articles,
                            installments || values.articleInstallments
                          )
                        )}
                      </InfoField>
                    </Grid>
                  </Grid>}

                  <OutlinedTextField
                    multiline
                    rows={4}
                    name="observations"
                    label="Observaciones"
                    value={values.observations}
                    onChange={e => {
                      this.props.resetQueryCampaign();
                      handleChange(e);
                    }}
                    className={classes.observations}
                  />
                  <StepperButtons
                    disabledNext={Object.keys(errors).length > 0 ||
                      (this.state.disabledNext && orgSellerDataAuthorization) ||
                      (isBlackListActive && this.state.isSellerBlocked)
                    }
                    onSubmit={handleSubmit}
                    goBack={this.props.goBack}
                  />
                </Form>
              )}
            />
            {this.state.gracePeriodModalIsOpen && (
              <QueryCampaignModal
                campaignTitle={'Periodo de Gracia'}
                onClose={this._handleCampaignClose}
                onSubmit={this._handleCampaignSubmit}
                onApplyCampaign={this._handleApplyCampaign}
                loading={this.props.requestCampaignValidationSend}
                validationResponse={campaignValidationResponse}
                campaignIds={this.props.gracePeriodCampaignIds}
              />
            )}
            {this.state.sellerDataAuthorization === null &&
              orgSellerDataAuthorization && (
                <ConfirmDialog
                  title={
                    'Notificación del uso de tratamiento de datos personales'
                  }
                  text={`El vendedor seleccionado no ha dado respuesta a la autorización de tratamiento de datos personales`}
                  buttonTextConfirm={'Aceptar'}
                  disableBackdropClick={true}
                  onClose={this.handleCloseDataAuthorizationModalNotification}
                  onConfirm={this.handleCloseDataAuthorizationModalNotification}
                />
              )}
            {this.state.blockedModalNotification &&
              isBlackListActive && this.state.isSellerBlocked && (
                <ConfirmDialog
                  title={
                    'Notificación de bloqueo'
                  }
                  text={`Lo sentimos, tiene deshabilitada esta función. Por favor, comuniquese con el area comercial.`}
                  buttonTextConfirm={'Aceptar'}
                  disableBackdropClick={true}
                  onClose={this.handleCloseModalModalNotification}
                  onConfirm={this.handleCloseModalModalNotification}
                />
              )}
            <CustomDialog
              open={
                this.props.canValidateCampaing &&
                this.props.isGracePeriodActive &&
                this.state.validateGracePeriodUse
              }
              onClose={() => {
                this.setState({ validateGracePeriodUse: false });
              }}
              onConfirm={() => {
                this.validateSale(this.state.gracePeriodModalData);
                this.setState({ validateGracePeriodUse: false });
              }}
              title={`Notificación - Periodo de gracia`}
              buttonText={'CONTINUAR'}
            >
              <p>
                Esta venta podría aplicar a periodo de gracia. ¿Desea continuar
                sin validar?
              </p>
            </CustomDialog>
            <CustomDialog
              open={layerTwoRequestDone}
              onClose={() => this.setState({ layerTwoRequestDone: false }, () => this.props.resetLayerTwoInfo())}
              onConfirm={() => this.setState({ layerTwoRequestDone: false, applyLayerTwo: isAbleToBuyLayerTwo, installments })}
              onConfirmSecondary={() => this.setState({ layerTwoRequestDone: false }, () => this.props.resetLayerTwoInfo())}
              title={`${isAbleToBuyLayerTwo ? "Activación" : "Mensaje informativo"}: ${voluntaryInsuranceLayerTwoName}`}
              buttonText={isAbleToBuyLayerTwo ? "ACTIVAR" : "CERRAR"}
              buttonSecondaryText={isAbleToBuyLayerTwo ? "VOLVER" : ""}
            >
              <p>
                {
                  isAbleToBuyLayerTwo
                    ? `¿Desea activar el ${voluntaryInsuranceLayerTwoName.toLowerCase()} por un valor mensual de $${formatBack(voluntaryInsuranceLayerTwoValue, 'currency')} a un plazo de (${installments}) cuotas?`
                    : `Se incumplen las siguientes condiciones:`
                }
              </p>
              <ul>
                {voluntaryInsurance.messages && voluntaryInsurance.messages.map((message, index) => (
                  <li key={index}> {message} </li>
                ))}
              </ul>
            </CustomDialog>
            <CustomDialog
              open={
                this.props.isInstallmentConfirmationActive &&
                this.state.validateInstallmentConfirmationUse
              }
              onClose={() => this.setState({ validateInstallmentConfirmationUse: false })}
              onConfirm={() => this.setState({ validateInstallmentConfirmationUse: false, installmentsConfirmed: true })}
              onConfirmSecond={() => this.setState({ validateInstallmentConfirmationUse: false })}
              title={`Notificación - Cuotas de la venta`}
              secondButtonText={'VOLVER'}
              buttonText={'CONFIRMAR'}
              disableBackdropClick={true}
            >
              <p>
                El número de cuotas pactadas para la venta será de <span className={classes.salesTotal}>{installments} </span>
                con un valor de <span className={classes.salesTotal}>{this.state.monthlyInstalment}</span>{installments !== 1 && ' cada una'}.
              </p>
            </CustomDialog>
            <CustomDialog
              open={this.state.showInvoiceSkipModal && invoiceValidationSkipped}
              onClose={() => {
                this.setState({ showInvoiceSkipModal: false });
              }}
              onConfirm={() =>
                this.props.goToStep(
                  `/${getProcessRoute(this.props.process)}/validate-invoice`,
                  this.props.originalProcess
                )
              }
              title={'Error — Omitir validación de facturas'}
              buttonText={'Retroceder'}
            >
              <p>
                {`La venta supera el valor permitido para omitir la validación de facturas (${formatMoney(
                  this.props.invoiceValidationData.sale_cap_for_invoice_validation
                )}). Para registrar la venta puedes realizar la validación de las facturas o modificar el valor de ésta.`}
              </p>
            </CustomDialog>
            <CustomDialog
              loading={megaquotaLoading}
              onClose={() => this.setState({ megaquotaModal: megaquotaLoading })}
              open={megaquotaModal}
              disableBackdropClick={true}
              onConfirm={() => megaQuotaValue && isMegaQuotaAbleToApply ? this.applyMegaquota(megaQuotaValue) : this.setState({ megaquotaModal: false })}
              title={'Consulta Megacupo'}
              buttonText={megaQuotaValue && isMegaQuotaAbleToApply ? 'APLICAR' : 'CERRAR'}
            >
              {
                megaQuotaValue !== null ? (
                  applyScoring ? (
                    isMegaQuotaAbleToApply ? (
                      <>
                        <p>El cupo total utilizable con Megacupo es de <b>{formatMoney(megaQuotaValue)}</b>. Se omitirá el uso de Scoring.</p>
                        {this.renderMegaquotaCategories()}
                      </>
                    ) : (
                      <p>El cupo total con Scoring es mayor al otorgado por Megacupo. Se procederá con Scoring.</p>
                    )
                  ) : (
                    isMegaQuotaAbleToApply ? (
                      <>{this.renderMegaquotaWithScoring()}</>
                    ) : (
                      <p>Su cupo disponible es de: <b>{formatMoney(availableQuota)}</b></p>
                    )
                  )
                ) : (
                  attemp === 0 || attemp === 12 ? <p>Se agotó el tiempo de respuesta para consulta de Megacupo.</p> : <p>Se está realizando la consulta del Megacupo. Espere un momento.</p>
                )
              }
            </CustomDialog>
          </Paper>
        )
        }
      </div>
    );
  }
}

const styles = theme => ({
  sideScroll: {
    overflowX: 'auto'
  },
  gridItem: {
    marginBottom: '20px',
  },
  articleFieldMargin: {
    margin: 4
  },
  iconButton: {
    borderRadius: '40px'
  },
  responsiveHeader: {
    display: 'none',
    backgroundColor: '#db4945',
    height: 47,
    color: 'white',
    textAlign: 'center',
    lineHeight: '47px',
    fontSize: 18,
    '@media (max-width: 898px)': {
      display: 'block'
    }
  },
  responsiveLable: {
    display: 'none',
    '@media (max-width: 898px)': {
      display: 'block',
      fontWeight: 'bold'
    }
  },
  responsiveLableRed: {
    display: 'none',
    '@media (max-width: 898px)': {
      display: 'inline',
      color: 'red',
      textAlign: 'center',
      fontSize: 15
    }
  },
  articlesHeader: {
    backgroundColor: '#db4945',
    height: 47,
    '@media (max-width: 898px)': {
      display: 'none'
    }
  },
  articleHeaderItem: {
    textAlign: 'center',
    margin: 'auto',
    color: 'white',
    fontSize: 13,
    fontWeight: 'bold'
  },
  articleItemContainerShade: {
    backgroundColor: '#eceef1'
  },
  articleItem: {
    textAlign: 'center',
    margin: 'auto',
    '@media (max-width: 898px)': {
      textAlign: 'left !important',
      fontSize: '15px'
    }
  },
  iconArticleItem: {
    textAlign: 'center',
    margin: 'auto'
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.54)',
    '@media (max-width: 898px)': {
      color: 'red',
      fontSize: 18
    }
  },
  segmentHeader: {
    marginTop: 3 * theme.spacing.unit,
    marginBottom: 2 * theme.spacing.unit,
    '&:first-of-type': {
      marginTop: 0
    }
  },
  extraQuotaHeader: {
    marginBottom: 0,
    textAlign: 'center',
    background: '#db4945',
    borderBottom: '1px solid rgba(255,255,255,0.3)',
    '& p': {
      color: 'white'
    }
  },
  actionBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 2 * theme.spacing.unit
  },
  button: {
    paddingLeft: 8 * theme.spacing.unit,
    paddingRight: 8 * theme.spacing.unit
  },
  addArticleBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 3 * theme.spacing.unit,
    marginBottom: 3 * theme.spacing.unit
  },
  addArticleButton: {
    alignSelf: 'flex-end',
    paddingLeft: 8 * theme.spacing.unit,
    paddingRight: 8 * theme.spacing.unit
  },
  megaQuotaButton: {
    alignSelf: 'flex-end',
    backgroundColor: '#fc5e03',
    color: 'white',
    paddingLeft: 4 * theme.spacing.unit,
    paddingRight: 4 * theme.spacing.unit
  },
  rootChip: {
    backgroundColor: 'rgb(33, 198, 157)',
    color: 'white',
    height: 24,
    fontSize: 15,
    paddingTop: 15,
    paddingBottom: 15
  },
  form: {
    display: 'flex',
    flexDirection: 'column'
  },
  salesTotal: {
    fontWeight: 'bold'
  },
  observations: {
    marginTop: 5 * theme.spacing.unit
  },
  initialPayment: {
    width: '80%'
  },
  cellLabel: {
    width: '100px'
  },
  cellField: {
    width: '200px'
  },
  cellAmount: {
    width: '100px'
  },
  cellInstallments: {
    width: '200px'
  },
  textInsurance: {
    marginLeft: '15px'
  },
  articlesSelect: {
    minWidth: '400px'
  },
  cellAction: {
    width: '100px',
    textAlign: 'center'
  },
  imageIcon: {
    color: 'white',
    marginRight: '5px',
    marginLeft: '-5px',
    height: '28px',
    width: '28px'
  },
});

PortalSelectArticlesPage.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withStyles(styles)(PortalSelectArticlesPage);
