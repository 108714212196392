import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '../../../shared/Modals/Modal';
import TextFieldInput from '../../../shared/Inputs/TextField';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import { Formik, Field } from 'formik';
import ProgressButton from '../../../shared/Buttons/ProgressButton';
import Grid from '@material-ui/core/Grid';
import GridItem from '../../../shared/GridItem';
import SimpleSelect from '../../../shared/Selects/SimpleSelect';
import combineStyles from '../../../../helpers/combineStyles';
import LayoutStyles from '../../../../styles/layout';
import TypographyStyles from '../../../../styles/typography';
import ButtonStyles from '../../../../styles/button';
import SpacingStyles from '../../../../styles/helpers/spacing';
import _forEach from 'lodash/forEach';
import { POINT_OF_SALES_VALIDATION_METHOD } from '../../../../constants/enums';

import EditBiometricReader from './EditBiometricReader.validation';

const styles = theme => ({
  titleWrapper: {
    display: 'flex',
    marginBottom: `${theme.spacing.unit * 2.125}px !important`,
    position: 'relative'
  },
  title: {
    flex: 1,
    fontWeight: 'bold !important'
  },
  resetLinkButton: {
    position: 'absolute',
    right: 0,
    top: -6
  },
  resetLink: {
    textTransform: 'capitalize',
    color: `${theme.palette.primary.main} !important`
  },
  modalFooter: {
    marginTop: `${theme.spacing.unit * 5}px !important`
  }
});

export class EditBiometricReaderModal extends Component {
  state = {
    supplier: '',
    selectedSupplier: false
  };

  _handleOnClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.erros !== this.props.errors) {
      const formik = this.formik;
      formik.setErrors(this._handleErrors(this.props.errors));
    }
  }

  _handleOnSave = async user => {
    const { onSave } = this.props;
    onSave(user);
  };

  _handleErrors = apiErrors => {
    const errors = {};
    _forEach(apiErrors, (v, k) => (errors[k] = v[0]));
    return errors;
  };

  pointsOfSalesMapToOptions = pointsOfSales => {
    if (!pointsOfSales) return [];
    return pointsOfSales.map(item => {
      return {
        key: item.id,
        id: item.id,
        value: item.id,
        label: item.name
      };
    });
  };

  validationMethodsMapToOptions = () => {
    if (!POINT_OF_SALES_VALIDATION_METHOD) return [];
    return POINT_OF_SALES_VALIDATION_METHOD.map(item => {
      return {
        key: item.id,
        id: item.id,
        value: item.id,
        label: item.name
      };
    });
  };

  userTypeOptions = () => {
    const types = [
      {
        key: true,
        value: true,
        label: 'Sí'
      },
      {
        key: false,
        value: false,
        label: 'No'
      }
    ];
    return types;
  };

  render() {
    const {
      classes,
      open = true,
      isSubmitting,
      biometricReader = {},
      pointsOfSale
    } = this.props;

    return (
      <Formik
        ref={ref => (this.formik = ref)}
        enableReinitialize={true}
        validationSchema={EditBiometricReader}
        initialValues={{
          pointOfSaleId: biometricReader.pointOfSaleId || '',
          methodValidation: biometricReader.methodValidation || '',
          serial: biometricReader.serial,
          mac: biometricReader.mac
        }}
        onSubmit={values => {
          this._handleOnSave(values);
          this._handleOnClose();
        }}
        onReset={(values, actions) => {
          actions.resetForm();
          this._handleOnClose();
        }}
        validateOnChange={false}
        validateOnBlur={false}
        render={({
          values,
          handleChange,
          handleSubmit,
          handleReset,
          dirty
        }) => (
          <Modal
            open={open}
            onClose={this._handleOnClose}
            classes={{
              footer: classes.modalFooter
            }}
            body={
              <Fragment>
                <div className={classes.titleWrapper}>
                  <p className={classnames(classes.headline6, classes.title)}>
                    {'Editar Datos Lector Biométrico'}
                  </p>
                </div>
                <Grid container spacing={16}>
                  <GridItem xs={12}>
                    <SimpleSelect
                      name={'pointOfSaleId'}
                      onChange={handleChange}
                      value={values.pointOfSaleId}
                      label={'Punto de venta'}
                      options={this.pointsOfSalesMapToOptions(pointsOfSale)}
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    <Field
                      name="serial"
                      onChange={handleChange}
                      render={({ field, form }) => {
                        return (
                          <TextFieldInput
                            value={values.serial}
                            label={'Serial'}
                            fullWidth
                            field={field}
                            form={form}
                          />
                        );
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    <Field
                      name="mac"
                      onChange={handleChange}
                      render={({ field, form }) => {
                        return (
                          <TextFieldInput
                            value={values.mac}
                            label={'MAC'}
                            fullWidth
                            field={field}
                            form={form}
                          />
                        );
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    <SimpleSelect
                      name={'methodValidation'}
                      onChange={handleChange}
                      value={values.methodValidation}
                      label={'Tipo de validación'}
                      options={this.validationMethodsMapToOptions()}
                    />
                  </GridItem>
                </Grid>
              </Fragment>
            }
            footer={
              <Fragment>
                <div className={classes.spacer} />
                <Button
                  className={classes.button}
                  onClick={handleReset}
                  color={'secondary'}
                  classes={{ label: classes.buttonLabel }}
                >
                  {'Cancelar'}
                </Button>
                <ProgressButton
                  onClick={handleSubmit}
                  className={classes.button}
                  classes={{
                    disabled: classes.buttonDisabled,
                    label: classes.buttonLabel
                  }}
                  color={'secondary'}
                  disabled={!dirty || isSubmitting}
                  isSubmitting={isSubmitting}
                >
                  {'Guardar'}
                </ProgressButton>
              </Fragment>
            }
          />
        )}
      />
    );
  }
}
export default withStyles(
  combineStyles(
    styles,
    LayoutStyles,
    TypographyStyles,
    ButtonStyles,
    SpacingStyles
  )
)(EditBiometricReaderModal);
