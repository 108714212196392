import _get from 'lodash/get';
import { PoliciesCode } from '../../../constants/enums.js';

export const columns = [
  {
    title: 'Estrato',
    name: 'stratum'
  },
  {
    title: 'Cupo de estrato',
    name: 'stratumQuota'
  },
  {
    title: 'Cupo adicional rollover',
    name: 'rolloverQuota'
  },
  {
    title: 'Ubicación Geográfica',
    name: 'geographicLocation'
  }
];

export const mapData = data => (
  data.map(datum => {
    const allowedTimeSinceGasInstallation = datum.policiesConfigurations.find(p => p.quotaAssignPolicyId === PoliciesCode.GasInstallationDate);
    const numberOfSuspensionsPeriodToEvaluate = datum.policiesConfigurations.find(p => p.quotaAssignPolicyId === PoliciesCode.Suspensions);
    const allowedTimeSinceLastPunishment = datum.policiesConfigurations.find(p => p.quotaAssignPolicyId === PoliciesCode.Punishment);
    const allowedTimeSinceLastDateBrillaProductWasInArrears = datum.policiesConfigurations.find(p => p.quotaAssignPolicyId === PoliciesCode.ArrearInBrillaProduct);
    const allowedTimeSinceLastDateGasProductWasInArrears = datum.policiesConfigurations.find(p => p.quotaAssignPolicyId === PoliciesCode.ArrearInGasProduct);
    const allowedTimeSinceLastDateOtherServicesWereInArrears = datum.policiesConfigurations.find(p => p.quotaAssignPolicyId === PoliciesCode.ArrearsInOtherService);
    const maximumNetworkBalance = datum.policiesConfigurations.find(p => p.quotaAssignPolicyId === PoliciesCode.NetworkBalance);
    const requiredOwnershipPeriod = datum.policiesConfigurations.find(p => p.quotaAssignPolicyId === PoliciesCode.PropertyOwnerShipDate);
    return {
      id: Number(datum.id),
      stratum: datum.stratum || '--',
      stratumQuota: datum.defaultQuota.toString() || '--',
      rolloverQuota: datum.rolloverQuota.toString() || '--',
      geographicLocation: _get(datum, 'geographicLocation.displayDescription', null) || 'TODAS',
      geographicLocationId: _get(datum, 'geographicLocation.id', null) || null,
      allowedTimeSinceGasInstallation: allowedTimeSinceGasInstallation ? allowedTimeSinceGasInstallation.parameterValue : '',
      numberOfSuspensionsPeriodToEvaluate: numberOfSuspensionsPeriodToEvaluate ? numberOfSuspensionsPeriodToEvaluate.parameterValue : '',
      allowedTimeSinceLastPunishment: allowedTimeSinceLastPunishment ? allowedTimeSinceLastPunishment.parameterValue : '',
      allowedTimeSinceLastDateBrillaProductWasInArrears: allowedTimeSinceLastDateBrillaProductWasInArrears ? allowedTimeSinceLastDateBrillaProductWasInArrears.parameterValue : '',
      allowedTimeSinceLastDateGasProductWasInArrears: allowedTimeSinceLastDateGasProductWasInArrears ? allowedTimeSinceLastDateGasProductWasInArrears.parameterValue : '',
      allowedTimeSinceLastDateOtherServicesWereInArrears: allowedTimeSinceLastDateOtherServicesWereInArrears ? allowedTimeSinceLastDateOtherServicesWereInArrears.parameterValue : '',
      maximumNetworkBalance: maximumNetworkBalance ? maximumNetworkBalance.parameterValue : '',
      requiredOwnershipPeriod: requiredOwnershipPeriod ? requiredOwnershipPeriod.parameterValue : ''
    };
  })
);