import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import RegisterSchedule from './RegisterSchedule';
import { newAlert } from '../../../actions/alert_actions';

import {
  closeRegisterScheduleModal,
  registerSchedule,
  updateErrorsOnRegisterSchedule,
  updateSchedule
} from '../../../actions/register_schedule_actions';

import {
  fetchContract,
  clearContract
} from '../../../actions/contract_actions';

import { fetchSchedulePointOfSale, fetchSchedulePointsOfSale } from '../../../actions/points_of_sale_actions';


function mapStateToProps(state) {
  return {
    loading: state.requestState.requestRegisterScheduleSend,
    loadingPointOfSales: state.requestState.requestSchedulePointsOfSaleSend,
    isOpen: state.registerSchedule.isRegsiterScheduleModalOpen,
    homevisit: state.registerSchedule.homevisit || null,
    categories: state.globalSetting.orgData.categories,
    scheduleCategories: state.globalSetting.orgData.schedule_categories,
    scheduleSaleChannels: state.globalSetting.orgData.get_schedule_sale_channels,
    isAutomaticSchedulePointOfSaleActive: state.globalSetting.orgData.is_automatic_schedule_point_of_sale_active,
    serverErrors: state.requestState.requestErrorsOnRegisterSchedule,
    registerScheduleWithEmail: state.globalSetting.orgData.register_schedule_with_email,
    requestSendContract: state.requestState['requestContract-DetailSend'],
    contractDetail: state.contract.contractDetail
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newAlert: bindActionCreators(newAlert, dispatch),
    registerSchedule: bindActionCreators(registerSchedule, dispatch),
    closeRegisterModal: bindActionCreators(closeRegisterScheduleModal, dispatch),
    fetchSchedulePointsOfSale: bindActionCreators(fetchSchedulePointsOfSale, dispatch),
    fetchSchedulePointOfSale: bindActionCreators(fetchSchedulePointOfSale, dispatch),
    fetchContract: bindActionCreators(fetchContract, dispatch),
    clearContract: bindActionCreators(clearContract, dispatch),
    updateErrorsOnRegisterSchedule: bindActionCreators(updateErrorsOnRegisterSchedule, dispatch),
    updateSchedule: bindActionCreators(updateSchedule, dispatch),
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(RegisterSchedule);
