export const listSanitizer = params => {
  const sanitizedParams = {
    perPage: params.perPage,
    page: params.page,
    geographicLocationId: params.geographicLocationId,
    stratum: params.stratum,
    quota: params.quota
  };

  return sanitizedParams;
};

export const updateSanitizer = params => {
  const sanitizedParams = {
    stratumQuota: Number(params.stratumQuota.replaceAll('.', '')),
    rolloverQuota: Number(params.rolloverQuota.replaceAll('.', '')),
    allowedTimeSinceGasInstallation: params.allowedTimeSinceGasInstallation.toString(),
    allowedTimeSinceLastDateBrillaProductWasInArrears: params.allowedTimeSinceLastDateBrillaProductWasInArrears.toString(),
    allowedTimeSinceLastDateGasProductWasInArrears: params.allowedTimeSinceLastDateGasProductWasInArrears.toString(),
    allowedTimeSinceLastDateOtherServicesWereInArrears: params.allowedTimeSinceLastDateOtherServicesWereInArrears.toString(),
    allowedTimeSinceLastPunishment: params.allowedTimeSinceLastPunishment.toString(),
    maximumNetworkBalance: params.maximumNetworkBalance.toString().replaceAll('.', ''),
    numberOfSuspensionsPeriodToEvaluate: params.numberOfSuspensionsPeriodToEvaluate.toString(),
    requiredOwnershipPeriod: params.requiredOwnershipPeriod.toString()
  };
  return sanitizedParams;
};

export const createSanitizer = params => {
  const sanitizedParams = {
    stratum: Number(params.stratum),
    geographicLocationId: params.geographicLocationId ? Number(params.geographicLocationId) : null,
    stratumQuota: Number(params.stratumQuota.replaceAll('.', '')),
    rolloverQuota: Number(params.rolloverQuota.replaceAll('.', '')),
    allowedTimeSinceGasInstallation: params.allowedTimeSinceGasInstallation.toString(),
    allowedTimeSinceLastDateBrillaProductWasInArrears: params.allowedTimeSinceLastDateBrillaProductWasInArrears.toString(),
    allowedTimeSinceLastDateGasProductWasInArrears: params.allowedTimeSinceLastDateGasProductWasInArrears.toString(),
    allowedTimeSinceLastDateOtherServicesWereInArrears: params.allowedTimeSinceLastDateOtherServicesWereInArrears.toString(),
    allowedTimeSinceLastPunishment: params.allowedTimeSinceLastPunishment.toString(),
    maximumNetworkBalance: params.maximumNetworkBalance.toString().replaceAll('.', ''),
    numberOfSuspensionsPeriodToEvaluate: params.numberOfSuspensionsPeriodToEvaluate.toString(),
    requiredOwnershipPeriod: params.requiredOwnershipPeriod.toString()
  };
  return sanitizedParams;
};