import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';

import {
  AppBar,
  Drawer,
  Grid,
  Toolbar,
  CssBaseline,
  IconButton,
  Hidden
} from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
/* TODO NICE TO HAVE use react-async-component to reduce size of initial load */
import ChangePasswordModal from '../UserDetail/modals/ChangePasswordModal';

import NewsDrawer from './NewsDrawer';
import AnnouncementIcon from '@material-ui/icons/Announcement';

import AvatarLayout from './AvatarLayout';
import SidebarMenu from './SidebarMenu';
import RoutesRenderer from './RoutesRenderer';
import DashboardStyles from './__styles__/Dashboard.styles';
import Notifications from './Notifications';
import NotificationsCircle from './NotificationsCircle';

import socketIOClient from 'socket.io-client';
import deployModal from './DeployModal';
import SliderModal from '../../shared/Modals/SliderModal';
import CustomDialog from '../../shared/Dialogs/CustomDialog';
import { Typography } from '@material-ui/core';
import _get from 'lodash/get';
import DataAuthorizationModal from '../../shared/Modals/DataAuthorizationModal';

class DashboardPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    container: PropTypes.object,
    theme: PropTypes.object.isRequired,
    permissions: PropTypes.array.isRequired,
    history: PropTypes.object,
    logout: PropTypes.func,
    location: PropTypes.object,
    currentUser: PropTypes.object
  };

  mainNode = null;

  // GLOBAL FUNCTION FOR ANY LOGGED IN USER
  componentDidMount() {
    if (
      !this.props.requestOrgDataSend &&
      Object.keys(this.props.orgData).length == 0
    ) {
      this.props.fetchOrgData();
      this.props.fetchNotifications();
      this.props.fetchAnnouncements();
    }
    this.subscribeToSocket();
  }

  componentWillMount() {
    !this.props.companyCode && this.props.fetchSessionData();
  }

  subscribeToSocket = () => {
    const socket = socketIOClient(process.env.REACT_APP_WEB_SOCKET);
    socket.on('osf-messages', data => {
      if (data.action === 'forced_logout') {
        this.props.logout();
      } else {
        this.props.openDeployModal(data);
      }
    });
  };

  state = {
    mobileOpen: false,
    newsDrawerOpen: false,
    auth: true,
    anchorEl: null,
    user: {},
    alliesOpen: true,
    portalOpen: false,
    profileMenuOpen: false,
    isEditUserOpen: true,
    siteState: sessionStorage.getItem('siteState')
      ? sessionStorage.getItem('siteState')
      : true
  };

  _updatePasswordNotificationDate = () => {
    return this.props.updateUser(
      { ...this.props.currentUser, isCurrentUser: true },
      {
        last_password_change_notification: new Date()
      },
      'updatePasswordNotificationChange'
    );
  };

  _handleCloseChangePasswordModal = () => {
    this.props.logout();
  };

  _handleChangePassword = passwordInfo => {
    const user = { ...this.props.currentUser, isCurrentUser: true };
    return this.props.updatePasswordWithToken(
      { userId: user.id, ...passwordInfo },
      user
    );
  };

  _handleProfileMenuClick = () => {
    this.setState({
      anchorEl: document.getElementById('profileButton'),
      profileMenuOpen: true
    });
  };

  _handleProfileMenuClose = () => {
    this.setState({ anchorEl: null, profileMenuOpen: false });
  };

  _handleNewsDrawerOpen = () => this.setState({ newsDrawerOpen: true });
  _handleNewsDrawerClose = () => this.setState({ newsDrawerOpen: false });

  onRefMain = mainNode => {
    this.mainNode = mainNode;
  };

  onRefMain = mainNode => {
    this.mainNode = mainNode;
  };

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  handleLogout = e => {
    e.preventDefault();
    this.props.history.push('/login');
    this.props.logout();
  };

  handleClick = () => {
    this.setState({ alliesOpen: !this.state.alliesOpen });
  };

  _handlerSite = resp => {
    sessionStorage.setItem('siteState', resp);
    this.setState({ siteState: resp });
  };

  getStateOrgData = () => {
    if (
      this.props.orgData.site_on_maintenance &&
      this.props.orgData.site_on_maintenance === '1'
    ) {
      return true;
    } else {
      return false;
    }
  };

  _notificationPermission = currentUser => {
    if (!currentUser) return false;
    const {
      canCreateManualQuota,
      canReviewManualQuota,
      canApproveManualQuota,
      canListManualQuota,
      canListHomeVisits,
      canUpdateHomeVisits,
      canApproveSeller,
      canRevokeSeller,
      canUpdateUserPoS,
      canLegalizeHomeVisits
    } = currentUser;

    return (
      canCreateManualQuota ||
      canReviewManualQuota ||
      canApproveManualQuota ||
      canListManualQuota ||
      canListHomeVisits ||
      canUpdateHomeVisits ||
      canApproveSeller ||
      canRevokeSeller ||
      canUpdateUserPoS ||
      canLegalizeHomeVisits
    );
  };

  getNotSeenAnnouncement = announcements => {
    return announcements.filter(
      item =>
        item.announcement.alwaysOnSlider ||
        (item.announcement.showOnSlider && !item.seen)
    );
  };

  _getUser = () => {
    const user = {
      ...this.props.currentUser,
      active: true,
      isCurrentUser: true
    };
    return user;
  };

  _updatePersonalDataHandlingPermission = val => {
    return this.props
      .updateUser(
        this._getUser(),
        {
          personal_data_handling: val
        },
        'updatePersonalDataHandlingPermission'
      )
      .then(({ success }) => {
        if (success) {
          this._handleCloseEditUserModal();
        }
      });
  };

  _handleCloseEditUserModal = () => this.setState({ isEditUserOpen: false });

  render() {
    const {
      classes,
      theme,
      currentUser,
      menuFunctions,
      notifications,
      companyCode,
      announcements,
      requestAnnouncementsSend,
      requestChangePasswordSend,
      freelanceRoles,
      asesorRoles,
      companyName,
      orgUserDataAuthorization
    } = this.props;

    const { personalDataHandling } = this.props.currentUser;
    let element;

    element = this.getStateOrgData();

    return (
      <Grid className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.toolbarTop}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <div>
              <IconButton
                color="inherit"
                size={'small'}
                onClick={this._handleNewsDrawerOpen}
              >
                <AnnouncementIcon />
                {announcements.filter(item => !item.seen).length > 0 && (
                  <NotificationsCircle />
                )}
              </IconButton>
              {this._notificationPermission(currentUser) && (
                <Notifications
                  list={notifications}
                  markAsSeen={this.props.notificationMarkAsSeen}
                  freelanceRoles={freelanceRoles}
                  asesorRoles={asesorRoles}
                  currentUser={currentUser}
                />
              )}
              <AvatarLayout
                currentUser={currentUser}
                logout={this.props.logout}
                location={this.props.location}
              />
            </div>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer}>
          {/* The implementation can be swap with js to avoid SEO duplication of links. */}
          <Hidden lgUp implementation="css">
            <Drawer
              container={this.props.container}
              variant="temporary"
              anchor={'left'}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
                paperAnchorDockedLeft: classes.paperAnchorDockedLeft
              }}
              ModalProps={{
                keepMounted: true // Better open performance on mobile.
              }}
            >
              <SidebarMenu
                currentUser={currentUser}
                menuFunctions={menuFunctions}
                classes={classes}
                location={this.props.location}
                history={this.props.history}
                toggleDrawer={this.handleDrawerToggle}
                companyCode={companyCode}
              />
            </Drawer>
          </Hidden>
          <Hidden mdDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
                paperAnchorDockedLeft: classes.paperAnchorDockedLeft
              }}
              variant="permanent"
              open
            >
              <SidebarMenu
                currentUser={currentUser}
                menuFunctions={menuFunctions}
                classes={classes}
                location={this.props.location}
                history={this.props.history}
                companyCode={companyCode}
              />
            </Drawer>
          </Hidden>
        </nav>
        <SliderModal
          open={
            this.props.location.pathname === '/' &&
            !requestAnnouncementsSend &&
            this.getNotSeenAnnouncement(announcements).length > 0
          }
          classes={classes}
          loading={requestAnnouncementsSend}
          disabled={requestAnnouncementsSend}
          list={this.getNotSeenAnnouncement(announcements)}
          customWidth={true}
          toggleAnnouncementAction={this.props.toggleAnnouncementAction}
          markAsSeen={this.props.announcementMarkAsSeen}
        />
        <NewsDrawer
          open={this.state.newsDrawerOpen}
          onClose={this._handleNewsDrawerClose}
          list={announcements}
          markAsSeen={this.props.announcementMarkAsSeen}
        />
        <main
          className={classes.content}
          ref={this.onRefMain}
          id="dashboard-main"
        >
          {currentUser.passwordUpdateInfo.passwordUpdateIsRequired && (
            <ChangePasswordModal
              user={currentUser}
              isCurrentUser={true}
              onClose={this._handleCloseChangePasswordModal}
              onSave={this._handleChangePassword}
              isSubmitting={requestChangePasswordSend}
              isLogOut={true}
            />
          )}
          {currentUser.passwordUpdateInfo.notify &&
            !currentUser.passwordUpdateInfo.passwordUpdateIsRequired &&
            (new Date(currentUser.lastPasswordChangeNotification).getDay() !=
              new Date().getDay() ||
              currentUser.lastPasswordChangeNotification === null) && (
              <CustomDialog
                onClose={this._updatePasswordNotificationDate}
                onConfirm={this._updatePasswordNotificationDate}
                open={prompt}
                title={'Contraseña próxima a vencer'}
                buttonText={'Continuar'}
              >
                <Typography variant="subheading">
                  {`Su contraseña vence en ${
                    currentUser.passwordUpdateInfo.dayLeft
                  } ${
                    currentUser.passwordUpdateInfo.dayLeft > 1 ? 'días' : 'día'
                  }`}
                </Typography>
              </CustomDialog>
            )}

          {currentUser.personalDataHandling === null &&
            this.state.isEditUserOpen &&
            orgUserDataAuthorization &&
            this.props.currentUser.canUpdateUserPersonalData && (
              <DataAuthorizationModal
                title={'Autorización y tratamiento de datos personales'}
                companyName={companyName}
                isDataAuthorizationAccepted={personalDataHandling}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                onClose={this._handleCloseEditUserModal}
                onConfirm={this._updatePersonalDataHandlingPermission}
              />
            )}

          <RoutesRenderer
            currentUser={currentUser}
            menuFunctions={menuFunctions}
            siteState={this.state.siteState}
            siteFlag={element}
          />
          {deployModal(
            this.props.isDeployModalOpen,
            this.props.actionData,
            this.props.currentUser,
            this.props.logout,
            this.props.closeDeployModal,
            this._handlerSite
          )}
        </main>
      </Grid>
    );
  }
}

export default withStyles(DashboardStyles, { withTheme: true })(DashboardPage);
