import React, { PureComponent, Fragment } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import _debounce from 'lodash/debounce';
import _pickBy from 'lodash/pickBy';
import _pick from 'lodash/pick';
import AutocompleteInput from '../../shared/Inputs/Autocomplete';
import TextFieldInput from '../../shared/Inputs/TextField';
import combineStyles from '../../../helpers/combineStyles';
import SpacingStyles from '../../../styles/helpers/spacing';
import { filterListFuzzyly } from '../../../helpers/utilityFunctions';
import { DEFAULT_DEBOUNCE_DELAY } from '../../../constants/request_constants';
import _get from 'lodash/get';
import CollapseWrapper from '../../shared/CollapseWrapper.js';

class CommissionFilters extends PureComponent {
  state = {
    geographicLocation: '',
    geographicLocationId: '',
    stratum: '',
    stratumLabel: '',
    quota: ''
  };

  componentDidMount() {
    this._handleChangeFilter();
  }

  _searchCallback = _debounce(
    (attribute, value) => {
      this.setState({ [attribute]: value, page: 0 }, this._handleChangeFilter);
    },
    DEFAULT_DEBOUNCE_DELAY,
    {
      leading: false,
      trailing: true
    }
  );

  _handleChange = ({ target: { name, value } }) => {
    this._searchCallback(name, value);
  };

  _handleChangeFilter = () => {
    const filterParams = this._getFilterParams();
    this.props.onChangeFilter(filterParams);
  };

  _handleSelectChange = ({ target: { name, value } }) =>
    this.setState({ [name]: value }, this._handleChangeFilter);

  _getFilterParams = () => {
    const filterParams = _pick(
      _pickBy(this.state, filter => !!filter),
      'geographicLocationId',
      'stratum',
      'quota'
    );
    return filterParams;
  };

  _getSuggestions = async (type, value) => {
    const items = this.props[type].map(item => ({
      label: item.name || item.description,
      value: item.id
    }));
    if (type == 'geographicLocations') {
      items.push({ label: 'TODAS', value: -5 });
    }
    const suggestions = filterListFuzzyly(value, items, 'label');
    return Promise.resolve(suggestions);
  };

  render() {
    const { classes, contractStrata } = this.props;
    return (
      <CollapseWrapper
        filter={true}
        body={
          <Fragment>
            <Grid container spacing={16}>
              <Grid item xs={12} sm={4}>
                <AutocompleteInput
                  id="geographicLocation"
                  name="geographicLocation"
                  label="Ubicación Geográfica"
                  margin="normal"
                  variant="outlined"
                  error={''}
                  helperText={''}
                  value={this.state.geographicLocation || ''}
                  onChange={change => {
                    if (this.state.geographicLocation && !change) {
                      this.setState(
                        { geographicLocation: '', geographicLocationId: '' },
                        this._handleChangeFilter
                      );
                    }
                    this.setState({ geographicLocation: change });
                  }}
                  getSuggestions={value =>
                    this._getSuggestions('geographicLocations', value)
                  }
                  onSuggestionSelected={gp => {
                    this.setState(
                      {
                        geographicLocation: gp.label,
                        geographicLocationId: gp.value
                      },
                      this._handleChangeFilter
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextFieldInput
                  id="quota"
                  name="quota"
                  value={this.state.quota || ''}
                  type={'number'}
                  onChange={this._handleChange}
                  label="Cupo de estrato"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <AutocompleteInput
                  id="stratum"
                  name="stratum"
                  label="Estrato"
                  margin="normal"
                  variant="outlined"
                  error={''}
                  helperText={''}
                  value={this.state.stratumLabel || ''}
                  onChange={change => {
                    if (this.state.stratumLabel && !change) {
                      this._handleSelectChange({
                        target: { name: 'stratumLabel', value: '' }
                      });
                      this._handleSelectChange({
                        target: { name: 'stratum', value: '' }
                      });
                    }
                    this.setState({ stratumLabel: change });
                  }}
                  suggestions={contractStrata}
                  getSuggestions={value =>
                    this._getSuggestions('contractStrata', value)
                  }
                  onSuggestionSelected={stratum => {
                    this._handleSelectChange({
                      target: {
                        name: 'stratum',
                        value: stratum.value
                      }
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Fragment>
        }
      />
    );
  }
}

const styles = theme => ({});

CommissionFilters.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(combineStyles(styles, SpacingStyles), { withTheme: true })
)(CommissionFilters);
