import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .nullable()
    .required('El nombre es requerido'),
  parent_name: Yup.string()
});

export default validationSchema;
