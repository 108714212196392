import {
  OPEN_REGISTER_SCHEDULE_MODAL,
  CLOSE_REGISTER_SCHEDULE_MODAL,
  OPEN_UPDATE_SCHEDULE_MODAL
} from '../constants/register_schedule_constants';
import { sendRequest, completeRequest, errorOnRequest } from './request_state';
import { newAlert } from './alert_actions';
import AxiosRequest from '../services/axios_request';
import registerScheduleSanitizer from '../helpers/sanitizers/registerScheduleSanitizer';
import RequestService from '../services/request_service';

const requestService = new RequestService('node');
const axiosRequest = new AxiosRequest();

export const openRegisterScheduleModal = () => {
  return dispatch => {
    dispatch({
      type: OPEN_REGISTER_SCHEDULE_MODAL
    });
  };
};

export const closeRegisterScheduleModal = () => {
  return dispatch => {
    dispatch(errorOnRequest('RegisterSchedule', null));
    dispatch({
      type: CLOSE_REGISTER_SCHEDULE_MODAL
    });
  };
};

export const updateErrorsOnRegisterSchedule = value => {
  return dispatch => {
    dispatch(errorOnRequest('RegisterSchedule', value))
  }
}

export const registerSchedule = (params = {}) => async dispatch => {
  dispatch(sendRequest('RegisterSchedule'));
  try {
    await requestService.post(
      'schedules',
      registerScheduleSanitizer(params),
      true
    );
    dispatch(errorOnRequest('RegisterSchedule', {}));
    dispatch(newAlert('success', 'success', 'Agendamiento registrado exitosamente'));
    dispatch(closeRegisterScheduleModal());
  } catch (errors) {
    const e = await errors.json();
    if (e.errors) {
      if ('base' in e.errors) {
        dispatch(newAlert('error', 'ERROR:', e.errors['base']));
      } else {
        dispatch(errorOnRequest('RegisterSchedule', e.errors));
      }
    } else {
      dispatch(serverStatusError(errors));
    }
  } finally {
    dispatch(completeRequest('RegisterSchedule'));
  }
};

export const updateSchedule = (body = {}) => async dispatch => {
  dispatch(sendRequest('UpdateSchedule'));
  try {
    await await requestService.put(
      `schedules/${body.id}`,
      body
    );
    dispatch(errorOnRequest('UpdateSchedule', {}));
    dispatch(newAlert('success', 'success', 'Agendamiento actualizado exitosamente'));
    dispatch(closeRegisterScheduleModal());
  } catch (errors) {
    const e = await errors.json();
    if (e.errors) {
      if ('base' in e.errors) {
        dispatch(newAlert('error', 'ERROR:', e.errors['base']));
      } else {
        dispatch(errorOnRequest('UpdateSchedule', e.errors));
      }
    } else {
      dispatch(serverStatusError(errors));
    }
  } finally {
    dispatch(completeRequest('UpdateSchedule'));
  }
};

export const openUpdateScheduleModal = (homevisit) => {
  return dispatch => {
    dispatch({
      type: OPEN_UPDATE_SCHEDULE_MODAL,
      data: homevisit
    });
  };
};
