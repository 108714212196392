import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { DEFAULT_DEBOUNCE_DELAY } from '../../../constants/request_constants';
import { monthOptions, getYears, saleType } from './dropDownOptions';

import parseQuerystring from '../../../helpers/parseQuerystring';
import OutlinedSelectChippedInput from '../../shared/Inputs/OutlinedSelectChipped';
import AutocompleteInput from '../../shared/Inputs/Autocomplete';
import { filterListFuzzyly } from '../../../helpers/utilityFunctions';
import combineStyles from '../../../helpers/combineStyles';
import SpacingStyles from '../../../styles/helpers/spacing';
import ChippedAutocomplete from '../../shared/Inputs/ChippedAutocomplete';

import _debounce from 'lodash/debounce';
import _pickBy from 'lodash/pickBy';
import _pick from 'lodash/pick';
import _replace from 'lodash/replace';

class SalesmanDataFilters extends PureComponent {
  state = {
    pointOfSale: '',
    pointOfSaleId: '',
    supplier: '',
    supplierId: '',
    year: '',
    month: '',
    saleType: 'total',
    zones: [],
    selectedZones: [],
    channel: '',
    channelId: ''
  };

  componentDidMount() {
    const today = new Date();
    const query = parseQuerystring(this.props);
    const suppliersList = this.filterListSupplier();
    const pointsOfSaleList = this.filterListPos();

    if (this.props.isAdmin) {
      this.setState(
        {
          ...this.state,
          year: today.getFullYear(),
          month: (today.getMonth() + 1).toString(),
          ...query
        },
        this._handleChangeFilter
      );
    } else {
      {
        (!pointsOfSaleList || !suppliersList) &&
          this.setState({
            ...this.state,
            year: today.getFullYear(),
            month: (today.getMonth() + 1).toString(),
            ...query
          });
      }
    }
    if (this.props.ranking) {
      {
        pointsOfSaleList &&
          !this.props.isFreelance &&
          this.setState(
            {
              ...this.state,
              year: this.props.filterSelection.year,
              month: this.props.filterSelection.month,
              pointOfSale: this.getName(
                pointsOfSaleList,
                this.props.filterSelection.pointOfSale
              ),
              pointOfSaleId: this.props.filterSelection.pointOfSale,
              saleType: this.props.filterSelection.saleType || 'total',
              ...query
            },
            this._handleChangeFilter
          );
      }
      {
        pointsOfSaleList &&
          this.props.isFreelance &&
          suppliersList &&
          this.setState(
            {
              ...this.state,
              year: this.props.filterSelection.year,
              month: this.props.filterSelection.month,
              supplier: this.getName(
                suppliersList,
                this.props.filterSelection.supplier
              ),
              supplierId: this.props.filterSelection.supplier,
              saleType: this.props.filterSelection.saleType || 'total',
              ...query
            },
            this._handleChangeFilter,
            this.props.supplierFilter(this.props.filterSelection.supplier)
          );
      }

      {
        pointsOfSaleList &&
          this.props.isFreelance &&
          suppliersList &&
          this.setState(
            {
              ...this.state,
              year: this.props.filterSelection.year,
              month: this.props.filterSelection.month,
              supplier: this.getName(
                suppliersList,
                this.props.filterSelection.supplier
              ),
              supplierId: this.props.filterSelection.supplier,
              saleType: this.props.filterSelection.saleType || 'total',
              ...query
            },
            this._handleChangeFilter,
            this.props.supplierFilter(this.props.filterSelection.supplier)
          );
      }

      {
        pointsOfSaleList &&
          suppliersList &&
          !this.props.isFreelance &&
          this.props.isSurfaceCoordinator &&
          this.setState(
            {
              ...this.state,
              year: this.props.filterSelection.year,
              month: this.props.filterSelection.month,
              supplier: this.getName(
                suppliersList,
                this.props.filterSelection.supplier
              ),
              supplierId: this.props.filterSelection.supplier,
              pointOfSale: this.getName(
                pointsOfSaleList,
                this.props.filterSelection.pointOfSale
              ),
              pointOfSaleId: this.props.filterSelection.pointOfSale,
              saleType: this.props.filterSelection.saleType || 'total',
              ...query
            },
            this._handleChangeFilter,
            this.props.supplierFilter(this.props.filterSelection.supplier)
          );
      }
    } else {
      if (!pointsOfSaleList[0] && !this.props.isAdmin) {
        this.props.newAlert(
          'error',
          null,
          'No tiene ningún punto de venta asociado'
        );
      }
      {
        pointsOfSaleList[0] &&
          !this.props.isFreelance &&
          !this.props.isSurfaceCoordinator &&
          this.setState(
            {
              ...this.state,
              year: today.getFullYear(),
              month: (today.getMonth() + 1).toString(),
              pointOfSale: pointsOfSaleList[0].name,
              pointOfSaleId: pointsOfSaleList[0].id,
              saleType: this.props.filterSelection.saleType || 'total',
              ...query
            },
            this._handleChangeFilter
          );
      }
      {
        pointsOfSaleList.length > 0 &&
          this.props.isFreelance &&
          suppliersList[0] &&
          this.setState(
            {
              ...this.state,
              year: today.getFullYear(),
              month: (today.getMonth() + 1).toString(),
              supplier: suppliersList[0].name,
              supplierId: suppliersList[0].id,
              saleType: this.props.filterSelection.saleType || 'total',
              ...query
            },
            this._handleChangeFilter,
            this.props.supplierFilter(suppliersList[0].id)
          );
      }
      {
        pointsOfSaleList[0] &&
          suppliersList[0] &&
          !this.props.isFreelance &&
          this.props.isSurfaceCoordinator &&
          this.setState(
            {
              ...this.state,
              year: today.getFullYear(),
              month: (today.getMonth() + 1).toString(),
              supplier: suppliersList[0].name,
              supplierId: suppliersList[0].id,
              pointOfSale: pointsOfSaleList[0].name,
              pointOfSaleId: pointsOfSaleList[0].id,
              saleType: this.props.filterSelection.saleType || 'total',
              ...query
            },
            this._handleChangeFilter,
            this.props.supplierFilter(suppliersList[0].id)
          );
      }
      {
        !pointsOfSaleList.length > 0 &&
          this.props.isFreelance &&
          suppliersList[0] &&
          this.setState(
            {
              ...this.state,
              year: today.getFullYear(),
              month: (today.getMonth() + 1).toString(),
              supplier: suppliersList[0].name,
              supplierId: suppliersList[0].id,
              saleType: this.props.filterSelection.saleType || 'total',
              ...query
            },
            this._handleChangeFilter,
            this.props.supplierFilter(suppliersList[0].id)
          );
      }
    }

    if (this.props.filterSelection) {
      const selectedYear = this.props.filterSelection.year;
      const selectedMonth = this.props.filterSelection.month;
      const supplierId =
        this.props.filterSelection.supplier ||
        (suppliersList[0] && suppliersList[0].id) ||
        '';
      const supplier =
        this.props.filterSelection.supplierName ||
        (suppliersList[0] && suppliersList[0].name) ||
        '';
      const pointOfSaleId =
        this.props.filterSelection.pointOfSale ||
        (pointsOfSaleList[0] && pointsOfSaleList[0].id) ||
        '';
      const pointOfSale =
        this.props.filterSelection.pointOfSaleName ||
        (pointsOfSaleList[0] && pointsOfSaleList[0].name) ||
        '';
      const saleType = this.props.filterSelection.saleType;
      const channel = this.props.filterSelection.channel;
      const channelId = this.props.filterSelection.channelId;
      const selectedZones = this.props.filterSelection.selectedZones;

      const selectedFilters = {
        supplier,
        supplierId,
        pointOfSale,
        pointOfSaleId
      };
      if (saleType) selectedFilters.saleType = saleType;
      if (channel) selectedFilters.channel = channel;
      if (channelId) selectedFilters.channelId = channelId;
      if (selectedZones) selectedFilters.selectedZones = selectedZones;

      this.setState(
        {
          ...selectedFilters,
          year: selectedYear > 0 ? selectedYear : today.getFullYear(),
          month:
            selectedMonth > 0
              ? selectedMonth
              : (today.getMonth() + 1).toString()
        },
        this._handleChangeFilter
      );
    }
  }

  _searchCallback = _debounce(
    (attribute, value) => {
      this.setState({ [attribute]: value, page: 0 }, this._handleChangeFilter);
    },
    DEFAULT_DEBOUNCE_DELAY,
    {
      leading: false,
      trailing: true
    }
  );

  _handleChange = ({ target: { name, value } }) => {
    this._searchCallback(name, value);
  };

  _handleMultiSelectChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value }, this._handleChangeFilter);
  };

  _handleSelectChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value }, this._handleChangeFilter);
    this.props.supplierFilter(value);
  };

  _handleSaleTypeSelectChange = ({ target: { value } }) => {
    this.setState({ saleType: value }, this._handleChangeFilter);
  };

  _handleChangeFilter = () => {
    this.props.onChangeFilter(this._getFilterParams());
    this.props.supplierFilter(this.state.supplierId);
  };

  _getFilterParams = () => {
    let saleType;
    if (this.state.saleType === 'tradicional') {
      saleType = 'tradicional';
    } else if (this.state.saleType === 'cardif') {
      saleType = 'cardif';
    } else {
      saleType = 'total';
    }

    const filterParams = _pick(
      _pickBy(
        {
          ...this.state,
          pointOfSale: this.state.pointOfSaleId,
          pointOfSaleName: this.state.pointOfSale,
          supplier: this.state.supplierId,
          supplierName: this.state.supplier,
          saleType: saleType,
          selectedZones: this.state.selectedZones
        },
        filter => {
          if (typeof filter === 'boolean') {
            return true;
          }

          return !!filter;
        }
      ),
      'supplier',
      'supplierName',
      'pointOfSale',
      'pointOfSaleName',
      'year',
      'month',
      'saleType',
      'channelId',
      'channel',
      'selectedZones'
    );

    return filterParams;
  };

  getName(array, id) {
    const names = array.map(s => {
      if (s.id == id) {
        return s.name;
      }
    });
    const name = names.filter(function(dato) {
      return dato != undefined;
    });
    return name[0];
  }

  _getSupplierSuggestions = async value => {
    const suppliers = this.filterListSupplier().map(supplier => ({
      label: supplier.name,
      value: supplier.id
    }));

    const suggestions = filterListFuzzyly(value, suppliers, 'label');
    return Promise.resolve(suggestions);
  };

  _getPosSuggestions = async value => {
    const pointsOfSale = this.filterListPos().map(pointOfSale => ({
      label: pointOfSale.name,
      value: pointOfSale.id
    }));
    const suggestions = filterListFuzzyly(value, pointsOfSale, 'label');
    return Promise.resolve(suggestions);
  };

  _getZoneSuggestions = async value => {
    const zones = this.props.zones.map(zone => ({
      label: zone.description,
      value: zone.id
    }));
    const suggestions = filterListFuzzyly(value, zones, 'label');
    return Promise.resolve(suggestions);
  };

  _getSaleChannelsSuggestions = async value => {
    const channels = this.props.saleChannels
      .filter(channel => channel.id !== -1 && channel.area_type !== 1)
      .map(channel => ({
        label: channel.description,
        value: channel.id
      }));

    const suggestions = filterListFuzzyly(value, channels, 'label');
    return Promise.resolve(suggestions);
  };

  getApprovedSuppliers = (listPos, user) => {
    const sup_approved = [];
    listPos.map(pv => {
      const m = pv.association;
      m.map(e => {
        if (e.status == 'approved' && e.userId == user) {
          const data = {
            id: pv.supplier.id,
            name: pv.supplier.name
          };
          sup_approved.push(data);
        }
      });
    });
    return sup_approved;
  };

  getApprovedPos = (listPos, user) => {
    const pos_approved = [];
    listPos.map(pv => {
      const m = pv.association;
      m.map(e => {
        if (e.status === 'approved' && e.userId == user) {
          const data = {
            id: pv.id,
            name: `${_replace(pv.name, 'FNB - ', '')}`,
            supplier: pv.supplier.id
          };
          pos_approved.push(data);
        }
      });
    });
    return pos_approved;
  };

  filterListSupplier = () => {
    let list,
      supUser = this.props.suppliers;
    if (this.props.pointsOfSale.length > 0) {
      list = this.props.pointsOfSale;
      supUser = this.getApprovedSuppliers(list, this.props.currentUser.id);
      supUser = supUser.filter(
        (ele, ind) =>
          ind ===
          supUser.findIndex(elem => elem.id === ele.id && elem.id === ele.id)
      );
    }
    return supUser;
  };

  filterListPos = () => {
    if (
      this.props.currentUser.canFilterDashboardByPos &&
      this.props.pointsOfSaleList &&
      this.props.pointsOfSaleList.length > 0
    )
      return this.props.pointsOfSaleList;

    let list,
      pointSales = this.props.pointsOfSale;
    const pointsOfSupplier = [];

    if (this.props.pointsOfSale.length > 0) {
      list = this.props.pointsOfSale;
      pointSales = this.getApprovedPos(list, this.props.currentUser.id);
      pointSales = pointSales.filter(
        (ele, ind) =>
          ind ===
          pointSales.findIndex(elem => elem.id === ele.id && elem.id === ele.id)
      );

      pointSales.map(pos => {
        if (pos.supplier === parseInt(this.state.supplierId)) {
          pointsOfSupplier.push(pos);
        }
      });

      if (pointsOfSupplier != '') {
        pointSales = pointsOfSupplier;
      }
    }
    return pointSales;
  };

  sizeAtView = () => {
    return this.props.ranking ? 3 : 2;
  };

  render() {
    const {
      classes,
      pointsOfSale,
      pointsOfSaleList,
      suppliers,
      saleChannels,
      ranking,
      currentUser,
      viewPos,
      isFreelance
    } = this.props;

    return (
      <Fragment>
        <Grid container spacing={16} justify="center">
          {currentUser.canFilterDashboardByZone && (
            <Grid item xs={12} sm={6}>
              <ChippedAutocomplete
                maxHeight={'406px'}
                label={'Zonas'}
                name={'selectedZones'}
                value={this.state.zones}
                getSuggestions={this._getZoneSuggestions}
                values={this.state.selectedZones}
                onChange={this._handleMultiSelectChange}
                margin={'normal'}
              />
            </Grid>
            // <Grid item xs={12} sm={6}>
            //   <Select
            //     multiple={multiple}
            //     value={inputValue}
            //     onChange={this._handleMultiSelectChange}
            //     native
            //     input={
            //       <OutlinedInput
            //         labelWidth={this.state.labelWidth}
            //         name={'selectedZones'}
            //         id="outlined-select"
            //       />
            //     }
            //     classes={{
            //       select: classnames(classes.select),
            //       icon: classes.icon
            //     }}
            //   >
            //     <option value="" />
            //     {this._getZoneSuggestions.map((option, index) => (
            //       <option key={index} value={option.value}>
            //         {option.label}
            //       </option>
            //     ))}
            //   </Select>
            // </Grid>
          )}
          {!isFreelance &&
            !ranking &&
            currentUser.canFilterDashboardByPos &&
            viewPos && (
              <Grid item xs={12} sm={3}>
                <AutocompleteInput
                  name="pointOfSale"
                  label="Punto de Venta"
                  margin="normal"
                  variant="outlined"
                  error={''}
                  helperText={''}
                  value={this.state.pointOfSale}
                  onChange={change => {
                    if (this.state.pointOfSale && !change) {
                      this._handleSelectChange({
                        target: { name: 'pointOfSale', value: '' }
                      });
                    }
                    this.setState({ pointOfSale: change });
                  }}
                  suggestions={pointsOfSale}
                  getSuggestions={this._getPosSuggestions}
                  onSuggestionSelected={pointOfSale => {
                    this.setState({
                      pointOfSaleId: pointOfSale.value.toString()
                    });
                    this._handleSelectChange({
                      target: {
                        name: 'pointOfSale',
                        value: pointOfSale.label
                      }
                    });
                  }}
                />
              </Grid>
            )}

          {currentUser.canFilterDashboardBySupplier && (
            <Grid item xs={12} sm={true ? 3 : 4}>
              <AutocompleteInput
                name="supplier"
                label="Aliado"
                margin="normal"
                variant="outlined"
                error={''}
                helperText={''}
                value={this.state.supplier || ''}
                onChange={change => {
                  if (this.state.supplier && !change) {
                    this.setState(
                      {
                        pointOfSale: '',
                        pointOfSaleId: '',
                        supplier: ''
                      },
                      this._handleSelectChange({
                        target: {
                          name: 'supplierId',
                          value: ''
                        }
                      })
                    );
                  }
                  this.setState({ supplier: change });
                }}
                suggestions={suppliers}
                getSuggestions={this._getSupplierSuggestions}
                onSuggestionSelected={supplier => {
                  this.setState({
                    supplierId: supplier.value.toString(),
                    pointOfSale: '',
                    pointOfSaleId: ''
                  });
                  this._handleSelectChange({
                    target: { name: 'supplier', value: supplier.label }
                  });
                  this.props.supplierFilter(supplier.value);
                }}
              />
            </Grid>
          )}

          {currentUser.canFilterDashboardByPos && !viewPos && (
            <Grid item xs={12} sm={true ? 3 : 4}>
              <AutocompleteInput
                name="pointOfSale"
                label="Punto de Venta"
                margin="normal"
                variant="outlined"
                disabled={
                  !this.state.supplierId && !this.state.selectedZones.length > 0
                }
                error={''}
                helperText={''}
                value={this.state.pointOfSale || ''}
                onChange={change => {
                  if (this.state.pointOfSale && !change) {
                    this._handleSelectChange({
                      target: {
                        name: 'pointOfSale',
                        value: ''
                      }
                    });
                    this._handleSelectChange({
                      target: {
                        name: 'pointOfSaleId',
                        value: ''
                      }
                    });
                  }
                  this.setState({ pointOfSale: change });
                }}
                suggestions={pointsOfSaleList}
                getSuggestions={this._getPosSuggestions}
                onSuggestionSelected={pointOfSale => {
                  this.setState({
                    pointOfSaleId: pointOfSale.value.toString()
                  });
                  this._handleSelectChange({
                    target: {
                      name: 'pointOfSale',
                      value: pointOfSale.label
                    }
                  });
                }}
              />
            </Grid>
          )}

          {currentUser.canFilterDashboardByChannel && (
            <Grid item xs={12} sm={4}>
              <AutocompleteInput
                name="channel"
                label="Canal"
                margin="normal"
                variant="outlined"
                error={''}
                helperText={''}
                value={this.state.channel || ''}
                onChange={change => {
                  if (this.state.channel && !change) {
                    this._handleSelectChange({
                      target: { name: 'channel', value: '' }
                    });
                    this._handleSelectChange({
                      target: { name: 'channelId', value: '' }
                    });
                  }
                  this.setState({ channel: change });
                }}
                suggestions={saleChannels}
                getSuggestions={this._getSaleChannelsSuggestions}
                onSuggestionSelected={channel => {
                  this.setState({ channelId: channel.value });
                  this._handleSelectChange({
                    target: { name: 'channel', value: channel.label }
                  });
                }}
              />
            </Grid>
          )}
          {currentUser.canFilterDashboardByMonth && (
            <Grid item xs={12} sm={2}>
              <OutlinedSelectChippedInput
                label={'Mes'}
                classes={{
                  root: classes.selectRoot,
                  filterColor: classes.filterColor
                }}
                name={'month'}
                options={monthOptions}
                onChange={this._handleSelectChange}
                inputLabel={'Month'}
                value={this.state.month}
                withChip={false}
              />
            </Grid>
          )}

          {currentUser.canFilterDashboardByYear && (
            <Grid item xs={12} sm={2}>
              <OutlinedSelectChippedInput
                label={'Año'}
                classes={{
                  root: classes.selectRoot,
                  filterColor: classes.filterColor
                }}
                name={'year'}
                options={getYears()}
                onChange={this._handleSelectChange}
                inputLabel={'Year'}
                value={this.state.year}
                withChip={false}
              />
            </Grid>
          )}

          {currentUser.canFilterDashboardByType && (
            <Grid item xs={12} sm={4}>
              <OutlinedSelectChippedInput
                label={'Tipo Venta'}
                classes={{
                  root: classes.selectRoot,
                  filterColor: classes.filterColor
                }}
                name={'saleType'}
                options={saleType}
                onChange={this._handleSaleTypeSelectChange}
                value={this.state.saleType}
                inputLabel={'SaleType'}
                withChip={false}
              />
            </Grid>
          )}
        </Grid>
      </Fragment>
    );
  }
}

const styles = theme => ({
  root: {
    marginBottom: theme.spacing.unit * 3
  },
  actionBar: {
    marginTop: theme.spacing.unit * 2.2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  select: {
    width: '162px',
    color: 'black'
  }
});

SalesmanDataFilters.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(combineStyles(styles, SpacingStyles), { withTheme: true })
)(SalesmanDataFilters);
