/* eslint-disable complexity */
import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import { Grid } from '@material-ui/core';
import GridItem from '../../../shared/GridItem';
import { Formik, Field } from 'formik';
import combineStyles from '../../../../helpers/combineStyles';
import LayoutStyles from '../../../../styles/layout';
import TypographyStyles from '../../../../styles/typography';
import ButtonStyles from '../../../../styles/button';
import SpacingStyles from '../../../../styles/helpers/spacing';
import { withStyles } from '@material-ui/core/styles';
import { styles } from '../ModalStyles';
import CustomDialog from '../../../shared/Modals/CustomDialog';
import AutocompleteInput from '../../../shared/Inputs/Autocomplete';
import { filterListFuzzyly } from '../../../../helpers/utilityFunctions';
import LoadingIndicator from '../../../shared/LoadingIndicator';
import { validationSchema } from '../QuotaConfigurationValidation';
import OutlinedTextField from '../../../shared/Fields/OutlinedTextField';
import _isEmpty from 'lodash/isEmpty';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import TextFieldInput from '../../../shared/Inputs/TextField';

class FormModal extends Component {
  state = {
    allowedTimeSinceGasInstallation: '',
    allowedTimeSinceLastDateBrillaProductWasInArrears: '',
    allowedTimeSinceLastDateGasProductWasInArrears: '',
    allowedTimeSinceLastDateOtherServicesWereInArrears: '',
    allowedTimeSinceLastPunishment: '',
    geographicLocation: '',
    geographicLocationId: '',
    maximumNetworkBalance: '',
    numberOfSuspensionsPeriodToEvaluate: '',
    requiredOwnershipPeriod: '',
    rolloverQuota: '',
    stratum: '',
    stratumLabel: '',
    stratumQuota: ''
  };
  _handleSave = params => {
    if (params.values.id) {
      this.props.updateConfig({
        fields: params.values,
        ...this.props.query
      });
    } else {
      this.props.createConfig({
        fields: params.values,
        ...this.props.query
      });
    }
  };

  _handleDelete = () => {
    this.props.deleteConfig({
      fields: {
        id: this.props.config.id
      },
      ...this.props.query
    });
  };

  _getSuggestions = async (type, value) => {
    const items = this.props[type].map(item => ({
      label: item.name || item.description,
      value: item.id
    }));

    const suggestions = filterListFuzzyly(value, items, 'label');

    return Promise.resolve(suggestions);
  };

  _handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.props.clearErrors();
  };

  _handleSelectChange = ({ target: { name, value } }) =>
    this.setState({ [name]: value }, this._handleChangeFilter);

  _modalProps = values => {
    return values.id && this.props.canDeleteAssignedQuotas
      ? {
          buttonSecondaryText: 'Eliminar',
          onConfirmSecondary: () => {
            this._handleDelete();
          }
        }
      : {};
  };

  render() {
    const {
      onClose,
      classes,
      submitLoading,
      rowsPerPage,
      currentPage,
      config,
      loadingData = true,
      modalSecondSubmitLoading,
      contractStrata
    } = this.props;
    return (
      <div>
        <Formik
          initialValues={_isEmpty(config) ? { ...this.state } : { ...config }}
          validationSchema={validationSchema}
          onSubmit={values =>
            this._handleSave({ values, rowsPerPage, currentPage })
          }
          onReset={(values, actions) => {
            actions.resetForm();
          }}
          validateOnChange={false}
          validateOnBlur={false}
          render={({
            values,
            handleSubmit,
            handleReset,
            handleChange,
            setFieldValue,
            handleBlur,
            errors,
            touched,
            dirty
          }) => {
            return (
              <CustomDialog
                open={true}
                loading={submitLoading}
                loadingSecondary={modalSecondSubmitLoading}
                disabled={submitLoading || !dirty}
                disableBackdropClick={false}
                headerSecondary
                onConfirm={handleSubmit}
                title={
                  values.id
                    ? 'Modificar configuración de cupos'
                    : 'Agregar configuración de cupos'
                }
                buttonText={'Guardar'}
                onClose={() => {
                  handleReset();
                  onClose();
                }}
                {...this._modalProps(values)}
                classes={{
                  root: classes.dialogRoot
                }}
              >
                <Fragment>
                  {loadingData && !values.id ? (
                    <LoadingIndicator />
                  ) : (
                    <Grid container spacing={16}>
                      {!values.id && (
                        <GridItem xs={12} sm={6} className={classes.text}>
                          <AutocompleteInput
                            id="stratum"
                            name="stratum"
                            label="Estrato"
                            margin="normal"
                            variant="outlined"
                            value={this.state.stratumLabel || ''}
                            onChange={change => {
                              if (this.state.stratumLabel && !change) {
                                this._handleSelectChange({
                                  target: { name: 'stratumLabel', value: '' }
                                });
                                this._handleSelectChange({
                                  target: { name: 'stratum', value: '' }
                                });
                              }
                              this.setState({ stratumLabel: change });
                              setFieldValue('stratum', '');
                            }}
                            suggestions={contractStrata}
                            getSuggestions={value =>
                              this._getSuggestions('contractStrata', value)
                            }
                            onSuggestionSelected={stratum => {
                              this._handleSelectChange({
                                target: {
                                  name: 'stratum',
                                  value: stratum.value
                                }
                              });
                              setFieldValue('stratum', stratum.value);
                            }}
                            error={touched.stratum && !!errors.stratum}
                            helperText={touched.stratum && errors.stratum}
                            classes={{
                              root: classes.autocomplete
                            }}
                          />
                        </GridItem>
                      )}
                      {!values.id && (
                        <GridItem
                          xs={12}
                          sm={6}
                          className={classes.autocompleteInputGrid}
                        >
                          <AutocompleteInput
                            id="geographicLocationId"
                            name="geographicLocationId"
                            label="Ubicación Geográfica"
                            margin="normal"
                            variant="outlined"
                            value={
                              this.state.geographicLocation ||
                              values.geographicLocation ||
                              ''
                            }
                            onChange={e => {
                              setFieldValue('geographicLocationId', '');
                              setFieldValue('geographicLocation', '');
                              this.setState({ geographicLocation: e });
                            }}
                            suggestions={this.props.geographicLocations}
                            getSuggestions={value =>
                              this._getSuggestions('geographicLocations', value)
                            }
                            onSuggestionSelected={item => {
                              setFieldValue('geographicLocation', item.label);
                              setFieldValue('geographicLocationId', item.value);
                            }}
                            error={
                              touched.geographicLocationId &&
                              !!errors.geographicLocationId
                            }
                            helperText={
                              touched.geographicLocationId &&
                              errors.geographicLocationId
                            }
                          />
                        </GridItem>
                      )}
                      <GridItem xs={12} sm={6} className={classes.text}>
                        <Field
                          name="stratumQuota"
                          onChange={handleChange}
                          render={({ field, form }) => {
                            return (
                              <TextFieldInput
                                value={values.stratumQuota}
                                label={'Cupo de estrato'}
                                fullWidth
                                field={field}
                                form={form}
                                startAdornment={<AttachMoneyIcon />}
                                classes={{
                                  root: classes.autocomplete
                                }}
                                mask={'currency'}
                              />
                            );
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} className={classes.text}>
                        <Field
                          name="rolloverQuota"
                          onChange={handleChange}
                          render={({ field, form }) => {
                            return (
                              <TextFieldInput
                                value={values.rolloverQuota}
                                label={'Cupo adicional rollover'}
                                fullWidth
                                field={field}
                                form={form}
                                startAdornment={<AttachMoneyIcon />}
                                classes={{
                                  root: classes.autocomplete
                                }}
                                mask={'currency'}
                              />
                            );
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} className={classes.text}>
                        <p
                          className={classnames(
                            classes.headline9,
                            classes.subTitle
                          )}
                        >
                          Parámetros de evaluación de políticas
                        </p>
                      </GridItem>
                      <GridItem xs={12} sm={6} className={classes.text}>
                        <OutlinedTextField
                          id="allowedTimeSinceGasInstallation"
                          name="allowedTimeSinceGasInstallation"
                          label="Meses desde la instalación de gas"
                          type="number"
                          margin="normal"
                          variant="outlined"
                          value={values.allowedTimeSinceGasInstallation}
                          onChange={e => {
                            if (
                              e.target.value == '' ||
                              (e.target.value > 0 && e.target.value <= 99)
                            ) {
                              handleChange(e);
                            }
                          }}
                          error={
                            touched.allowedTimeSinceGasInstallation &&
                            !!errors.allowedTimeSinceGasInstallation
                          }
                          helperText={
                            touched.allowedTimeSinceGasInstallation &&
                            errors.allowedTimeSinceGasInstallation
                          }
                          classes={{
                            root: classes.autocomplete
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} className={classes.text}>
                        <OutlinedTextField
                          id="numberOfSuspensionsPeriodToEvaluate"
                          name="numberOfSuspensionsPeriodToEvaluate"
                          label="Meses desde la última suspensión"
                          type="number"
                          margin="normal"
                          variant="outlined"
                          value={values.numberOfSuspensionsPeriodToEvaluate}
                          onChange={e => {
                            if (
                              e.target.value == '' ||
                              (e.target.value > 0 && e.target.value <= 99)
                            ) {
                              handleChange(e);
                            }
                          }}
                          error={
                            touched.numberOfSuspensionsPeriodToEvaluate &&
                            !!errors.numberOfSuspensionsPeriodToEvaluate
                          }
                          helperText={
                            touched.numberOfSuspensionsPeriodToEvaluate &&
                            errors.numberOfSuspensionsPeriodToEvaluate
                          }
                          classes={{
                            root: classes.autocomplete
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} className={classes.text}>
                        <OutlinedTextField
                          id="allowedTimeSinceLastPunishment"
                          name="allowedTimeSinceLastPunishment"
                          label="Meses desde el último castigo"
                          type="number"
                          margin="normal"
                          variant="outlined"
                          value={values.allowedTimeSinceLastPunishment}
                          onChange={e => {
                            if (
                              e.target.value == '' ||
                              (e.target.value > 0 && e.target.value <= 99)
                            ) {
                              handleChange(e);
                            }
                          }}
                          error={
                            touched.allowedTimeSinceLastPunishment &&
                            !!errors.allowedTimeSinceLastPunishment
                          }
                          helperText={
                            touched.allowedTimeSinceLastPunishment &&
                            errors.allowedTimeSinceLastPunishment
                          }
                          classes={{
                            root: classes.autocomplete
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} className={classes.text}>
                        <OutlinedTextField
                          id="allowedTimeSinceLastDateBrillaProductWasInArrears"
                          name="allowedTimeSinceLastDateBrillaProductWasInArrears"
                          label="Meses desde la última mora de brilla"
                          type="number"
                          margin="normal"
                          variant="outlined"
                          value={
                            values.allowedTimeSinceLastDateBrillaProductWasInArrears
                          }
                          onChange={e => {
                            if (
                              e.target.value == '' ||
                              (e.target.value > 0 && e.target.value <= 99)
                            ) {
                              handleChange(e);
                            }
                          }}
                          error={
                            touched.allowedTimeSinceLastDateBrillaProductWasInArrears &&
                            !!errors.allowedTimeSinceLastDateBrillaProductWasInArrears
                          }
                          helperText={
                            touched.allowedTimeSinceLastDateBrillaProductWasInArrears &&
                            errors.allowedTimeSinceLastDateBrillaProductWasInArrears
                          }
                          classes={{
                            root: classes.autocomplete
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} className={classes.text}>
                        <OutlinedTextField
                          id="allowedTimeSinceLastDateGasProductWasInArrears"
                          name="allowedTimeSinceLastDateGasProductWasInArrears"
                          label="Meses desde la última mora de Gas"
                          type="number"
                          margin="normal"
                          variant="outlined"
                          value={
                            values.allowedTimeSinceLastDateGasProductWasInArrears
                          }
                          onChange={e => {
                            if (
                              e.target.value == '' ||
                              (e.target.value > 0 && e.target.value <= 99)
                            ) {
                              handleChange(e);
                            }
                          }}
                          error={
                            touched.allowedTimeSinceLastDateGasProductWasInArrears &&
                            !!errors.allowedTimeSinceLastDateGasProductWasInArrears
                          }
                          helperText={
                            touched.allowedTimeSinceLastDateGasProductWasInArrears &&
                            errors.allowedTimeSinceLastDateGasProductWasInArrears
                          }
                          classes={{
                            root: classes.autocomplete
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} className={classes.text}>
                        <OutlinedTextField
                          id="allowedTimeSinceLastDateOtherServicesWereInArrears"
                          name="allowedTimeSinceLastDateOtherServicesWereInArrears"
                          label="Meses desde la última mora de otros servicios"
                          type="number"
                          margin="normal"
                          variant="outlined"
                          value={
                            values.allowedTimeSinceLastDateOtherServicesWereInArrears
                          }
                          onChange={e => {
                            if (
                              e.target.value == '' ||
                              (e.target.value > 0 && e.target.value <= 99)
                            ) {
                              handleChange(e);
                            }
                          }}
                          error={
                            touched.allowedTimeSinceLastDateOtherServicesWereInArrears &&
                            !!errors.allowedTimeSinceLastDateOtherServicesWereInArrears
                          }
                          helperText={
                            touched.allowedTimeSinceLastDateOtherServicesWereInArrears &&
                            errors.allowedTimeSinceLastDateOtherServicesWereInArrears
                          }
                          classes={{
                            root: classes.autocomplete
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} className={classes.text}>
                        <Field
                          name="maximumNetworkBalance"
                          onChange={handleChange}
                          render={({ field, form }) => {
                            return (
                              <TextFieldInput
                                value={values.maximumNetworkBalance}
                                label={'Máximo saldo de red'}
                                fullWidth
                                field={field}
                                form={form}
                                startAdornment={<AttachMoneyIcon />}
                                classes={{
                                  root: classes.autocomplete
                                }}
                                mask={'currency'}
                              />
                            );
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} className={classes.text}>
                        <OutlinedTextField
                          id="requiredOwnershipPeriod"
                          name="requiredOwnershipPeriod"
                          label="Meses siendo titular"
                          type="number"
                          margin="normal"
                          variant="outlined"
                          value={values.requiredOwnershipPeriod}
                          onChange={e => {
                            if (
                              e.target.value == '' ||
                              (e.target.value > 0 && e.target.value <= 99)
                            ) {
                              handleChange(e);
                            }
                          }}
                          error={
                            touched.requiredOwnershipPeriod &&
                            !!errors.requiredOwnershipPeriod
                          }
                          helperText={
                            touched.requiredOwnershipPeriod &&
                            errors.requiredOwnershipPeriod
                          }
                          classes={{
                            root: classes.autocomplete
                          }}
                        />
                      </GridItem>
                    </Grid>
                  )}
                </Fragment>
              </CustomDialog>
            );
          }}
        />
      </div>
    );
  }
}

export default withStyles(
  combineStyles(
    styles,
    LayoutStyles,
    TypographyStyles,
    ButtonStyles,
    SpacingStyles
  )
)(FormModal);
