import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import PortalCosignerDataPage from './PortalCosignerDataPage';
import { newAlert } from '../../../actions/alert_actions';
import { setCurrentStep, autoSkipStep } from '../../../actions/stepper_actions';
import {
  checkThirdStep,
  setNextStep,
  resetCosignerData,
  checkSecureSaleExperian
} from '../../../actions/portal_financing_actions';

function mapStateToProps(state) {
  const pf = state.portalFinancing;

  return {
    process: state.stepper.process,
    promissory: pf.promissory,
    loading: pf.stepIsLoading,
    nextStep: state.stepper.nextStep,
    portalNext: state.stepper.portalNext,
    promissoryNext: state.stepper.promissoryNext,
    serverErrors: pf.errors,
    requiresCosigner: pf.require_cosigner,
    holder: pf.holder,
    supplier: state.authentication.currentUser.supplier,
    routes: state.stepper.routes,
    solidaryDebtorCauses: state.globalSetting.orgData.solidary_debtor_causes,
    secureSaleActive: state.globalSetting.orgData.secure_sale_active,
    debtorMaxAge: state.globalSetting.orgData.debtor_max_age,
    isSecureSaleWithExperianActive: state.globalSetting.orgData.is_secure_sale_with_experian_active,
    canCheckSecureSaleScoreExperian: state.authentication.currentUser.canCheckSecureSaleScoreExperian,
    canSellSecureSaleByZone: pf.can_sell_secure_sale_by_zone,
    debtor: {
      debtor_contract_number: pf.debtor_contract_number,
      debtor_identification_type: pf.debtor_identification_type,
      debtor_identification: pf.debtor_identification,
      debtor_birthdate: pf.debtor_birthdate
    },
    cosigner: {
      contract: pf.cosigner_contract_number,
      identificationType: pf.cosigner_identification_type,
      identification: pf.cosigner_identification,
      birthdate: pf.cosigner_birthdate,
      causal: pf.reason_solidary_debtor
    },
    transfer: {
      requestMade: pf.moveQuotaData.requestMade,
      contract: pf.moveQuotaData.contract,
      identificationType: pf.moveQuotaData.identificationType,
      identification: pf.moveQuotaData.identification,
      birthdate: pf.moveQuotaData.birthdate
    },
    gnpGasApplianceSaleEvaluation: pf.gnp_gas_appliance_evaluation
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newAlert: bindActionCreators(newAlert, dispatch),
    setCurrentStep: bindActionCreators(setCurrentStep, dispatch),
    checkThirdStep: bindActionCreators(checkThirdStep, dispatch),
    setNextStep: bindActionCreators(setNextStep, dispatch),
    resetCosignerData: bindActionCreators(resetCosignerData, dispatch),
    checkSecureSaleExperian: bindActionCreators(checkSecureSaleExperian, dispatch),
    autoSkipStep: bindActionCreators(autoSkipStep, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PortalCosignerDataPage);
