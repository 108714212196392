import _get from 'lodash/get';
import classnames from 'classnames';
import { Formik, Field } from 'formik';
import Grid from '@material-ui/core/Grid';
import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import * as Yup from 'yup';
//Shared
import Button from '@material-ui/core/Button';
import GridItem from '../../../shared/GridItem';
import Modal from '../../../shared/Modals/Modal';
import OurDatePicker from '../../../shared/CustomDatePicker';
import TextFieldInput from '../../../shared/Inputs/TextField';
import ProgressButton from '../../../shared/Buttons/ProgressButton';
import AutocompleteInput from '../../../shared/Inputs/Autocomplete';
import ChippedAutocompleteInput from '../../../shared/Inputs/ChippedAutocomplete';
import OutlinedSelectChippedInput from '../../../shared/Inputs/OutlinedSelectChipped';
import SimpleSelect from '../../../shared/Selects/SimpleSelect';
import OutlinedTextField from '../../../shared/Fields/OutlinedTextField';

//helpers
import combineStyles from '../../../../helpers/combineStyles';
// eslint-disable-next-line no-unused-vars
import { removeAccents } from '../../../../helpers/utilityFunctions';
import { filterListFuzzyly } from '../../../../helpers/utilityFunctions';
import mapListToOptions from '../../../../helpers/mapListToOptions';
import { getStreetTypeName } from '../../../../helpers/utilityFunctions';
import {
  buildAddress,
  allAddressFieldsFilled,
  NotOneAddressFieldFilled
} from '../../../../helpers/addressFunctions';

//styles
import ButtonStyles from '../../../../styles/button';
import LayoutStyles from '../../../../styles/layout';
import TypographyStyles from '../../../../styles/typography';
import SpacingStyles from '../../../../styles/helpers/spacing';

import {
  friendly_gender,
  streetType,
  addressComponent
} from '../../../../constants/enums';
const styles = theme => ({
  titleWrapper: {
    display: 'flex',
    marginBottom: `${theme.spacing.unit * 2.125}px !important`,
    position: 'relative'
  },
  title: {
    flex: 1,
    fontWeight: 'bold !important'
  },
  resetLinkButton: {
    position: 'absolute',
    right: 0,
    top: -6
  },
  resetLink: {
    textTransform: 'capitalize',
    color: `${theme.palette.primary.main} !important`
  },
  modalRoot: {
    maxHeight: '100vh'
  },
  modalBody: {
    maxHeight: '85vh',
    overflow: 'auto'
  },
  modalFooter: {
    marginTop: `${theme.spacing.unit * 5}px !important`
  },
  gridItem: {
    paddingTop: '2px !important'
  },
  line: {
    marginTop: '0px',
    marginBottom: '25px',
    borderTop: '1px solid #D2D2D3',
    width: '80%'
  },
  addressDash: {
    textAlign: 'center',
    padding: '15px 0'
  }
});

// Character limits
const maxCityCharacters = 50;
const maxNumberCharacters = 10;
const maxComplementCharacters = 50;

// Regex
const nameRegex = /^ *([A-Za-zÀ-ÖØ-öø-ÿ]+ *\d* *)*[A-Za-zÀ-ÖØ-öø-ÿ]+ *\d* *$/;
const numberRegex = /^ *\d+ *\w* *$/;

const validationSchema = Yup.object().shape({
  sports: Yup.array().nullable(),
  hobbies: Yup.array().nullable(),
  likes: Yup.array().nullable(),
  gender: Yup.string().nullable(),
  maritalStatus: Yup.number().nullable(),
  numOfChildren: Yup.string().nullable(),
  dateOfBirth: Yup.string()
    .nullable()
    .required('El campo es requerido'),
  city: Yup.string()
    .max(
      maxCityCharacters,
      `La ciudad debe tener un máximo de ${maxCityCharacters} caracteres`
    )
    .matches(nameRegex, {
      message: 'La ciudad debe contener sólo letras',
      excludeEmptyString: true
    }),
  department: Yup.string()
    .max(
      maxCityCharacters,
      `La ciudad debe tener un máximo de ${maxCityCharacters} caracteres`
    )
    .matches(nameRegex, {
      message: 'El departamento debe contener sólo letras',
      excludeEmptyString: true
    }),
  initialStreetNumber: Yup.string()
    .max(
      maxNumberCharacters,
      `El número debe tener un máximo de ${maxNumberCharacters} caracteres`
    )
    .matches(numberRegex, {
      message: 'El número de la calle debe ser alfanumérico',
      excludeEmptyString: true
    }),
  intersectingStreetNumber: Yup.string()
    .max(
      maxNumberCharacters,
      `El número debe tener un máximo de ${maxNumberCharacters} caracteres`
    )
    .matches(numberRegex, {
      message: 'El número de la calle debe ser alfanumérico',
      excludeEmptyString: true
    }),
  houseNumber: Yup.string()
    .max(
      maxNumberCharacters,
      `El número debe tener un máximo de ${maxNumberCharacters} caracteres`
    )
    .matches(numberRegex, {
      message: 'El número de la casa debe ser alfanumérico',
      excludeEmptyString: true
    }),
  complement: Yup.string()
    .max(
      maxComplementCharacters,
      `La información adicional debe tener un máximo de ${maxComplementCharacters} caracteres`
    )
    .matches(nameRegex, {
      message: 'La información adicional debe ser alfanumérica',
      excludeEmptyString: true
    })
});

export class EditAdditionalInfo extends Component {
  state = {
    sports: [],
    hobbies: [],
    likes: []
  };
  constructor(props) {
    super(props);
  }
  _getElements = name => {
    const { activities = [] } = this.props.additionalInfo;
    return activities
      .filter(el => el.activityType == name)
      .map(el => {
        return {
          label: el.name,
          value: el.id
        };
      });
  };

  _handleOnClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  _handleOnSave = data => {
    const { onSave } = this.props;

    if (allAddressFieldsFilled(data) || NotOneAddressFieldFilled(data)) {
      const userAdditionalInfo = {
        user: {
          gender: data.gender,
          date_of_birth: data.dateOfBirth,
          marital_status: data.maritalStatus,
          num_of_children: data.numOfChildren,
          address: buildAddress(data)
        },
        sports: data.sports.map(el => el.label) || [],
        hobbies: data.hobbies.map(el => el.label) || [],
        likes: data.likes.map(el => el.label) || []
      };
      onSave(userAdditionalInfo);
      this._handleOnClose();
    } else {
      this.props.newAlert(
        'error',
        'ERROR:',
        'Los campos de la dirección deben estar todos vacíos o todos llenos.'
      );
    }
  };

  _getSuggestions = async (type, value) => {
    // Sugerencias habilitar de ser necesario
    // const toSuggest = this.props[type] || [];
    // const items = toSuggest.map(item => ({
    //   label: item.name || item.description,
    //   value: item.id
    // }));
    // items.push({ label: value.toLowerCase(), value: value.toLowerCase() });
    const items = [{ label: value.toLowerCase(), value: value.toLowerCase() }];
    const suggestions = filterListFuzzyly(value, items, 'label');
    return Promise.resolve(suggestions);
  };

  render() {
    const { classes, open = true, isSubmitting, additionalInfo, maritalStatus } = this.props;

    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          gender: additionalInfo.gender
            ? friendly_gender.filter(el => el.name == additionalInfo.gender)[0]
                .id
            : '',
          dateOfBirth: additionalInfo.dateOfBirth,
          maritalStatus: additionalInfo.maritalStatus
            ? maritalStatus.filter(
                el => el.name == additionalInfo.maritalStatus
              )[0].id
            : '',
          numOfChildren:
            additionalInfo.numOfChildren != null
              ? additionalInfo.numOfChildren
              : '',
          city: additionalInfo.address[addressComponent.city.name],
          department: additionalInfo.address[addressComponent.department.name],
          complement: additionalInfo.address[addressComponent.complement.name],
          initialStreet:
            additionalInfo.address[
              addressComponent.streetAddress.info.initialStreet.name
            ],
          initialStreetNumber:
            additionalInfo.address[
              addressComponent.streetAddress.info.initialStreetNumber.name
            ],
          intersectingStreet:
            additionalInfo.address[
              addressComponent.streetAddress.info.intersectingStreet.name
            ],
          intersectingStreetNumber:
            additionalInfo.address[
              addressComponent.streetAddress.info.intersectingStreetNumber.name
            ],
          houseNumber:
            additionalInfo.address[
              addressComponent.streetAddress.info.houseNumber.name
            ],
          sports: this._getElements('sport') || [],
          hobbies: this._getElements('hobby') || [],
          likes: this._getElements('liking') || []
        }}
        onSubmit={async values => {
          this._handleOnSave(values);
        }}
        validationSchema={validationSchema}
        onReset={(values, actions) => {
          actions.resetForm();
          this._handleOnClose();
        }}
        validateOnChange={false}
        validateOnBlur={false}
        render={({
          values,
          handleChange,
          handleSubmit,
          handleReset,
          dirty,
          handleBlur,
          touched,
          setFieldValue,
          errors
        }) => (
          <Modal
            open={open}
            onClose={this._handleOnClose}
            classes={{
              root: classes.modalRoot,
              body: classes.modalBody,
              footer: classes.modalFooter
            }}
            body={
              <Fragment>
                <div className={classes.titleWrapper}>
                  <p className={classnames(classes.headline6, classes.title)}>
                    {'¡Queremos conocerte!'}
                  </p>
                </div>
                <Grid container spacing={16}>
                  <GridItem xs={6}>
                    <Field
                      name="gender"
                      render={({ field, form }) => (
                        <SimpleSelect
                          name="gender"
                          label="Género"
                          value={values.gender}
                          onChange={handleChange}
                          options={mapListToOptions(friendly_gender, {
                            withId: false
                          })}
                          allowMargin={false}
                          error={touched.gender && !!errors.gender}
                          helperText={touched.gender && errors.gender}
                          field={field}
                          form={form}
                        />
                      )}
                    />
                  </GridItem>
                  <GridItem xs={6} className={classes.gridItem}>
                    <OurDatePicker
                      name={'dateOfBirth'}
                      invalidLabel="Fecha inválida"
                      maxDateMessage="Fecha inválida"
                      minDateMessage="Fecha inválida"
                      invalidDateMessage={'Fecha inválida'}
                      label="Fecha de Nacimiento"
                      value={values.dateOfBirth}
                      onChange={e => setFieldValue('dateOfBirth', e)}
                      onError={() => setFieldValue('dateOfBirth', null)}
                      error={touched.dateOfBirth && !!errors.dateOfBirth}
                      helperText={touched.dateOfBirth && errors.dateOfBirth}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    <Field
                      name="maritalStatus"
                      render={({ field, form }) => (
                        <SimpleSelect
                          name="maritalStatus"
                          label="Estado civil"
                          value={values.maritalStatus}
                          onChange={handleChange}
                          options={mapListToOptions(maritalStatus, {
                            withId: false
                          })}
                          allowMargin={false}
                          error={
                            touched.maritalStatus && !!errors.maritalStatus
                          }
                          helperText={
                            touched.maritalStatus && errors.maritalStatus
                          }
                          field={field}
                          form={form}
                          inputProps={{
                            MenuProps: {
                              disableAutoFocusItem: true
                            }
                          }}
                        />
                      )}
                    />
                  </GridItem>
                  <GridItem xs={6} className={classes.gridItem}>
                    <OutlinedTextField
                      name="numOfChildren"
                      onChange={handleChange}
                      value={values.numOfChildren + ''}
                      label={'¿Cuántos hijos tienes?'}
                      type={'number'}
                      fullWidth
                      min={0}
                      error={touched.numOfChildren && !!errors.numOfChildren}
                      helperText={touched.numOfChildren && errors.numOfChildren}
                    />
                  </GridItem>

                  <hr className={classes.line} />
                </Grid>
                <div className={classes.titleWrapper}>
                  <p className={classnames(classes.headline6, classes.title)}>
                    {'¿Dónde vives?'}
                  </p>
                </div>

                <Grid container spacing={16}>
                  <GridItem xs={6} className={classes.gridItem}>
                    <OutlinedTextField
                      name="city"
                      onChange={handleChange}
                      value={values.city}
                      label={'Ciudad'}
                      type={'text'}
                      fullWidth
                      error={touched.city && !!errors.city}
                      helperText={touched.city && errors.city}
                    />
                  </GridItem>
                  <GridItem xs={6} className={classes.gridItem}>
                    <OutlinedTextField
                      name="department"
                      onChange={handleChange}
                      value={values.department}
                      label={'Departamento'}
                      type={'text'}
                      fullWidth
                      error={touched.department && !!errors.department}
                      helperText={touched.department && errors.department}
                    />
                  </GridItem>
                  <GridItem xs={4} sm={3}>
                    <Field
                      name="initalStreet"
                      render={({ field, form }) => (
                        <SimpleSelect
                          name="initialStreet"
                          label="Vía Pral."
                          value={values.initialStreet}
                          onChange={handleChange}
                          options={mapListToOptions(streetType, {
                            withId: false
                          })}
                          allowMargin={false}
                          error={
                            touched.initialStreet && !!errors.initialStreet
                          }
                          helperText={
                            touched.initialStreet && errors.initialStreet
                          }
                          field={field}
                          form={form}
                        />
                      )}
                    />
                  </GridItem>
                  <GridItem xs={4} sm={2} className={classes.gridItem}>
                    <OutlinedTextField
                      name="initialStreetNumber"
                      onChange={handleChange}
                      value={values.initialStreetNumber}
                      label={'No.'}
                      type={'text'}
                      fullWidth
                      error={
                        touched.initialStreetNumber &&
                        !!errors.initialStreetNumber
                      }
                      helperText={
                        touched.initialStreetNumber &&
                        errors.initialStreetNumber
                      }
                    />
                  </GridItem>
                  <GridItem xs={4} sm={2}>
                    <Field
                      name="intersectingStreet"
                      render={({ field, form }) => (
                        <SimpleSelect
                          name="intersectingStreet"
                          label="Vía Sec."
                          value={values.intersectingStreet}
                          onChange={handleChange}
                          options={mapListToOptions(streetType, {
                            withId: false
                          })}
                          allowMargin={false}
                          error={
                            touched.intersectingStreet &&
                            !!errors.intersectingStreet
                          }
                          helperText={
                            touched.intersectingStreet &&
                            errors.intersectingStreet
                          }
                          field={field}
                          form={form}
                        />
                      )}
                    />
                  </GridItem>
                  <GridItem xs={4} sm={2} className={classes.gridItem}>
                    <OutlinedTextField
                      name="intersectingStreetNumber"
                      onChange={handleChange}
                      value={values.intersectingStreetNumber}
                      label={'No.'}
                      type={'text'}
                      fullWidth
                      error={
                        touched.intersectingStreetNumber &&
                        !!errors.intersectingStreetNumber
                      }
                      helperText={
                        touched.intersectingStreetNumber &&
                        errors.intersectingStreetNumber
                      }
                    />
                  </GridItem>
                  <GridItem xs={1} className={classes.gridItem}>
                    <p className={classes.addressDash}>{'—'}</p>
                  </GridItem>
                  <GridItem xs={4} sm={2} className={classes.gridItem}>
                    <OutlinedTextField
                      name="houseNumber"
                      onChange={handleChange}
                      value={values.houseNumber}
                      label={'No. Casa'}
                      type={'text'}
                      error={touched.houseNumber && !!errors.houseNumber}
                      helperText={touched.houseNumber && errors.houseNumber}
                    />
                  </GridItem>
                  <GridItem xs={6} className={classes.gridItem}>
                    <OutlinedTextField
                      name="complement"
                      onChange={handleChange}
                      value={values.complement}
                      label={'Información Adicional'}
                      type={'text'}
                      placeholder={'Torre 1 apto 101'}
                      error={touched.complement && !!errors.complement}
                      helperText={touched.complement && errors.complement}
                    />
                  </GridItem>

                  <hr className={classes.line} />
                </Grid>

                <Grid container spacing={16}>
                  <GridItem xs={6}>
                    <Field
                      name="sports"
                      render={({ field, form }) => (
                        <ChippedAutocompleteInput
                          getSuggestions={value => {
                            return this._getSuggestions('sports', value);
                          }}
                          value={this.state.sports}
                          values={values.sports}
                          onChange={evt => {
                            const {
                              target: { value }
                            } = evt;
                            this.setState({ sports: value });
                            handleChange(evt);
                          }}
                          field={field}
                          form={form}
                          margin="normal"
                          error={touched.sports && !!errors.sports}
                          helperText={touched.sports && errors.sports}
                          label={'¿Qué deportes practicas?'}
                        />
                      )}
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    <Field
                      name="hobbies"
                      render={({ field, form }) => (
                        <ChippedAutocompleteInput
                          getSuggestions={value => {
                            return this._getSuggestions('hobbies', value);
                          }}
                          value={this.state.hobbies}
                          values={values.hobbies}
                          onChange={evt => {
                            const {
                              target: { value }
                            } = evt;
                            this.setState({ hobbies: value });
                            handleChange(evt);
                          }}
                          field={field}
                          form={form}
                          margin="normal"
                          error={touched.hobbies && !!errors.hobbies}
                          helperText={touched.hobbies && errors.hobbies}
                          label={'¿Cuáles son tus hobbies?'}
                        />
                      )}
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    <Field
                      name="likes"
                      render={({ field, form }) => (
                        <ChippedAutocompleteInput
                          getSuggestions={value => {
                            return this._getSuggestions('likes', value);
                          }}
                          value={this.state.likes}
                          values={values.likes}
                          onChange={evt => {
                            const {
                              target: { value }
                            } = evt;
                            this.setState({ likes: value });
                            handleChange(evt);
                          }}
                          field={field}
                          form={form}
                          margin="normal"
                          error={touched.likes && !!errors.likes}
                          helperText={touched.likes && errors.likes}
                          label={'¿Qué te gusta?'}
                        />
                      )}
                    />
                  </GridItem>
                </Grid>
              </Fragment>
            }
            footer={
              <Fragment>
                <div className={classes.spacer} />
                <Button
                  className={classes.button}
                  onClick={handleReset}
                  color={'secondary'}
                  classes={{ label: classes.buttonLabel }}
                >
                  {'Cancelar'}
                </Button>
                <ProgressButton
                  onClick={handleSubmit}
                  className={classes.button}
                  classes={{
                    disabled: classes.buttonDisabled,
                    label: classes.buttonLabel
                  }}
                  color={'secondary'}
                  disabled={!dirty || isSubmitting}
                  isSubmitting={isSubmitting}
                >
                  {'Guardar'}
                </ProgressButton>
              </Fragment>
            }
          />
        )}
      />
    );
  }
}
export default withStyles(
  combineStyles(
    styles,
    LayoutStyles,
    TypographyStyles,
    ButtonStyles,
    SpacingStyles
  )
)(EditAdditionalInfo);
