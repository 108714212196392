import * as Yup from 'yup';

export function validationSchema(state, osfMaxIdLength) {
  const validationSchema = Yup.object().shape({
    typeOfIdentification: Yup.string().required(
      'Tipo de identificación es requerido'
    ),
    identification: Yup.string()
      .max(
        osfMaxIdLength,
        `La identificación debe tener un máximo de hasta ${osfMaxIdLength} caracteres`
      )
      .required('Identificación es requerida'),
    firstName: Yup.string().required('Nombre es requerido'),
    lastName: Yup.string().required('Apellido es requerido'),
    email: Yup.string()
      .required('Email es requerido')
      .email('El email debe ser válido'),
    roleIds: Yup.string(),
    supplierId: Yup.string()
      .when('roleIds', roleIds => {
        if (state.asesorID.includes(roleIds)) {
          return Yup.string().required(
            'Un asesor FNB debe tener proveedor específico'
          );
        } else if (
          state.coordinadorAliadoID.includes(roleIds) &&
          !state.hugeSurfaceCoordinatorId.includes(roleIds)
        ) {
          return Yup.string().required(
            'Un coordinador aliado debe tener proveedor específico'
          );
        } else if (state.freelanceID.includes(roleIds)) {
          return Yup.string().max(
            1,
            'Un asesor freelance no puede tener proveedor específico'
          );
        } else {
          return Yup.string().nullable();
        }
      })
      .when('freelance', freelance => {
        if (
          state.isHugeSurfaceCoordinator &&
          (!state.isAdmin && !state.canCreateNonAdminUsers) &&
          !freelance
        ) {
          return Yup.string().required(
            'Un asesor FNB debe tener proveedor específico'
          );
        }
      })
  });

  return validationSchema;
}
