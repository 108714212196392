import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';

import LoadingIndicator from '../../shared/LoadingIndicator';
import FormItemContainer from '../../shared/Containers/FormItemContainer';
import { _parsePOSname } from '../../../helpers/utilityFunctions';

import combineStyles from '../../../helpers/combineStyles';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';

const styles = theme => ({
  tag: {
    margin: 0,
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    display: 'inline-block',
    padding: [[theme.spacing.unit * 0.5, theme.spacing.unit * 1]],
    borderRadius: '18px',
    backgroundColor: 'rgba(254,176,11,0.12)',
    color: '#FEB00B',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    '&:last-child': {
      marginRight: 0
    }
  }
});
export class POintOfSales extends PureComponent {
  _verification = (id_point_of_sale, userId, associations) => {
    let style = {color :'rgba(12,31,44,0.38)', backgroundColor: '#EBEEF1'}
    associations.map(association => {
      if (association.userId == userId && association.pointOfSaleId == id_point_of_sale) {
        if (association.status == "approved") {
          style = {color :'#FEB00B', backgroundColor: 'rgba(254,176,11,0.12)'}
        }
      }
    })
    return style
  };
  render() {
    const {
      classes,
      isLoading,
      pointsOfSales = [],
      userId,
      onEditPointOfSales,
      canUpdatePOS
    } = this.props;
    return (
      <FormItemContainer
        title={'Puntos de Venta'}
        secondaryAction={
          !isLoading && canUpdatePOS ? (
            <Button
              size={'small'}
              color={'secondary'}
              className={classnames(classes.smallButton, classes.actionButtons)}
              classes={{
                label: classes.buttonLabel
              }}
              onClick={onEditPointOfSales}
            >
              {'Editar'}
            </Button>
          ) : null
        }
      >
        {isLoading ? (
          <LoadingIndicator />
        ) : (
            <div>
              {!pointsOfSales.length &&
                'No hay puntos de ventas asociados al usuario'}
              {pointsOfSales.map(pointOfSale => {
                const color = this._verification(pointOfSale.id, userId, pointOfSale.association)
                return (<span className={classes.tag} key={pointOfSale.id} style={{ color: color.color, backgroundColor: color.backgroundColor }}>
                  {_parsePOSname(pointOfSale.name).concat(pointOfSale.active ? `` : ` - INACTIVO`)}
                </span>)
              })}
            </div>
          )}
      </FormItemContainer>
    );
  }
}

export default withStyles(
  combineStyles(ButtonStyles, TypographyStyles, styles)
)(POintOfSales);
