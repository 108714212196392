import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import DigitalSaleOTPSale from './DigitalSaleOTPSale';
import { newAlert } from '../../../actions/alert_actions';
import { setCurrentStep } from '../../../actions/stepper_actions';
import {
  setNextStep,
  checkDigitalSaleSignedUnmaterializedVoucher,
  resendOlimpiaOTP,
  searchCacheSaleResponse,
  initiateOlimpiaV2Process,
  sendOtpV2,
  reSendOtpV2,
  validateOtpV2,
  resetProp,
  validateSignedDocumentV2,
  fetchExtraQuotas,
  previewDocumentV2
} from '../../../actions/portal_financing_actions';
import moment from 'moment-timezone';
import _get from 'lodash/get';
import { doctypes } from '../../../constants/enums';
import { removeFirstZeros } from '../../../helpers/utilityFunctions';
import { createRemoteTeleshopping, reSendOtpV2Teleshopping, sendOtpV2Teleshopping, validateOtpV2Teleshopping, validateSignedDocumentV2Teleshopping } from '../../../actions/teleshopping_actions';

function mapStateToProps(state) {
  const pf = state.portalFinancing;
  const ts = state.teleshopping;
  const pp = state.portalFinancing.promissory;
  const sc = pf.supplier || {};
  const isRemoteTeleshopping = state.stepper.process === 'remoteTeleshopping';
  const debtor_details = pf.promissory
    ? _get(pf, 'promissory.debtor_details')
    : _get(pf, 'tempPromissory.debtor');
  const debtor_details_ = pf.promissory
    ? _get(pf, 'promissory.debtor')
    : _get(pf, 'tempPromissory.debtor');
  const cosigner_details = pf.promissory
    ? _get(pf, 'promissory.cosigner_details')
    : _get(pf, 'tempPromissory.cosigner');
  const cosigner_details_ = pf.promissory
    ? _get(pf, 'promissory.cosigner')
    : _get(pf, 'tempPromissory.cosigner');
  const debtor = debtor_details || debtor_details_;
  const cosigner = cosigner_details || cosigner_details_;
  const studyLevels = state.globalSetting.orgData.get_levels_of_studies;
  let promissoryIsV2 = false;

  if (pf.promissory && pf.promissory.unmaterialized_process_id) {
    promissoryIsV2 = pf.promissory.unmaterialized_process_id.startsWith("2-")
  }

  const isPromissoriesV2 = isRemoteTeleshopping ? ts.sale_summary.is_promissories_v2 : _get(pf.saleCacheResponse, 'is_promissories_v2') || promissoryIsV2;
  const articles = [{
    article_id: _get(state, 'globalSetting.orgData.teleshopping_voluntary_insurance.cardif.article.id'),
    amount: 1,
    value: ts.price,
    num_monthly_instalments: ts.instalments,
  }];
  const data = isRemoteTeleshopping ? null : {
    debtor: {
      newName: debtor.name,
      newLastname: debtor.last_name,
      name: debtor.name,
      last_name: debtor.last_name,
      marital_status: debtor.marital_status,
      debtor_doctype_id:
        debtor.type_of_identification &&
        doctypes.find(o => o.name === debtor.type_of_identification).id,
      type_of_identification: debtor.type_of_identification,
      identification: removeFirstZeros(debtor.identification),
      id_expedition_place: debtor.id_debtor_expedition_place,
      id_expedition_date: moment(
        debtor.id_debtor_expedition_date,
        'YYYY-MM-DD'
      ).format('DD-MM-YYYY'),
      gender: debtor.gender === 'M' ? 4 : debtor.gender === 'F' ? 1 : 9,
      level_of_studies:
        debtor.level_of_studies && studyLevels &&
        studyLevels.find(o => o.name === debtor.level_of_studies).id,
      email: debtor.email,
      phone: debtor.phone,
      mobile: pf.debtorMobile,
      debtorNeighbourhood: _get(pf, 'neighborhood.description', ''),
      debtorNeighbourhoodId: _get(pf, 'neighborhood.id', ''),
      address: _get(debtor, 'address.body', ''),
      type_of_house: debtor.type_of_house,
      profession: debtor.profession || '',
      occupation: debtor.occupation || '',
      company_name: debtor.company_name || '',
      date_of_birth: pf.debtor_birthdate
    },
    cosigner: cosigner ?
    {
      newName: cosigner.name,
      newLastname: cosigner.last_name,
      name: cosigner.name,
      last_name: cosigner.last_name,
      marital_status: cosigner.marital_status,
      cosigner_doctype_id:
        cosigner.type_of_identification &&
        doctypes.find(o => o.name === cosigner.type_of_identification).id,
      type_of_identification: cosigner.type_of_identification,
      identification: removeFirstZeros(cosigner.identification),
      id_expedition_place: cosigner.id_cosigner_expedition_place,
      id_expedition_date: moment(
        cosigner.id_cosigner_expedition_date,
        'YYYY-MM-DD'
      ).format('DD-MM-YYYY'),
      gender: cosigner.gender === 'M' ? 4 : cosigner.gender === 'F' ? 1 : 9,
      level_of_studies:
        cosigner.level_of_studies && studyLevels &&
        studyLevels.find(o => o.name === cosigner.level_of_studies).id,
      email: cosigner.email,
      phone: cosigner.phone,
      mobile: cosigner.mobile,
      cosignerNeighbourhood: _get(pf, 'neighborhood.description', ''),
      cosignerNeighbourhoodId: _get(pf, 'neighborhood.id', ''),
      address: _get(cosigner, 'address.body', ''),
      type_of_house: cosigner.type_of_house,
      profession: cosigner.profession || '',
      occupation: cosigner.occupation || '',
      company_name: cosigner.company_name || '',
      date_of_birth: pf.cosigner_birthdate
    }
    : null
  };
  return {
    ...state.portalFinancing.validatedSaleDetails,
    ...data,
    first_invoice: pf.invoice1,
    second_invoice: pf.invoice2,
    process: state.stepper.process,
    clientMobile: ts.client_mobile,
    isRemoteTeleshopping,
    nextStep: state.stepper.nextStep,
    digitalSaleWithUUPNext: state.stepper.digitalSaleWithUUPNext,
    digitalSaleWithouFaceIdNext: state.stepper.digitalSaleWithouFaceIdNext,
    digitalSaleWithoutUUPonContractNext:
      state.stepper.digitalSaleWithoutUUPonContractNext,
    promissory: isRemoteTeleshopping ? ts.promissory_ts : pp && pf.promissory,
    uniquePromissory: pp && pf.promissory.code,
    promissoryType: sc.allowed_promissory_type || (pp && pf.promissory.type),
    debtorContract: pf.debtor_contract_number,
    debtorStratum: pf.stratum,
    saleChannel: pf.sale_channel,
    isTransfer: !!pf.moveQuotaData.contract,
    routes: state.stepper.routes,
    applyScoring: pf.applyScoring,
    useMegaQuota: pf.useMegaQuota,
    megaQuotaInfo: pf.megaQuotaInfo,
    scoringQuota: pf.scoringQuota,
    serverErrors: pf.errors,
    biometricLogId: pf.biometricLogId,
    contract: pf.debtor_contract_number || state.teleshopping.contract,
    cosignerContract: pf.cosigner_contract_number,
    debtorUnmaterializedVoucherUrl: isPromissoriesV2
      ? ''
      : isRemoteTeleshopping
        ? ts.unmaterialized_voucher_url.debtor
        : pf.unmaterialized_voucher_url.debtor,
    cosignerUnmaterializedVoucherUrl: isPromissoriesV2
      ? ''
      : isRemoteTeleshopping
        ? ts.unmaterialized_voucher_url.cosigner
        : pf.unmaterialized_voucher_url.cosigner,
    unmaterializedVoucherProcessId: isPromissoriesV2 ? '' : isRemoteTeleshopping ? ts.unmaterialized_voucher_process_id : pf.unmaterialized_voucher_process_id,
    voucherSerial: isPromissoriesV2
      ? pf.documentSerialV2
      : isRemoteTeleshopping
        ? ts.voucher_serial
        : pf.voucher_serial,
    verification_sale: state.teleshopping.verification_sale,
    price: state.teleshopping.price,
    instalments: state.teleshopping.instalments,
    salesmanId: state.teleshopping.salesmanId,
    studyLevels: studyLevels,
    isPromissoriesV2: isPromissoriesV2,
    voucherUrl: isRemoteTeleshopping ? state.file.voucherV2TsUrl : state.file.voucherV2Url,
    otpV2TransaccionId: isRemoteTeleshopping ? ts.otpV2TransaccionId : pf.otpV2TransaccionId,
    externalProcessId: isRemoteTeleshopping ? ts.external_process_id : pf.external_process_id,
    otpValidatorDebtor: isRemoteTeleshopping ? ts.otpValidatorDebtor : pf.otpValidatorDebtor,
    otpValidatorCosigner: isRemoteTeleshopping ? ts.otpValidatorCosigner : pf.otpValidatorCosigner,
    loading: isRemoteTeleshopping ? ts.stepIsLoading : pf.stepIsLoading,
    mobile: isRemoteTeleshopping ? ts.mobile : pf.mobile,
    saleDetails: {
      downPayment: isRemoteTeleshopping ? 0 : pf.validatedSaleDetails.sale.down_payment,
      articles: isRemoteTeleshopping ? articles : pf.validatedSaleDetails.articles,
      pointOfSaleID: isRemoteTeleshopping ? 0 : pf.validatedSaleDetails.sale.point_of_sale_id,
      salesmanId: isRemoteTeleshopping ? state.teleshopping.salesmanId : pf.validatedSaleDetails.sale.salesman_id,
    },
    quotaValue: isRemoteTeleshopping ? ts.quota_value : pf.quota_value,
    voucherBody: isRemoteTeleshopping ? ts.voucher_body : pf.voucher_body,
    // REQUESTS
    requestSendOtpSent: state.requestState.requestSendV2OtpSend,
    previewPromissoryLoading: state.requestState.requestPreviewDocumentSend
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newAlert: bindActionCreators(newAlert, dispatch),
    setCurrentStep: bindActionCreators(setCurrentStep, dispatch),
    setNextStep: bindActionCreators(setNextStep, dispatch),
    checkDigitalSaleSignedUnmaterializedVoucher: bindActionCreators(
      checkDigitalSaleSignedUnmaterializedVoucher,
      dispatch
    ),
    createRemoteTeleshopping: bindActionCreators(createRemoteTeleshopping, dispatch),
    resendOlimpiaOTP: bindActionCreators(resendOlimpiaOTP, dispatch),
    searchCacheSaleResponse: bindActionCreators(searchCacheSaleResponse, dispatch),
    sendOtpV2: bindActionCreators(sendOtpV2, dispatch),
    reSendOtpV2: bindActionCreators(reSendOtpV2, dispatch),
    validateOtpV2: bindActionCreators(validateOtpV2, dispatch),
    resetProp: bindActionCreators(resetProp, dispatch),
    validateSignedDocumentV2: bindActionCreators(validateSignedDocumentV2, dispatch),
    fetchExtraQuotas: bindActionCreators(fetchExtraQuotas, dispatch),
    validateOtpV2Teleshopping: bindActionCreators(validateOtpV2Teleshopping, dispatch),
    sendOtpV2Teleshopping: bindActionCreators(sendOtpV2Teleshopping, dispatch),
    reSendOtpV2Teleshopping: bindActionCreators(reSendOtpV2Teleshopping, dispatch),
    validateSignedDocumentV2Teleshopping: bindActionCreators(validateSignedDocumentV2Teleshopping, dispatch),
    previewDocumentV2: bindActionCreators(previewDocumentV2, dispatch),
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DigitalSaleOTPSale);
