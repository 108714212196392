/* eslint-disable complexity */
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import DigitalSaleUnmaterializedPromissory from './DigitalSaleUnmaterializedPromissory';
import { newAlert } from '../../../actions/alert_actions';
import { setCurrentStep } from '../../../actions/stepper_actions';
import {
  setNextStep,
  registerDigitalSalePromissory,
  fetchProfessions,
  resetProp
} from '../../../actions/portal_financing_actions';
import { fetchLocationNames } from '../../../actions/financial_plan_actions';
import {
  doctypes
} from '../../../constants/enums';
import moment from 'moment-timezone';
import _get from 'lodash/get';
import { removeFirstZeros } from '../../../helpers/utilityFunctions';
import { fetchPointOfSaleInfo } from '../../../actions/points_of_sale_actions';

function mapStateToProps(state) {
  const pf = state.portalFinancing;
  let data = {};
  const studyLevels = state.globalSetting.orgData.get_levels_of_studies;
  const maritalStatus = state.globalSetting.orgData.get_marital_statuses;
  const houseType = state.globalSetting.orgData.get_types_of_houses;
  const b2bSelectedPos = state.globalSetting.orgData.b2b_selected_pos;
  const date_of_birth = moment(
    pf.client_debtor_birthdate ||
      pf.debtor_birthdate ||
      (pf.tempPromissory && pf.tempPromissory.debtor.date_of_birth),
    'YYYY-MM-DD'
  ).format('YYYY/MM/DD');
  const initialValues = pf.promissory || pf.tempPromissory;
  if (initialValues) {
    const debtor = pf.promissory
      ? _get(pf, 'promissory.debtor')
      : _get(pf, 'tempPromissory.debtor');
    const cosigner = pf.promissory
      ? _get(pf, 'promissory.cosigner')
      : _get(pf, 'tempPromissory.cosigner');
    data = {
      debtor: {
        name: pf.client_debtor_name || debtor.name,
        last_name: pf.client_debtor_last_name ? (pf.client_debtor_last_name + ' ' + pf.client_debtor_second_last_name).trim() : debtor.last_name,
        marital_status: debtor.marital_status,
        debtor_doctype_id:
          debtor.type_of_identification &&
          doctypes.find(o => o.name === debtor.type_of_identification).id,
        type_of_identification: debtor.type_of_identification,
        identification: removeFirstZeros(debtor.identification),
        id_expedition_place:
          pf.ani_debtor_response ?
            pf.ani_debtor_response.data.expedition_place ||
            debtor.id_expedition_place ||
            ''
          : pf.experian_debtor_response.persona.nacionalidad || '',
        id_expedition_date: pf.ani_debtor_response ? moment(
          pf.ani_debtor_response.data.expedition_date ||
            debtor.id_expedition_date,
          'YYYY-MM-DD'
        ).format('DD-MM-YYYY') : pf.experian_debtor_response ? pf.experian_debtor_response.documento.fecha_expedicion : '',
        gender: debtor.gender === 'M' ? 4 : debtor.gender === 'F' ? 1 : 9,
        level_of_studies:
          debtor.level_of_studies && studyLevels &&
          studyLevels.find(o => o.name === debtor.level_of_studies).id,
        email: debtor.email || '',
        phone: debtor.phone || '',
        mobile: debtor.mobile || '',
        debtorNeighbourhood: _get(pf, 'neighborhood.description', ''),
        debtorNeighbourhoodId: _get(pf, 'neighborhood.id', ''),
        address: pf.address,
        type_of_house: debtor.type_of_house,
        profession: debtor.profession || '',
        occupation: debtor.occupation || '',
        company_name: debtor.company_name || '',
        locality: _get(pf, 'locality', ''),
        department: _get(pf, 'department', ''),
        date_of_birth: date_of_birth !== 'Invalid date' ? date_of_birth : null
      },
      cosigner: cosigner
        ? {
          contract_id: pf.cosigner_contract,
          name: _get(cosigner, 'name', ''),
          last_name: _get(cosigner, 'last_name', ''),
          marital_status: _get(cosigner, 'marital_status', ''),
          cosigner_doctype_id: _get(cosigner, 'type_of_identification')
            ? doctypes.find(o => o.name === cosigner.type_of_identification)
              .id
            : '',
          type_of_identification: _get(
            cosigner,
            'type_of_identification',
            ''
          ),
          identification: _get(cosigner, 'identification', ''),
          gender:
            cosigner.gender === 'M' ? 4 : cosigner.gender === 'F' ? 1 : 9,
          level_of_studies: _get(cosigner, 'level_of_studies') && studyLevels
            ? studyLevels.find(o => o.name === cosigner.level_of_studies).id
            : '',
          email: _get(cosigner, 'email', ''),
          phone: _get(cosigner, 'phone', ''),
          mobile: _get(cosigner, 'mobile', ''),
          cosignerNeighbourhood: _get(pf.cosignerContractData, 'neighborhood.description', ''),
          cosignerNeighbourhoodId: _get(pf.cosignerContractData, 'neighborhood.id', ''),
          address: _get(pf.cosignerContractData, 'address', ''),
          locality: _get(pf.cosignerContractData, 'locality', ''),
          department: _get(pf.cosignerContractData, 'department', ''),
          id_expedition_place: pf.ani_cosigner_response ? pf.ani_cosigner_response.data.expedition_place : pf.experian_cosigner_response ? pf.experian_cosigner_response.persona.nacionalidad : '',
          id_expedition_date: pf.ani_cosigner_response ?
            moment(
              pf.ani_cosigner_response.data.expedition_date,
              'YYYY-MM-DD'
            ).format('DD-MM-YYYY') : pf.experian_cosigner_response ? pf.experian_cosigner_response.documento.fecha_expedicion : '',
          type_of_house: _get(cosigner, 'type_of_house', ''),
          profession: _get(cosigner, 'profession', ''),
          occupation: cosigner.occupation || '',
          company_name: cosigner.company_name,
          date_of_birth: pf.cosigner_birthdate,
          stratum: _get(pf.cosignerContractData, 'stratum', '')
        }
        : null
    };
  } else {
    let debtorLastName = '';
    let cosignerLastName = '';
    if (!pf.client_debtor_last_name) {
      debtorLastName = pf.last_name;
    } else {
      debtorLastName = pf.client_debtor_last_name + ' ' + pf.client_debtor_second_last_name;
    }

    if (pf.cosigner_identification) {
      if (!pf.client_cosigner_last_name) {
        cosignerLastName = _get(pf.cosignerContractData, 'lastName', '');
      } else {
        cosignerLastName = pf.client_cosigner_last_name + ' ' + pf.client_cosigner_second_last_name;
      }
    }

    data = {
      debtor: {
        name: pf.client_debtor_name || pf.name,
        last_name: debtorLastName.trim(),
        marital_status: '',
        debtor_doctype_id: doctypes.find(
          o => o.name === pf.debtor_identification_type
        ).id,
        type_of_identification: pf.debtor_identification_type,
        identification: removeFirstZeros(pf.debtor_identification),
        id_expedition_place: pf.ani_debtor_response ? pf.ani_debtor_response.data.expedition_place : pf.experian_debtor_response.persona.nacionalidad || '',
        id_expedition_date: pf.ani_debtor_response ?
          moment(
            pf.ani_debtor_response.data.expedition_date,
            'YYYY-MM-DD'
          ).format('DD-MM-YYYY') : pf.experian_debtor_response ? pf.experian_debtor_response.documento.fecha_expedicion : '',
        gender: '',
        level_of_studies: '',
        email: '',
        phone: '',
        mobile: '',
        debtorNeighbourhood: _get(pf, 'neighborhood.description', ''),
        debtorNeighbourhoodId: _get(pf, 'neighborhood.id', ''),
        address: pf.address,
        type_of_house: '',
        profession: '',
        occupation: '',
        company_name: '',
        locality: _get(pf, 'locality', ''),
        department: _get(pf, 'department', ''),
        date_of_birth: date_of_birth !== 'Invalid date' ? date_of_birth : null
      },
      cosigner: pf.cosigner_identification
        ? {
          contract_id: pf.cosigner_contract_number,
          identification: pf.cosigner_identification,
          cosigner_doctype_id:
            pf.cosigner_identification_type &&
            doctypes.find(o => o.name === pf.cosigner_identification_type).id,
          type_of_identification: pf.cosigner_identification_type,
          cosignerDoctypeText: pf.cosigner_identification_type,
          date_of_birth: pf.cosigner_birthdate,
          name:
            pf.client_cosigner_name ||
            _get(pf.cosignerContractData, 'name', ''),
          last_name: cosignerLastName.trim(),
          cosignerNeighbourhood: _get(pf.cosignerContractData, 'neighborhood.description', ''),
          cosignerNeighbourhoodId: _get(pf.cosignerContractData, 'neighborhood.id', ''),
          locality: _get(pf.cosignerContractData, 'locality', ''),
          department: _get(pf.cosignerContractData, 'department', ''),
          address: _get(pf.cosignerContractData, 'address', ''),
          stratum: _get(pf.cosignerContractData, 'stratum', ''),
          id_expedition_place: pf.ani_cosigner_response ? pf.ani_cosigner_response.data.expedition_place : pf.experian_cosigner_response ? pf.experian_cosigner_response.persona.nacionalidad : '',
          id_expedition_date: pf.ani_cosigner_response ?
            moment(
              pf.ani_cosigner_response.data.expedition_date,
              'YYYY-MM-DD'
            ).format('DD-MM-YYYY') : pf.experian_cosigner_response ? pf.experian_cosigner_response.documento.fecha_expedicion : '',
          }
        : null
    };
  }
  return {
    ...data,
    isSecureSale: pf.isSecureSale,
    process: state.stepper.process,
    digitalSaleWithUUPNext: state.stepper.digitalSaleWithUUPNext,
    digitalSaleWithouFaceIdNext: state.stepper.digitalSaleWithouFaceIdNext,
    digitalSaleWithoutUUPonContractNext:
      state.stepper.digitalSaleWithoutUUPonContractNext,
    serverErrors: pf.errors,
    contract: pf.debtor_contract_number,
    routes: state.stepper.routes,
    locationNames: state.financialPlans.locationNames,
    cityNames: state.financialPlans.cityNames,
    cosignerContract: pf.cosigner_contract_number,
    neighbourhoodNames: state.financialPlans.neighbourhoodNames,
    professions: pf.professions,
    promissory: pf.promissory,
    debtorIdentification: pf.debtor_identification,
    debtorStratum: pf.stratum,
    digitalSaleWithPUI: pf.digital_sale_with_pui,
    maritalStatus: maritalStatus,
    houseType: houseType,
    studyLevels: studyLevels,
    posId: pf.point_of_sale,
    b2bSelectedPos: b2bSelectedPos,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newAlert: bindActionCreators(newAlert, dispatch),
    setCurrentStep: bindActionCreators(setCurrentStep, dispatch),
    setNextStep: bindActionCreators(setNextStep, dispatch),
    registerDigitalSalePromissory: bindActionCreators(
      registerDigitalSalePromissory,
      dispatch
    ),
    fetchLocationNames: bindActionCreators(fetchLocationNames, dispatch),
    fetchProfessions: bindActionCreators(fetchProfessions, dispatch),
    fetchPointOfSaleInfo: bindActionCreators(fetchPointOfSaleInfo, dispatch),
    resetProps: bindActionCreators(resetProp, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DigitalSaleUnmaterializedPromissory);
