import * as Yup from 'yup';
import classnames from 'classnames';
import { Formik, Field } from 'formik';
import React, { Component, Fragment } from 'react';

import _get from 'lodash/get';
import OtpModal from './OtpModal';
import _forEach from 'lodash/forEach';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import Modal from '../../../shared/Modals/Modal';
import CheckboxInput from '../../../shared/Inputs/Checkbox';
import SimpleSelect from '../../../shared/Selects/SimpleSelect';
import ProgressButton from '../../../shared/Buttons/ProgressButton';
import AutocompleteInput from '../../../shared/Inputs/Autocomplete';
import OutlinedTextField from '../../../shared/Fields/OutlinedTextField';

import LayoutStyles from '../../../../styles/layout';
import ButtonStyles from '../../../../styles/button';
import TypographyStyles from '../../../../styles/typography';
import SpacingStyles from '../../../../styles/helpers/spacing';

import combineStyles from '../../../../helpers/combineStyles';
import { filterListFuzzyly } from '../../../../helpers/utilityFunctions';
import mapListToOptions from '../../../../helpers/mapListToOptions';

import { doctypes } from '../../../../constants/enums';

const styles = theme => ({
  titleWrapper: {
    display: 'flex',
    marginBottom: `${theme.spacing.unit * 3.125}px !important`,
    position: 'relative'
  },
  title: {
    flex: 1,
    fontWeight: 'bold !important'
  },
  attributes: {
    fontSize: '17.5px !important',
    color: '#2C3C47 !important',
    fontWeight: '500 !important',
    //Porque la letra no es responsive
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  resetLinkButton: {
    position: 'absolute',
    right: 0,
    top: -6
  },
  resetLink: {
    textTransform: 'capitalize',
    color: `${theme.palette.primary.main} !important`
  },
  padding: {
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 1}px ${theme
      .spacing.unit * 1.25}px ${theme.spacing.unit * 1}px !important`
  },
  buttonChangeLabel: {
    fontSize: '15px',
    textTransform: 'capitalize'
  },
  modalRoot: {
    maxWidth: '100vw',
    width: '700px'
  },
  modalRootBase: {
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  modalBody: {
    padding: `${theme.spacing.unit * 10}px ${theme.spacing.unit * 6}px 0 ${theme
      .spacing.unit * 6}px`
  },
  modalFooter: {
    margin: `${theme.spacing.unit * 7}px ${theme.spacing.unit * 5}px ${theme
      .spacing.unit * 1.75}px ${theme.spacing.unit * 5}px`
  },
  buttonChange: {
    padding: '0px'
  }
});

const validationSchema = (osfMaxIdLength) => {
  const validationSchema = Yup.object().shape({
    typeOfIdentification: Yup.string()
      .typeError('El campo es requerido')
      .required('El campo es requerido'),
    identification: Yup.string()
      .when('typeOfIdentification', {
        is: '1',
        then: Yup.string()
          .matches(
            /^[0-9]+$/,
            'Tipo de documento no admite caracteres especiales o letras'
          )
          .required('El campo es requerido')
      })
      .when('typeOfIdentification', {
        is: '2',
        then: Yup.string()
          .matches(
            /^[a-z-A-Z-0-9]+$/,
            'Tipo de documento no admite caracteres especiales'
          )
          .required('El campo es requerido')
      })
      .when('typeOfIdentification', {
        is: '3',
        then: Yup.string()
          .matches(
            /^[a-z-A-Z-0-9]+$/,
            'Tipo de documento no admite caracteres especiales'
          )
          .required('El campo es requerido')
      })
      .when('typeOfIdentification', {
        is: '5',
        then: Yup.string()
          .matches(
            /^[0-9-]+$/,
            'Tipo de documento solo admite números y el caracter - '
          )
          .required('El campo es requerido')
      })
      .max(
        osfMaxIdLength,
        `La identificación debe tener un máximo de hasta ${osfMaxIdLength} caracteres`
      )
      .typeError('El campo es requerido')
      .nullable()
      .required('El campo es requerido'),
    firstName: Yup.string()
      .nullable()
      .required('El campo es requerido'),
    lastName: Yup.string()
      .nullable()
      .required('El campo es requerido'),
    email: Yup.string()
      .email('Correo electronico invalido')
      .required('El campo es requerido'),
    mobile: Yup.number()
      .typeError('Celular es requerido')
      .positive('Debe ser un número válido')
      .moreThan(3000000000, 'Debe ser un número válido')
      .lessThan(4000000000, 'Debe ser un número válido')
      .required('Celular es requerido')
  });
  return validationSchema;
};

export class EditProfileModal extends Component {
  state = {
    isOpenedOtpModel: false,
    otpType: 'mobile'
  };
  _handleOnClose = () => {
    const { onClose } = this.props;
    onClose();
  };
  _handleCloseOpenedOtpModel = () => this.setState({ isOpenedOtpModel: false });

  _getSuggestions = async (type, value) => {
    const items = this.props[type].map(item => ({
      label: item.name || item.description,
      value: item.id
    }));
    const suggestions = filterListFuzzyly(value, items, 'label');
    return Promise.resolve(suggestions);
  };

  _handleOnSave = async values => {
    const { onSave } = this.props;
    const userinfo = { ...values };
    userinfo.firstName = userinfo.firstName.toUpperCase().trim();
    userinfo.lastName = userinfo.lastName.toUpperCase().trim();
    if (userinfo.supplier) userinfo.supplier_id = userinfo.supplier.value;
    delete userinfo.supplier;
    onSave({ user: userinfo }).then(isCorrect => {
      if (isCorrect) this._handleOnClose();
    });
  };

  componentDidMount() {
    this.setState({
      isOpenedOtpModel: this.props.openOtp
    });
  }
  handleCheckBox(values, setFieldValue) {
    setFieldValue('sendNotifications', !values.sendNotifications);
  }

  _handleErrors = apiErrors => {
    const errors = {};
    _forEach(apiErrors, (v, k) => (errors[k] = v[0]));
    return errors;
  };

  userTypeOptions = () => {
    const types = [
      {
        key: true,
        value: true,
        label: 'Sí'
      },
      {
        key: false,
        value: false,
        label: 'No'
      }
    ];
    return types;
  };

  render() {
    const {
      classes,
      open = true,
      isSubmitting,
      user = {},
      confirmUpdateMedia,
      canUpdateUsers,
      updateMedia,
      requestErrorsOnUpdateMedia,
      requestErrorsOnUpdatePersonalData,
      canModifyUserSupplier,
      currentUser,
      osfMaxIdLength,
      errors,
      hugeSurfaceCoordinatorRoles,
      isBlackListActive
    } = this.props;
    const {
      typeOfIdentification,
      identification,
      firstName,
      lastName,
      username,
      email,
      mobile,
      supplier,
      generic,
      sendNotifications,
      hasBiometricReader,
      roles,
      blocked
    } = user;

    const {
      canUpdateUserMedia,
      canConfirmUserMedia,
      canUpdateGenericField,
      canUpdateHasBiometricReaderField,
      canUpdateTheReceiptOfSchedulingNotifications,
      canUpdateBasicPersonalData,
      canUpdateUserIdentification
    } = currentUser;

    const currentUserRoles = currentUser.roles;

    const userSupplier = {
      label: (supplier && supplier.name) || '',
      value: supplier && supplier.id
    };
    const supplierError =
      requestErrorsOnUpdatePersonalData == null
        ? ''
        : requestErrorsOnUpdatePersonalData.supplier;

    return (
      <Formik
        enableReinitialize={true}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema(osfMaxIdLength)}
        initialValues={{
          typeOfIdentification: typeOfIdentification || '',
          identification: identification,
          firstName: firstName,
          lastName: lastName,
          userName: username,
          email: email,
          mobile: mobile,
          supplier: userSupplier,
          generic: generic,
          sendNotifications: sendNotifications,
          hasBiometricReader: hasBiometricReader
        }}
        onSubmit={this._handleOnSave}
        onReset={(values, actions) => {
          actions.resetForm();
          this._handleOnClose();
        }}
        // eslint-disable-next-line complexity
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleReset,
          setFieldValue,
          dirty
        }) => {
          const userView = {
            title: 'Tus datos personales',
            identificationType: (
              <>
                <p className={classes.body2}>Tipo de identificación</p>
                <p
                  className={classnames(classes.headline6, classes.attributes)}
                >
                  {typeOfIdentification
                    ? doctypes.filter(el => el.id == typeOfIdentification)[0]
                        .name
                    : '--'}
                </p>
              </>
            ),
            identification: (
              <>
                <p className={classes.body2}>Número de identificación</p>
                <p
                  className={classnames(classes.headline6, classes.attributes)}
                >
                  {identification || '--'}
                </p>
              </>
            ),
            firstName: (
              <>
                <p className={classes.body2}>Nombre</p>
                <p
                  className={classnames(classes.headline6, classes.attributes)}
                >
                  {firstName || '--'}
                </p>
              </>
            ),
            lastName: (
              <>
                <p className={classes.body2}>Apellido</p>
                <p
                  className={classnames(classes.headline6, classes.attributes)}
                >
                  {lastName || '--'}
                </p>
              </>
            ),
            supplier: (
              <>
                {' '}
                <p className={classes.body2}>Proveedor</p>
                <p
                  className={classnames(classes.headline6, classes.attributes)}
                >
                  {userSupplier.label ? userSupplier.label : '--'}
                </p>
              </>
            ),
            userName: (
              <>
                {' '}
                <p className={classes.body2}>Usuario</p>
                <p
                  className={classnames(classes.headline6, classes.attributes)}
                >
                  {username || '--'}
                </p>
              </>
            ),
            email: (
              <>
                <p className={classes.body2}>Email</p>
                <p
                  className={classnames(classes.headline6, classes.attributes)}
                >
                  {email || '--'}
                </p>
                {canUpdateUserMedia && canConfirmUserMedia ? (
                  <Button
                    className={classes.buttonChange}
                    onClick={() => {
                      this.setState({
                        isOpenedOtpModel: true,
                        otpType: 'email'
                      });
                    }}
                    color={'secondary'}
                    classes={{ label: classes.buttonChangeLabel }}
                  >
                    {'Cambiar'}
                  </Button>
                ) : null}
              </>
            ),
            mobile: (
              <>
                <p className={classes.body2}>Número celular</p>
                <p
                  className={classnames(classes.headline6, classes.attributes)}
                >
                  {mobile || '--'}
                </p>
                {canUpdateUserMedia && canConfirmUserMedia ? (
                  <Button
                    className={classes.buttonChange}
                    onClick={() => {
                      this.setState({
                        isOpenedOtpModel: true,
                        otpType: 'mobile'
                      });
                    }}
                    color={'secondary'}
                    classes={{ label: classes.buttonChangeLabel }}
                  >
                    {'Cambiar'}
                  </Button>
                ) : null}
              </>
            )
          };
          const adminView = {
            title: 'Editar datos personales',
            identificationType: (
              <Field
                render={({ field, form }) => {
                  return (
                    <SimpleSelect
                      name="identificationType"
                      label="Tipo de identificación"
                      value={values.typeOfIdentification}
                      onChange={change => {
                        setFieldValue(
                          'typeOfIdentification',
                          change.target.value
                        );
                      }}
                      disabled={!canUpdateUserIdentification}
                      options={mapListToOptions(doctypes, {
                        withId: false
                      })}
                      inputProps={{
                        MenuProps: {
                          disableAutoFocusItem: true
                        }
                      }}
                      margin="normal"
                      variant="outlined"
                      field={field}
                      error={
                        touched.typeOfIdentification &&
                        !!errors.typeOfIdentification
                      }
                      helperText={
                        touched.typeOfIdentification &&
                        errors.typeOfIdentification
                      }
                      form={form}
                      fullWidth
                    />
                  );
                }}
              />
            ),
            identification: (
              <OutlinedTextField
                name="identification"
                onChange={handleChange}
                value={values.identification}
                label={'Número de identificación'}
                disabled={!canUpdateUserIdentification}
                fullWidth
                min={0}
                error={touched.identification && !!errors.identification}
                helperText={touched.identification && errors.identification}
              />
            ),
            firstName: (
              <OutlinedTextField
                name="firstName"
                onChange={handleChange}
                value={values.firstName}
                label={'Nombre'}
                disabled={!canUpdateBasicPersonalData}
                fullWidth
                min={0}
                error={touched.firstName && !!errors.firstName}
                helperText={touched.firstName && errors.firstName}
              />
            ),
            lastName: (
              <OutlinedTextField
                name="lastName"
                onChange={handleChange}
                value={values.lastName}
                label={'Apellido'}
                disabled={!canUpdateBasicPersonalData}
                fullWidth
                min={0}
                error={touched.lastName && !!errors.lastName}
                helperText={touched.lastName && errors.lastName}
              />
            ),
            supplier: (
              <Field
                name="supplier"
                onChange={handleChange}
                render={() => {
                  return (
                    <AutocompleteInput
                      id="supplier"
                      name="supplier"
                      label="Proveedor"
                      margin="normal"
                      variant="outlined"
                      disabled={roles.some(role =>
                        hugeSurfaceCoordinatorRoles.includes(role.name)
                      ) || (isBlackListActive && blocked)}
                      error={
                        touched.supplier && ( (errors.supplier && !!errors.supplier.label) || supplierError)
                      }
                      helperText={
                        touched.supplier && ( (errors.supplier && errors.supplier.label) || supplierError)
                      }
                      value={values.supplier.label || ''}
                      onChange={change => {
                        setFieldValue('supplier', {
                          label: change
                        });
                      }}
                      suggestions={this.props.suppliers}
                      getSuggestions={value =>
                        this._getSuggestions('suppliers', value)
                      }
                      onSuggestionSelected={supplier => {
                        setFieldValue('supplier', supplier);
                      }}
                    />
                  );
                }}
              />
            ),
            userName: (
              <OutlinedTextField
                name="username"
                margin="none"
                onChange={handleChange}
                value={values.userName}
                label={'Usuario'}
                disabled={true}
                fullWidth
                min={0}
                error={touched.userName && !!errors.userName}
                helperText={touched.userName && errors.userName}
              />
            ),
            email: (
              <OutlinedTextField
                name="email"
                onChange={handleChange}
                value={values.email}
                label={'Email'}
                disabled={!canUpdateBasicPersonalData}
                type={'email'}
                fullWidth
                min={0}
                error={touched.email && !!errors.email}
                helperText={touched.email && errors.email}
              />
            ),
            mobile: (
              <OutlinedTextField
                name="mobile"
                onChange={handleChange}
                value={values.mobile}
                label={'Número celular'}
                disabled={!canUpdateBasicPersonalData}
                type={'number'}
                fullWidth
                min={0}
                error={touched.mobile && !!errors.mobile}
                helperText={touched.mobile && errors.mobile}
              />
            ),
            generic: (
              <SimpleSelect
                name={'generic'}
                value={values.generic}
                label={'Usuario genérico:'}
                onChange={handleChange}
                options={this.userTypeOptions()}
              />
            ),
            sendNotifications: (
              <Field
                onChange={handleChange}
                render={({ field, form }) => {
                  return (
                    <CheckboxInput
                      name="sendNotifications"
                      value={values.sendNotifications}
                      label={'Recibir notificaciones de agendamiento'}
                      onChange={() =>
                        setFieldValue(
                          'sendNotifications',
                          !values.sendNotifications
                        )
                      }
                    />
                  );
                }}
              />
            ),
            biometricReader: (
              <Field
                onChange={handleChange}
                render={({ field, form }) => {
                  return (
                    <SimpleSelect
                      name={'hasBiometricReader'}
                      value={values.hasBiometricReader}
                      label={'Tiene lector biométrico:'}
                      disabled={ isBlackListActive && blocked }
                      options={this.userTypeOptions()}
                      onChange={() =>
                        setFieldValue(
                          'hasBiometricReader',
                          !values.hasBiometricReader
                        )
                      }
                    />
                  );
                }}
              />
            )
          };
          const view = canUpdateUsers
            ? user.isCurrentUser && !canUpdateBasicPersonalData
              ? userView
              : adminView
            : userView;
          return (
            <Modal
              open={open}
              onClose={this._handleOnClose}
              classes={{
                modalRootBase: classes.modalRootBase,
                root: classes.modalRoot,
                body: classes.modalBody,
                footer: classes.modalFooter
              }}
              body={
                this.state.isOpenedOtpModel ? (
                  <OtpModal
                    otpType={this.state.otpType}
                    requestErrorsOnUpdateMedia={requestErrorsOnUpdateMedia}
                    onClose={this._handleCloseOpenedOtpModel}
                    onCloseAll={this._handleOnClose}
                    isSubmitting={this.props.requestNewOtpSend}
                    confirmUpdateMedia={confirmUpdateMedia}
                    updateMedia={updateMedia}
                    user={user}
                    hideBackdrop={true}
                  />
                ) : (
                  <Fragment>
                    <div className={classes.titleWrapper}>
                      <p
                        className={classnames(classes.headline6, classes.title)}
                      >
                        {view.title}
                      </p>
                    </div>
                    <Grid container spacing={16} justify="space-between">
                      <Grid item xs={6} classes={{ item: classes.padding }}>
                        {view.identificationType}
                      </Grid>
                      <Grid item xs={6} classes={{ item: classes.padding }}>
                        {view.identification}
                      </Grid>
                      <Grid item xs={6} classes={{ item: classes.padding }}>
                        {view.firstName}
                      </Grid>
                      <Grid item xs={6} classes={{ item: classes.padding }}>
                        {view.lastName}
                      </Grid>
                      <Grid item xs={6} classes={{ item: classes.padding }}>
                        {hugeSurfaceCoordinatorRoles
                          ? canModifyUserSupplier &&
                            (!roles.some(role =>
                              hugeSurfaceCoordinatorRoles.includes(role.name)
                            ) ||
                              !currentUserRoles.some(role =>
                                hugeSurfaceCoordinatorRoles.includes(role.name)
                              ))
                            ? adminView.supplier
                            : userView.supplier
                          : canModifyUserSupplier
                          ? adminView.supplier
                          : userView.supplier}
                      </Grid>
                      <Grid item xs={6} classes={{ item: classes.padding }}>
                        {view.userName}
                      </Grid>
                      <Grid item xs={6} classes={{ item: classes.padding }}>
                        {view.email}
                      </Grid>
                      <Grid item xs={6} classes={{ item: classes.padding }}>
                        {view.mobile}
                      </Grid>
                      {canUpdateGenericField && (
                        <Grid item xs={6} classes={{ item: classes.padding }}>
                          {adminView.generic}
                        </Grid>
                      )}
                      {canUpdateHasBiometricReaderField && (
                        <Grid item xs={6} classes={{ item: classes.padding }}>
                          {adminView.biometricReader}
                        </Grid>
                      )}
                      {canUpdateTheReceiptOfSchedulingNotifications && (
                        <Grid item xs={12} classes={{ item: classes.padding }}>
                          {adminView.sendNotifications}
                        </Grid>
                      )}
                    </Grid>
                  </Fragment>
                )
              }
              footer={
                canUpdateUsers ? (
                  <>
                    <div className={classes.spacer} />
                    <Button
                      className={classes.button}
                      onClick={this._handleOnClose}
                      color={'secondary'}
                      classes={{ label: classes.buttonLabel }}
                    >
                      {'CANCELAR'}
                    </Button>
                    <ProgressButton
                      onClick={handleSubmit}
                      className={classes.button}
                      classes={{
                        disabled: classes.buttonDisabled,
                        label: classes.buttonLabel
                      }}
                      color={'secondary'}
                      disabled={!dirty || isSubmitting}
                      isSubmitting={isSubmitting}
                    >
                      {'Guardar'}
                    </ProgressButton>
                  </>
                ) : (
                  <>
                    <div className={classes.spacer} />
                    <Button
                      className={classes.button}
                      onClick={this._handleOnClose}
                      color={'secondary'}
                      classes={{ label: classes.buttonChangeLabelel }}
                    >
                      {'Cerrar'}
                    </Button>
                  </>
                )
              }
            />
          );
        }}
      />
    );
  }
}
export default withStyles(
  combineStyles(
    styles,
    LayoutStyles,
    TypographyStyles,
    ButtonStyles,
    SpacingStyles
  )
)(EditProfileModal);
