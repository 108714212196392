import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  stratumQuota: Yup.string()
    .required('El campo es requerido'),
  rolloverQuota: Yup.string()
    .required('El campo es requerido'),
  allowedTimeSinceGasInstallation: Yup.number()
    .required('El campo es requerido'),
  numberOfSuspensionsPeriodToEvaluate: Yup.number()
    .required('El campo es requerido'),
  allowedTimeSinceLastPunishment: Yup.number()
    .required('El campo es requerido'),
  allowedTimeSinceLastDateBrillaProductWasInArrears: Yup.number()
    .required('El campo es requerido'),
  allowedTimeSinceLastDateGasProductWasInArrears: Yup.number()
    .required('El campo es requerido'),
  allowedTimeSinceLastDateOtherServicesWereInArrears: Yup.number()
    .required('El campo es requerido'),
  maximumNetworkBalance: Yup.string()
    .required('El campo es requerido'),
  requiredOwnershipPeriod: Yup.number()
    .required('El campo es requerido'),
  stratum: Yup.number()
    .required('El campo es requerido')
});
