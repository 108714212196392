import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import LoadingIndicator from '../../shared/LoadingIndicator';
import FormItemContainer from '../../shared/Containers/FormItemContainer';

import {
  promissoryTypes,
  processesOrder
} from './constants/SupplierPreferences';

import combineStyles from '../../../helpers/combineStyles';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import classnames from 'classnames';
import _get from 'lodash/get';

const styles = theme => ({
  gridContainer: {
    '& b': {
      color: 'rgba(12,31,44,0.87)'
    }
  },
  itemValue: {
    textTransform: 'lowercase',
    '&:first-letter': {
      textTransform: 'uppercase'
    }
  },
  column: {},
  row: {
    display: 'flex',
    flexDirection: 'row',
    '& p:nth-child(1)': {
      flex: '80%'
    },
    '& p:nth-child(2)': {
      flex: '20%',
      paddingLeft: 10
    }
  },
  divider: {
    width: '100%',
    margin: '5px 0'
  }
});

const Section = ({ classes, title, content, row = true }) => {
  return (
    <div className={row ? classes.row : classes.column}>
      <p className={classnames(classes.subtitle3)}>{title}</p>
      <p className={classnames(classes.body2, classes.itemValue)}>{content}</p>
    </div>
  );
};

const _getLabel = (list, value, defaultLabel) => {
  const result = list.find(item => item.value === value);
  return result ? result.label : defaultLabel;
};

const _checkBool = data => {
  return data ? 'Si' : 'No';
};

export const SupplierPreferences = ({
  classes,
  supplier,
  isLoading,
  onEditSupplier,
  canUpdateSupplier
}) => {
  return (
    <FormItemContainer
      title={'Configuración Registro de Venta'}
      secondaryAction={
        !isLoading && canUpdateSupplier ? (
          <Button
            size={'small'}
            color={'secondary'}
            className={classnames(classes.smallButton, classes.actionButtons)}
            classes={{
              label: classes.buttonLabel
            }}
            onClick={onEditSupplier}
          >
            {'Editar'}
          </Button>
        ) : null
      }
    >
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <Grid container spacing={16} className={classes.gridContainer}>
          <Grid item md={6} xs={12}>
            <Section
              classes={classes}
              row={false}
              title={'Tipo de pagaré permitido:'}
              content={_getLabel(
                promissoryTypes,
                supplier.allowedPromissoryType,
                'any_type'
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Section
              classes={classes}
              row={false}
              title={'Orden de los procesos:'}
              content={_getLabel(
                processesOrder,
                supplier.orderOfProcesses,
                'any_order'
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Section
              classes={classes}
              row={false}
              title={'Punto de venta por defecto:'}
              content={_get(supplier, 'defaultPointOfSale.name', '--')}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Section
              classes={classes}
              row={false}
              title={'Canal de venta por defecto:'}
              content={_get(supplier, 'defaultSaleChannel.name', '--')}
            />
          </Grid>
          <Divider variant={'middle'} light classes={{ root: classes.divider }} />
          <Grid item md={12} xs={12}>
            <Section
              classes={classes}
              title={'¿Grande superficie?'}
              content={_checkBool(supplier.isBigBox)}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Section
              classes={classes}
              title={'¿Puede imprimir documento de venta?'}
              content={_checkBool(supplier.canReprintSaleDocuments)}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Section
              classes={classes}
              title={'¿Generación de BIN?'}
              content={_checkBool(supplier.requiresBin)}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Section
              classes={classes}
              title={'¿Puede seleccionar punto de venta?'}
              content={_checkBool(supplier.canSelectPointOfSale)}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Section
              classes={classes}
              title={'¿Entrega en punto?'}
              content={_checkBool(supplier.siteDelivery)}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Section
              classes={classes}
              title={'¿Puede cambiar entrega en punto?'}
              content={_checkBool(supplier.canChangeSiteDelivery)}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Section
              classes={classes}
              title={'¿Requiere aprobación antes de cancelar ventas?'}
              content={_checkBool(supplier.requiresApprovalBeforeSaleCancel)}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Section
              classes={classes}
              title={'¿Puede seleccionar canales de venta?'}
              content={_checkBool(supplier.canSelectSaleChannel)}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Section
              classes={classes}
              title={'¿Requiere aprobación antes de devolver ventas?'}
              content={_checkBool(supplier.requiresApprovalBeforeSaleReturn)}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Section
              classes={classes}
              title={'¿Seguro voluntario se muestra en los reportes?'}
              content={_checkBool(supplier.reportsShowVoluntaryInsurance)}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Section
              classes={classes}
              title={'¿Puede vender con fianza?'}
              content={_checkBool(supplier.canSellWithSecureSale)}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Section
              classes={classes}
              title={'¿Puede omitir paso de validación de facturas?'}
              content={_checkBool(supplier.canSkipInvoiceValidation)}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Section
              classes={classes}
              title={'¿Puede validar teléfono celular en registro de venta?'}
              content={_checkBool(supplier.canValidateMobileOnSale)}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Section
              classes={classes}
              title={'¿Puede vender seguro voluntario complementario?'}
              content={_checkBool(supplier.canSellVoluntaryInsuranceLayerTwo)}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Section
              classes={classes}
              title={'¿Puede vender con Megacupo?'}
              content={_checkBool(supplier.canSellWithMegaquota)}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Section
              classes={classes}
              title={'¿Puede validar mascotas en registro de venta?'}
              content={_checkBool(supplier.canValidatePetSale)}
            />
          </Grid>
        </Grid>
      )}
    </FormItemContainer>
  );
};

export default withStyles(
  combineStyles(ButtonStyles, TypographyStyles, styles)
)(SupplierPreferences);
