import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import PortalSupplierDataPage from './PortalSupplierDataPage';
import { setCurrentStep } from '../../../actions/stepper_actions';
import { fetchSaleChannels, fetchPointOfSaleSaleChannels } from '../../../actions/points_of_sale_actions';
import {
  checkSixthStep,
  fetchSellers,
  fetchPOS,
  stepSuccess,
  resetBaseErrorModal,
  fetchSecurityQuestionsCausals
} from '../../../actions/portal_financing_actions';
import { newAlert } from '../../../actions/alert_actions';
import _get from 'lodash/get';

function mapStateToProps(state) {
  const sc = state.portalFinancing.supplier || {};
  const orgData = _get(state, 'globalSetting.orgData');
  const pf = state.portalFinancing;
  const debtor = pf.promissory ? pf.promissory.debtor : null;
  const cosigner = pf.promissory && pf.promissory.cosigner ? pf.promissory.cosigner : null;

  return {
    useScoring: state.portalFinancing.useScoring,
    routes: state.stepper.routes,
    process: state.stepper.process,
    serverErrors: state.portalFinancing.errors,
    nextStep: state.stepper.nextStep,
    portalNext: state.stepper.portalNext,
    promissoryNext: state.stepper.promissoryNext,
    remoteDigitalSaleWithUUPNext: state.stepper.remoteDigitalSaleWithUUPNext,
    remoteDigitalSaleWithoutUUPNext: state.stepper.remoteDigitalSaleWithoutUUPNext,
    saleChannels: _get(orgData, 'sale_channels') &&
      orgData.sale_channels.filter(sale_channel => sale_channel.active && sale_channel.is_for_register_sale),
    pointsOfSale: state.portalFinancing.portalPOS,
    pointOfSaleSaleChannels: state.pointOfSale.pointOfSaleSaleChannels || [],
    userSupplier:
      state.authentication.currentUser.supplier &&
      state.authentication.currentUser.supplier.id,
    userID: state.authentication.currentUser.id,
    contract: state.portalFinancing.debtor_contract_number,
    providerConfig: {
      can_change_site_delivery: sc.can_change_site_delivery,
      can_select_point_of_sale: sc.can_select_point_of_sale,
      can_select_sale_channel: sc.can_select_sale_channel,
      default_point_of_sale: sc.default_point_of_sale,
      default_sale_channel: sc.default_sale_channel,
      site_delivery: sc.site_delivery
    },
    userConfig: {
      pos: state.portalFinancing.point_of_sale,
      saleChannel: state.portalFinancing.sale_channel,
      clerk: state.portalFinancing.clerk,
      clerkName: state.portalFinancing.clerkName,
      validationMethod: pf.validationMethod,
      saleDate: state.portalFinancing.saleDate
    },
    sellers: state.portalFinancing.sellers.filter(
      seller => !seller.attributes.generic
    ),
    gnpGasApplianceSaleEvaluation:
      state.portalFinancing.gnp_gas_appliance_evaluation,
    gnpCategories: _get(orgData, 'gnp_categories'),
    gasApplianceCategories: _get(orgData, 'gas_appliance_categories'),
    orgSellerDataAuthorization: _get(orgData, 'get_seller_data_authorization'),
    isBlackListActive: _get(orgData, 'get_blacklist_active'),
    isSecureSale: pf.isSecureSale,
    isBiometricValidationInSecureSaleActive: state.globalSetting.orgData.is_biometric_validation_in_secure_sale_active,
    promissory: pf.promissory,
    userId: pf.userId,
    channelIgnoreBiometricsValidation: pf.channel_ignore_biometrics_validation,
    debtorIdentificationType: debtor ? debtor.type_of_identification : pf.debtor_identification_type,
    cosignerIdentificationType: cosigner ? cosigner.type_of_identification : pf.cosigner_identification_type,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentStep: bindActionCreators(setCurrentStep, dispatch),
    fetchSaleChannels: bindActionCreators(fetchSaleChannels, dispatch),
    fetchPOS: bindActionCreators(fetchPOS, dispatch),
    checkSixthStep: bindActionCreators(checkSixthStep, dispatch),
    fetchSellers: bindActionCreators(fetchSellers, dispatch),
    fetchPointOfSaleSaleChannels: bindActionCreators(fetchPointOfSaleSaleChannels, dispatch),
    newAlert: bindActionCreators(newAlert, dispatch),
    stepSuccess: bindActionCreators(stepSuccess, dispatch),
    resetBaseErrorModal: bindActionCreators(resetBaseErrorModal, dispatch),
    fetchSecurityQuestionsCausals: bindActionCreators(fetchSecurityQuestionsCausals, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PortalSupplierDataPage);
