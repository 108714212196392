export const statusOptions = [
  { value: 'todos', title: 'Todos' },
  { value: 'activos', title: 'Activos' },
  { value: 'inactivos', title: 'Inactivos' }
];

export const levelOptions = [
  { value: 'todos', title: 'Todos' },
  { value: 'padre', title: 'Padre' },
  { value: 'hija', title: 'Hija' }
];
